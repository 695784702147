import { CustomAutocompleteFreeSolo } from 'components/shared/DropDown/CustomAutocomplete';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PHONENUMBER_REGEX } from 'utils/Regex';

export const AssignClient = (props) => {
  const { t } = useTranslation();
  const [name, setName] = useState(null);
  const [email, setMail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    setName(props.data.name);
    if (name && name.length > 0) {
    }

    setMail(props.data.email);
    setPhoneNumber(props.data.phoneNumber);
  }, [props, props.data]);

  return (
    <div className="row row-mb">
      <div className="col-12 col-md-4">
        <div className="label-title">{t('Prénom / Nom du client')}</div>
        <CustomAutocompleteFreeSolo
          list={props.list}
          value={name}
          onInputChange={(value) => {
            if (typeof value?.target?.value === 'string') {
              props.updateClientsList(
                'firstName',
                value.target.value?.split(' ')[0]
              );
              props.updateClientsList(
                'lastName',
                value.target.value?.split(' ')[1]
              );
            } else {
              props.updateClientsList('firstName', value);
            }
          }}
          setValue={(value) => {
            if (
              value &&
              value.id &&
              props.list.length > 0 &&
              props.list.some((x) => x.id === value.id)
            ) {
              const client = props.list.find((x) => x.id === value.id);
              props.updateClientsList('firstName', client.firstName);
              props.updateClientsList('lastName', client.lastName);
              props.updateClientsList('email', client.email);
              props.updateClientsList('phoneNumber', client.phoneNumber);
              props.updateClientsList('isNew', false);
            } else {
              props.updateClientsList('email', '');
              props.updateClientsList('phoneNumber', '');
              props.updateClientsList('isNew', true);
            }
          }}
          getOptionLabel={(option) =>
            option.firstName && option.lastName
              ? option.firstName + ' ' + option.lastName
              : ''
          }
        />
      </div>
      <div className="col-12 col-md-4">
        <div className="label-title">{t('email client')}</div>
        <input
          onChange={(e) => props.updateClientsList('email', e.target.value)}
          value={email}
          type="text"
          className="form-control form-ct"
        />
      </div>
      <div className="col-12 col-md-3">
        <div className="label-title">{t('Téléphone')}</div>
        <input
          onChange={(e) => {
            if (
              e.target.value === '' ||
              PHONENUMBER_REGEX.test(e.target.value)
            ) {
              props.updateClientsList('phoneNumber', e.target.value);
            }
          }}
          value={phoneNumber}
          type="text"
          className="form-control form-ct"
        />
      </div>
      {(props.isDeleteable && props.id) || props.new === false ? (
        <div
          className="col-12 col-md-1"
          onClick={() => props.deleteClient(props.id)}
        >
          <div className="bt-minus-circle" style={{ marginTop: 25 }} />
        </div>
      ) : (
        <div className="col-12 col-md-1" onClick={props.addClient}>
          <div className="bt-plus-circle" style={{ marginTop: 25 }} />
        </div>
      )}
    </div>
  );
};
