export const portugeseTranslation = {
  'Les lignes de commandes ont été mises à jour avec succès !':
    'As linhas de comando foram actualizadas com sucesso!',
  'Le texte a été enregistré avec succès': 'O texto foi registado com sucesso',
  'Nombre de mots': 'Número de palavras',
  Lingala: 'Lingala',
  'Prénom/Nom du client': 'Nome próprio/último nome do cliente',
  Bashkir: 'Bashkir',
  "La date doit être ultérieure à aujourd'hui.":
    'A data deve ser posterior a hoje.',
  'Contenu rédigé': 'Conteúdo escrito',
  'Nom/prénom': 'Apelido/primeiro nome',
  'Mot de passe incorrect': 'Palavra-passe incorrecta',
  Institutions: 'Instituições',
  'Veuillez donner un descriptif au document téléchargé':
    'Por favor, dê uma descrição do documento descarregado',
  'Sichuan Yi ; Nuosu': 'Sichuan Yi; Nuosu',
  Suivant: 'Próximo',
  Kannada: 'Kannada',
  'Séparer les mots clés par un "enter"':
    'Separar palavras-chave com um "enter".',
  Allemand: 'Alemão',
  Sango: 'Sango',
  'Min.': 'Min.',
  'un email contenant un lien de réinitialisation du mot de passe a été envoyé à votre email':
    'um e-mail contendo uma ligação de redefinição de senha foi enviado para o seu e-mail',
  'Chef edito': 'escritores chefe',
  Croate: 'Croata',
  'Nb de textes': 'Nº de textos',
  Violet: 'Violeta',
  'Ce champs doit être sup ou égale nombre de texte':
    'Este campo deve ser maior ou igual ao número de texto',
  'a été activé avec succès': 'foi activada com sucesso',
  Népalais: 'Nepalês',
  'Nombre de FAQ': 'Número de FAQs',
  Urdu: 'Urdu',
  'Analyses restantes': 'Restantes análises',
  Indonésien: 'Indonésio',
  Breton: 'Bretão',
  Livrés: 'Entregue em',
  Associations: 'Associações',
  'Le client': 'O cliente',
  'Révision demandée': 'Revisão solicitada',
  "Une erreur s'est produite, veuillez réessayer":
    'Ocorreu um erro, por favor tente novamente',
  'Mise à jour non autorisée car des textes ont été livrés':
    'Actualização não autorizada à medida que os textos forem sendo entregues',
  "Pour ajouter un texte (optimisation d'un contenu existant)":
    'Para adicionar texto (optimização do conteúdo existente)',
  Gaélique: 'Gaélico',
  'La ligne de commande pour': 'A linha de comando para',
  'Business/Management': 'Negócios/Gestão',
  Serbe: 'Sérvio',
  "Merci d'indiquer ci-dessous vos demandes de modifications en prenant bien en compte nos conditions de repasses":
    'Indique abaixo os seus pedidos de modificações, tendo em conta as nossas condições de engomagem',
  'rédacteurs trouvés': 'escritores encontrados',
  'Kirghiz ; Kirghiz': 'Kirghiz; Kirghiz',
  Différence: 'Diferença',
  'en cours': 'em curso',
  'Sami du Nord': 'Sami do Norte',
  Comparer: 'Comparar',
  Lituanien: 'Lituano',
  'PixLink - Mon tableau de bord': 'PixLink - O meu tablier',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial et ne pas être identique au nom':
    'Deve ter pelo menos 8 caracteres com uma letra maiúscula, um número, um carácter especial e não idêntico ao nome',
  Webmaster: 'Webmaster',
  'Veuillez saisir des champs valides': 'Por favor introduza campos válidos',
  Basque: 'Basco',
  'Exporter .csv': 'Exportar .csv',
  'Confirmer le nouveau mot de passe': 'Confirmar nova senha',
  "Pas d'options": 'Sem opções',
  'Banque/Assurance': 'Banca/Seguros',
  'Vos demandes': 'Os seus pedidos',
  'Coût d\u2019achat': 'Custo de aquisição',
  Tamil: 'Tâmil',
  'Le texte a été supprimé avec succès': 'O texto foi eliminado com sucesso',
  'Mes Commandes': 'As minhas encomendas',
  Zoulou: 'Zulu',
  Afficher: 'Ver',
  Texte: 'Texto',
  Islandais: 'Islandês',
  Russe: 'Russo',
  'Tous les textes': 'Todos os textos',
  Czech: 'Checo',
  'Comparaison hors ligne': 'Comparação off-line',
  Téléphonie: 'Telefonia',
  'Valider le texte': 'Validar o texto',
  'Votre text ne de doit pas dépasser 500 mots':
    'O seu texto não deve exceder 500 palavras',
  'Coquilles (double espace, « s »\u2026)': 'Conchas (espaço duplo, "s"...)',
  'le consultant': 'o consultor',
  Coréen: 'Coreano',
  'Nb de Silo': 'Nº de Silos',
  Validés: 'Validado',
  'non attribuées': 'não atribuído',
  Néerlandais: 'Holandês',
  'Voulez-vous vraiment supprimer': 'Quer realmente apagar',
  Rundi: 'Rundi',
  Regional: 'Regional',
  'Compte client': 'Conta de cliente',
  Global: 'Global',
  'Tous les rôles': 'Todos os papéis',
  Musique: 'Música',
  Perso: 'Pessoal',
  Kinyarwanda: 'Kinyarwanda',
  'Il semblerait que tu n\u2019aies plus de crédit d\u2019analyse':
    'Parece que não tem mais crédito de análise',
  Bambara: 'Bambara',
  Sortir: 'Saia',
  optionel: 'opcional',
  'Temps restant': 'Tempo restante',
  Ukrainien: 'Ucraniano',
  'Chichewa ; Chewa ; Nyanja': 'Chichewa; Chewa; Nyanja',
  'Votre demande de révision a bien été envoyée.':
    'O seu pedido de revisão foi enviado.',
  'PixLink - Créer rédacteur': 'PixLink - Criar um escritores',
  "n'existe pas": 'não existe',
  Hindi: 'Hindi',
  'ne peut pas être supprimé car il est lié à des sites web':
    'não pode ser apagado porque está ligado a websites',
  'Campagnes en cours': 'Encomendas em curso',
  'Textes livrés': 'Textos entregues',
  Validation: 'Validação',
  'Non attribuées': 'Não atribuído',
  'Voulez-vous vraiment désactiver': 'Quer realmente desactivar',
  'Catalan ; Valencien': 'catalão; valenciano',
  'Nombre de textes restants': 'Número de textos restantes',
  'Ne doit pas contenir votre nom': 'Não deve conter o seu nome',
  Documentation: 'Documentação',
  Kazakh: 'Cazaque',
  'Numéro invalide': 'Número inválido',
  'Veuillez saisir un texte ou importer un fichier.':
    'Por favor, introduza um texto ou importe um ficheiro.',
  'Nb de FAQ': 'N.º de FAQs',
  Analyser: 'Analisar',
  Turc: 'Turco',
  'Toute demande n\u2019ayant pas été précisée lors du brief':
    'Qualquer pedido não especificado no resumo',
  Wallon: 'Valão',
  Birmane: 'Birmanês',
  Irlandais: 'Irlandês',
  Oriya: 'Oriya',
  'Réinitialiservotre mot de passe': 'Redefinir a sua palavra-passe',
  Cuisine: 'Cozinha',
  'Haïtien ; créole haïtien': 'Haitiano; crioulo haitiano',
  'Vous ne pouvez pas supprimer le rédacteur':
    'Não se pode apagar o escritores',
  Validé: 'Validado',
  "cet email n'existe pas": 'este e-mail não existe',
  Historique: 'História',
  Confirmer: 'Confirme',
  'à cocher si le nombre de mots est différent par texte':
    'a ser assinalado se o número de palavras for diferente por texto',
  'Fiche du site web ': 'Ficha do sítio Web',
  Désactiver: 'Desactivar',
  'Travaux/BTP': 'Obras/construção',
  'La fiche du website': 'A folha do sítio web',
  'Si le contenu ne respecte pas les règles du brief':
    'Se o conteúdo não respeitar as regras do brief',
  Norvégien: 'Norueguês',
  '0 rédacteurs': '0 escritores',
  indisponible: 'não disponível',
  Venda: 'Venda',
  Maori: 'Maori',
  'Textes revisés': 'Textos revistos',
  'Status texte': 'Estado do texto',
  'textes livrés en attente de validation':
    'textos entregues a aguardar validação',
  'PixLink - Mon Profile': 'PixLink - O meu perfil',
  'le client': 'o cliente',
  Romanche: 'Romanche',
  'URL du site': 'URL do sítio',
  'nouvelles notifications': 'novas notificações',
  'Divehi ; Dhivehi ; Maldivien': 'Divehi; Dhivehi; Maldivian',
  'Êtes vous sur de vouloir sauvegarder le texte':
    'Tem a certeza que quer guardar o texto',
  'Veuillez ajouter un texte initiale afin de pouvoir sauvegarder le texte en brouillon':
    'Por favor adicione um texto inicial para guardar o texto como um rascunho',
  Xhosa: 'Xhosa',
  'Le contrat pour le client': 'O contrato para o cliente',
  rédacteurs: 'escritores',
  'Ce champ est obligatoire': 'Este campo é obrigatório',
  'Tarif/Mot': 'Taxa/palavra',
  Tadjik: 'Tajik',
  'Réf devis': 'Citação de referência',
  'Rechercher un rédacteur': 'Procura de um escritor',
  Albanais: 'Albanês',
  Galicien: 'Galego',
  Disponibilité: 'Disponibilidade',
  'Les deux mots de passe ne sonts pas identiques':
    'As duas palavras-passe não são idênticas',
  Tibétain: 'Tibetano',
  'PixLink - Gestion des utilisateurs': 'PixLink - Gestão de utilizadores',
  'Ossétien ; Ossétique': 'Ossetista; Ossetic',
  Tswana: 'Tswana',
  'Ajouter un client': 'Adicionar um cliente',
  'Séparez les mots-clés par un " Enter ".':
    'Separar as palavras-chave com um "Enter".',
  'Zhuang ; Chuang': 'Zhuang; Chuang',
  Thématiques: 'Temas',
  'Nb mots/texte': 'Nº de palavras/texto',
  Commerce: 'Comércio',
  'Voir les commandes': 'Ver as encomendas',
  'Nombre de mots par texte': 'Número de palavras por texto',
  'Lien drive...': 'Ligação de condução...',
  'Hollandais ; Flamand': 'Holandês; Flamengo',
  'Mon profil': 'O meu perfil',
  "Ce champs doit être sup à 0 car facturation n'est pas au réel":
    'Este campo deve ser maior que 0 porque a facturação não é real',
  'Le texte a été validé avec succès': 'O texto foi validado com sucesso',
  Jeux: 'Jogos',
  'Voulez-vous vraiment supprimer la ligne de commande pour':
    'Quer realmente remover a linha de comando para',
  'Ndebele, Nord ; Ndebele Nord': 'Ndebele, Norte; Ndebele Norte',
  'Slave ecclésiastique ; vieux slavon ; slavon ecclésiastique ; vieux bulgare ; vieux slavon ecclésiastique':
    'Eclesiástico Eslavónico; Velho Eslavónico; Eclesiástico Eslavónico; Velho Búlgaro; Velho Eclesiástico Eslavónico',
  Swahili: 'Swahili',
  Voyage: 'Viagens',
  'Nombre de mot à ajouter': 'Número de palavras a acrescentar',
  'Liste des rédacteurs': 'Lista de escritores',
  'le rédacteur': 'o escritores',
  Kanuri: 'Kanuri',
  rédacteur: 'escritores',
  Tatar: 'Tártaro',
  'Roumain ; Moldave ; Moldovan': 'Romeno; Moldavo',
  'Le texte initial de': 'O texto original do',
  'Mail clients': 'Correio do cliente',
  'Le contrat de': 'O contrato de',
  'au moins une lettre minuscule et une lettre majuscule,un caractère spécial et un chiffre':
    'pelo menos uma letra minúscula e uma maiúscula, um carácter especial e um número',
  Transport: 'Transportes',
  'Nombre total de mots (existants et ajoutés) :':
    'Número total de palavras (existentes e acrescentadas) :',
  'Rechercher par mail, prénom, nom, site...':
    'Pesquisa por e-mail, nome, apelido, site...',
  'Nombre de mot à ajouter (Min/Max) : ':
    'Número de palavras a acrescentar (Min/Máx) :',
  Compte: 'Conta',
  'Tourisme/Loisirs': 'Turismo/Lazer',
  'Nouveau texte': 'Novo texto',
  Max: 'Max',
  'Kalaallisut ; Groenlandais': 'Kalaallisut; Gronelândia',
  'Oups...!il y a une/plusieurs erreur(s) dans la fenêtre du gap sémantique. Veuillez vérifier son contenu pour la/les corriger et soumettre à nouveau votre texte.':
    'Oops...! Há um ou mais erros na janela de intervalo semântico. Por favor verifique o seu conteúdo para corrigi-los/eles e voltar a submeter o seu texto.',
  'ne peut pas être supprimé pour le moment':
    'não pode ser apagado neste momento',
  'Le texte': 'O texto',
  URL: 'URL',
  "Un utilisateur existe déjà avec l'email":
    'Já existe um utilizador com o e-mail',
  Cree: 'Cree',
  Prénom: 'Primeiro nome',
  'commandes prête': 'encomendas prontas',
  Réinitialiser: 'Reinicialização',
  'Veuillez fournir une date valide': 'Por favor forneça uma data válida',
  'Kuanyama ; Kwanyama': 'Kuanyama; Kwanyama',
  'Luxembourgeois ; Letzeburgesch': 'Luxemburguês; Letzeburgesch',
  Suédois: 'Sueco',
  'Confirmer le mot de passe': 'Confirmar palavra-passe',
  'Ce champs est obligatoire et ne peut avoir que des nombres':
    'Este campo é obrigatório e só pode ter números',
  Sciences: 'Ciência',
  Tchétchène: 'Checheno',
  "est le seul client pour un site, il faut ajouter d'autres pour pouvoir le supprimer":
    'é o único cliente de um site, outros devem ser adicionados para o remover',
  'Le mot de passe de confirmation ne correspond pas':
    'A palavra-chave de confirmação não corresponde',
  Swati: 'Swati',
  Danois: 'Dinamarquês',
  Sauvegarder: 'Guardar',
  'Veuillez changer les informations suivantes titre et/ou mot clé':
    'Favor alterar a seguinte informação: título e/ou palavra-chave',
  'importer un document': 'importar um documento',
  Maltais: 'Maltês',
  'Nb de mots': 'Nº de palavras',
  'Les données fournies sont invalides': 'Os dados fornecidos são inválidos',
  'Livraison estimée': 'Entrega estimada',
  'Grec moderne (1453-)': 'Grego moderno (1453-)',
  Avestan: 'Avestan',
  Inuktitut: 'Inuktitut',
  'Nombre total de mots ajoutés': 'Número total de palavras adicionadas',
  'le contenu présente des inexactitudes': 'o conteúdo contém imprecisões',
  Finnois: 'Finlandês',
  'Ajouter un admin': 'Adicionar um administrador',
  'Min/Max': 'Min/Máx',
  'Modifier le consultant': 'Modificar o consultor',
  'Liste des clients': 'Lista de clientes',
  'Nom client': 'Nome do cliente',
  Vert: 'Verde',
  'Mots clé manquants': 'Palavras-chave em falta',
  Lao: 'Lao',
  Somali: 'Somali',
  'Nb de silo par texte': 'Nº de silos por texto',
  Italien: 'Italiano',
  'La commande a été mis à jour avec succès !':
    'A encomenda foi actualizada com sucesso!',
  Bosniaque: 'Bósnio',
  'Thématiques d\u2019expertise': 'Temas de especialização',
  'Ce mot de passe est le mot de passe que vous avez oublié':
    'Esta é a palavra-chave que se esqueceu',
  'a été désactivé avec succès': 'foi desactivado com sucesso',
  Kongo: 'Kongo',
  'Postuler à la commande': 'Pedir a encomenda',
  'mot-clé': 'palavra-chave',
  Abkhazien: 'Abkhazian',
  Mongolien: 'Mongol',
  'PixLink - Fiche site web': 'PixLink - Website',
  'Mail envoyé': 'Correio enviado',
  Géorgien: 'Georgiano',
  'Si des phrases ne sont pas dans un français correct':
    'Se as frases não estiverem em francês correcto',
  'En cours': 'Em curso',
  voir: 'ver',
  "Date d'ajout": 'Data adicionada',
  'Tarif au mot': 'Taxa por palavra',
  Thai: 'Thai',
  'Détails du texte': 'Detalhes do texto',
  'Commandes en retard': 'Encomendas tardias',
  disponible: 'disponível',
  Droit: 'Certo',
  Azerbaïdjanais: 'Azerbaijano',
  'Ajouter un consultant': 'Adicionar um consultor',
  'Dans le cadre d\u2019une repasse sur des contenus, seuls les éléments suivants seront pris en charge':
    'Ao reproduzir o conteúdo, apenas serão suportados os seguintes itens',
  'doit comprendre au moins 8 charactères':
    'deve conter pelo menos 8 caracteres',
  'Titre de la commande': 'Título do pedido',
  'Langues bihari': 'Línguas Bihari',
  'Livraison estimé au': 'Entrega estimada por',
  "Le nombre de texte de la commande ne peut pas être inférieur aux nombre de textes attribuées au(x) rédacteur(s). Veuillez d'abord modifier les informations de la ligne de commande":
    'O número de textos na ordem não pode ser inferior ao número de textos atribuídos ao(s) escritor(es). Por favor, altere primeiro as informações na linha de comando',
  'Date de livraison': 'Data de entrega',
  'Voulez-vous vraiment supprimer le contrat de': 'Quer realmente abolir a',
  'Facturation au réel': 'Facturação efectiva',
  'Gap sémantique': 'Abertura semântica',
  Estonien: 'Estónio',
  Wolof: 'Wolof',
  'Coût d\u2019achat max/mot': 'Custo máximo de compra/palavra-palavra',
  'Détail de la commande': 'Detalhes da encomenda',
  'Export en cours de téléchargement\u2026': 'Exportação a ser carregada...',
  'Ce champ est obligatoire et doit être inférieur ou égal au Nombre total des textesde la commande':
    'Este campo é obrigatório e deve ser inferior ou igual ao número total de textos na ordem',
  Arabe: 'árabe',
  Bislama: 'Bislama',
  'Mail client': 'Correio do cliente',
  '(Titre, mots clé, url, balise, méta-description)':
    '(Título, palavras-chave, url, tag, meta-descrição)',
  'PixLink - Nouvelle commande': 'PixLink - Nova encomenda',
  'Révision en attente': 'Revisão pendente',
  'Le consultant': 'O consultor',
  Entreprise: 'Empresa',
  Aragonais: 'Aragonês',
  Kashmiri: 'Kashmiri',
  'a été activé': 'foi activado',
  'Interlingua (Association internationale des langues auxiliaires)':
    'Interlingua (Associação Internacional de Línguas Auxiliares)',
  Actualités: 'Notícias',
  'Votre décision a été prise en compte avec succès':
    'A sua decisão foi tida em conta com sucesso',
  Afrikaans: 'Afrikaans',
  'contenu en attente de vérification client':
    'conteúdo a aguardar verificação do cliente',
  'Fiche du rédacteur': 'Ficheiro do escritores',
  Outillage: 'Ferramentas',
  'PixLink - Détails de la commande': 'PixLink - Detalhes da encomenda',
  'Ajouter ou compléter ci-dessous votre contenu :':
    'Adicione ou complete o seu conteúdo abaixo:',
  Ewe: 'Ewe',
  'contenu en attente de repasse rédacteur':
    'conteúdo pendente de replay escritores',
  'La commande a été supprimé avec succès':
    'A encomenda foi eliminada com sucesso',
  'Expressions ou phrases françaises mais ne vous convenant pas':
    'Expressões ou frases francesas que não lhe convêm',
  Corse: 'Córsega',
  Modifier: 'Editar',
  'Nb de GMB': 'Nº de GMBs',
  Oromo: 'Oromo',
  Polish: 'Polaco',
  'Insérez votre gap sémantique avec l\u2019occurence':
    'Insira a sua lacuna semântica com a ocorrência',
  'Nb de textes à optimiser': 'Número de textos a serem optimizados',
  Mode: 'Modo',
  Akan: 'Akan',
  'Révisions effectués': 'Revisões efectuadas',
  'Modifier l\u2019admin': 'Editar o administrador',
  'Télécharger le document': 'Descarregar o documento',
  Hausa: 'Hausa',
  'Notes rédacteurs': 'Notas dos escritores',
  Quechua: 'Quechua',
  Féroïen: 'Faroês',
  'Modifier le rédacteur': 'Mudar o escritores',
  Mail: 'Correio',
  Rédacteurs: 'Escritores',
  'PixLink - Détails du texte': 'PixLink - Detalhes do texto',
  Hongrois: 'Húngaro',
  'Bon pour livraison': 'Bom para entrega',
  'Au réel': 'No mundo real',
  'Hiri Motu': 'Hiri Motu',
  Hébreu: 'Hebraico',
  'Nombre total de mots ajoutés :': 'Número total de palavras acrescentadas :',
  Komi: 'Komi',
  'Doit être inférieur au nombre maximum':
    'Deve ser menor do que o número máximo',
  'Nom du texte': 'Nome do texto',
  'Ajouter un rédacteur': 'Adicionar um escritores',
  Ou: 'Ou',
  Utilisateurs: 'Utilizadores',
  Heures: 'Horas',
  'Santé/Bien-être': 'Saúde/bem-estar',
  'Interlingue ; Occidental': 'Interlinguística; Ocidental',
  'Luba-Katanga': 'Luba-Katanga',
  Letton: 'Letão',
  Anglais: 'Inglês',
  Envoyer: 'Enviar',
  'Tableau de bord': 'Painel de instrumentos',
  'Demande de révision': 'Pedido de revisão',
  'Résultat sémantique': 'Resultado semântico',
  'Chercher un rédacteur': 'Procura de um escritores',
  'Ce champs est obligatoire': 'Este campo é obrigatório',
  Rédaction: 'escritoresial',
  'Modifier le client': 'Modificar o cliente',
  'Voulez-vous vraiment supprimer le rédacteur':
    'Quer realmente apagar o escritores',
  Titre: 'Título',
  Langue: 'Idioma',
  'Mots clé': 'Palavras-chave',
  'Les demandes suivantes ne seront pas prises en compte':
    'Não serão consideradas as seguintes candidaturas',
  'délai dépassé': 'prazo não cumprido',
  Tchouvache: 'Tchouvache',
  Ganda: 'Ganda',
  Twi: 'Twi',
  'Contrat déjà existant, veuillez modifier les informations':
    'Contrato existente, por favor modifique a informação',
  Igbo: 'Igbo',
  Yiddish: 'Yiddish',
  'Limburgan ; Limburger ; Limburgish': 'Limburgan; Limburger; Limburgish',
  'Commandes validées': 'Ordens validadas',
  'listeRedacteur-': 'listRedactor-',
  Étape: 'Etapa',
  'Energie/Environnement': 'Energia/Ambiente',
  Min: 'Min',
  Gujarati: 'Gujarati',
  'ne doit pas inclure votre nom/prénom':
    'não deve incluir o seu nome/primeiro nome',
  'PixLink - Fiche rédacteur': 'PixLink - Ficheiro do escritor',
  'révisions effectuées en attentes de validation':
    'revisões efectuadas até à validação',
  'Textes validés': 'Textos validados',
  Art: 'Arte',
  Dzongkha: 'Dzongkha',
  Polyvalent: 'Versátil',
  Sundanese: 'Sundanese',
  'Nb total de textes': 'Número total de textos',
  'Nombre de mots gérés': 'Número de palavras geridas',
  "L'émail": 'Esmalte',
  'Afficher les résultats': 'Mostrar resultados',
  'Frison occidental': 'Frisiano Ocidental',
  'Modification effectuée avec succès': 'Modificação concluída com sucesso',
  'Balise titre': 'Etiqueta de título',
  'PixLink - Mon profil': 'PixLink - O meu perfil',
  'email invalide': 'e-mail inválido',
  'Veuillez ajouter une url afin de pouvoir sauvegarder le texte en brouillon':
    'Por favor adicione uma url para que possa guardar o texto como um rascunho',
  Macédonien: 'Macedónio',
  'Votre candidature a bien été envoyée': 'A sua candidatura foi enviada',
  'E-mail': 'Correio electrónico',
  Fulah: 'Fulah',
  'Ajouter ou compléter ci-dessous votre contenu':
    'Adicione ou complete o seu conteúdo abaixo',
  Manx: 'Manx',
  'Ajout de mots-clés avec la fréquence restante':
    'Acrescentar palavras-chave com a frequência restante',
  'Fiche du site web': 'Ficha do sítio Web',
  Contacter: 'Contacto',
  Fidjien: 'Fijianas',
  Déconnexion: 'Desconexão',
  email: 'e-mail',
  'Nombre de textes': 'Número de textos',
  'Enregistrer et informer client': 'Registo e informação dos clientes',
  'Date de validation': 'Data de validação',
  'Tonga (Îles Tonga)': 'Tonga (Ilhas Tonga)',
  Javanais: 'Javanês',
  Portugais: 'Português',
  Biélorusse: 'Bielorrusso',
  'Pour ajouter un nouveau texte': 'Para adicionar um novo texto',
  'Enfant/Puéri': 'Criança/Puperium',
  'Mots clés': 'Palavras-chave',
  Emploi: 'Emprego',
  'Voulez-vous vraiment supprimer la commande de': 'Quer realmente remover o',
  'Mot clé': 'Palavra-chave',
  Consultants: 'Consultores',
  Volapük: 'Volapük',
  Services: 'Serviços',
  'mot-clé (fréquence)': 'palavra-chave (frequência)',
  'Ne plus afficher ce message': 'Não voltar a mostrar esta mensagem',
  Consultant: 'Consultor',
  "Une erreur s'est produite": 'Ocorreu um erro',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial':
    'Pelo menos 8 caracteres com uma letra maiúscula, um número, um carácter especial',
  'Pushto ; Pashto': 'Pushto; Pashto',
  Français: 'Francês',
  validées: 'validado',
  'Url du site': 'Url do site',
  Précédent: 'Anterior',
  'car celui-ci a déjà rédigé des textes':
    'porque este último já redigiu textos',
  'Kikuyu ; Gikuyu': 'Kikuyu; Gikuyu',
  Brouillons: 'Esboços',
  Ido: 'Ido',
  'Contenu disponible': 'Conteúdo disponível',
  Enseignement: 'Ensinar',
  'a été livré avec succès': 'foi entregue com sucesso',
  Chinois: 'Chinês',
  Vietnamien: 'Vietnamita',
  'Nouveau client': 'Novo cliente',
  'Votre profil a été mis à jour avec succès.':
    'O seu perfil foi actualizado com sucesso.',
  Consignes: 'Instruções',
  'Mot de passe': 'Senha',
  Annuler: 'Cancelar',
  Non: 'Não',
  Nom: 'Nome',
  Guarani: 'Guarani',
  "il y'a des commandes liés à au site web":
    'há encomendas relacionadas com o sítio web',
  Score: 'Pontuação',
  'Mot de passe mis à jour avec succès': 'Senha actualizada com sucesso',
  Latin: 'Latim',
  Technologie: 'Tecnologia',
  Internet: 'Internet',
  Nauru: 'Nauru',
  'a été créé avec succès': 'foi criada com sucesso',
  Roumain: 'Romeno',
  Assamais: 'Assamês',
  'Khmer central': 'Khmer Central',
  'Navajo ; Navaho': 'Navajo; Navaho',
  'Panjabi ; Punjabi': 'Punjabi; Punjabi',
  'Analyse/Limite': 'Análise/Limite',
  Grec: 'Grego',
  Ojibwa: 'Ojibwa',
  'Mes Projets': 'Os meus projectos',
  'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n\u2026':
    'palavra-chave1 (2)\npalavra-chave2 (1)\npalavra-chave3 (1)\npalavra-chave4 (2)\n...',
  'ajouter un texte': 'adicionar um texto',
  'Détail de la prestation': 'Detalhes do serviço',
  "Vous avez la possibilité d'exporter le rapport d'une, ou de plusieurs commandes. Pour exporter toutes les commandes d'un client, il vous suffit juste de cliquer sur l'icône de téléchargement en jaune":
    'Tem a possibilidade de exportar o relatório de uma ou mais encomendas. Para exportar todas as encomendas de um cliente, basta clicar no ícone amarelo de download',
  'Gestion des utilisateurs': 'Gestão de utilizadores',
  'livré et inférieur au nombre de texte non affecté':
    'entregue e menos do que o número de textos não atribuídos',
  'Date deadline validation client': 'Prazo de validação do cliente',
  Aymara: 'Aymara',
  Validées: 'Validado',
  Notes: 'Notas',
  'Nombre de GMB': 'Número de GMBs',
  'résultats sur': 'resultados sobre',
  'Prénom / Nom du client': 'Nome / Nome do cliente',
  'Tout télécharger': 'Descarregar tudo',
  'Champs obligatoires': 'Campos obrigatórios',
  Éditer: 'Editar',
  'Coller le texte initial dans le champs ci-dessous':
    'Colar o texto original no campo abaixo',
  'ID rédacteur': 'ID do escritores',
  Arménien: 'Arménio',
  'Grande distribution': 'Distribuição em grande escala',
  Welsh: 'Galês',
  Herero: 'Herero',
  'Enrichissement sémantique': 'Enriquecimento semântico',
  'Détail de la prestation': 'Detalhes da entrega do conteúdo',
  Noir: 'Preto',
  'Ajouter un texte': 'Acrescentar um texto',
  'Immo/Defisc': 'Propriedade/Taxa',
  'Brief détaillé': 'Resumo detalhado',
  'Séparez les mots-clés par un Enter .':
    'Separe as palavras-chave com um Enter .',
  Prix: 'Prémios',
  'mot de passe oublié': 'Palavra-passe esquecida',
  'Ndebele, South ; South Ndebele': 'Ndebele, Sul; Ndebele do Sul',
  'Demander une révision': 'Pedir uma revisão',
  'Sotho, Southern': 'Sotho, Sul',
  'Le titre de la commande existe déjà': 'O título da encomenda já existe',
  'Félicitations !': 'Parabéns!',
  Kurde: 'Curdo',
  Adresse: 'Endereço',
  Espagnol: 'Espanhol',
  Ndonga: 'Ndonga',
  'En retard': 'Tarde',
  'a été ajouté avec succès': 'foi adicionado com sucesso',
  Commandes: 'Encomendas',
  'a été mis en brouillon': 'foi redigido',
  'Rédacteur attribué': 'escritores designado',
  Rechercher: 'Pesquisa',
  'Nouvelle commande': 'Nova encomenda',
  'Commandes non attribuées': 'Encomendas não atribuídas',
  'Le texte a été mis en brouillon': 'O texto foi redigido',
  'Contact edito': 'Contacto edito',
  Langues: 'Línguas',
  Orange: 'Laranja',
  'Nynorsk norvégien': 'Norueguês Nynorsk',
  Shona: 'Shona',
  Turkmène: 'Turcomenos',
  'contenu repassé, en attente validation client':
    'conteúdo engomado, à espera de validação pelo cliente',
  'Nombre de mots rédigés': 'Número de palavras escritas',
  Malay: 'Malaio',
  'Nombre de Silo': 'Número de Silo',
  Oui: 'Sim',
  Automobile: 'Automotivo',
  Résultats: 'Resultados',
  Afar: 'Afar',
  'Tâche - id tâche': 'Tarefa - id tarefa',
  'Ce champ est obligatoire doit être supérieur à 0':
    'Este campo é obrigatório e deve ser superior a 0',
  'Vous êtes sûr': 'Tem a certeza',
  'Réinitialiser votre mot de passe': 'Redefinir a sua palavra-passe',
  Sindhi: 'Sindhi',
  Bengali: 'Bengali',
  'Bokmål, norvégien ; Bokmål norvégien': 'Bokmål, norueguês; Bokmål norueguês',
  'Nombre de Jours': 'Número de dias',
  'Min/Max mots': 'Palavras Min/Max',
  Slovène: 'Esloveno',
  Site: 'Sítio Web',
  'Sinhala ; Cingalais': 'Sinhala; Sinhalês',
  Bulgare: 'Búlgaro',
  'Liste des commandes': 'Lista de encomendas',
  "L'utilisateur": 'O utilizador',
  Uzbek: 'Uzbeque',
  'Titre du texte': 'Título do texto',
  Postuler: 'Aplicar em',
  'Nb de textes par Silo': 'Nº de textos por Silo',
  'Nombre total de mots (existants et ajoutés)':
    'Número total de palavras (existentes e acrescentadas)',
  'Max.': 'Máx.',
  Beauté: 'Beleza',
  Humanitaire: 'Humanitário',
  Telugu: 'Telugu',
  'Recherche par rédacteur ou texte': 'Pesquisar por autor ou texto',
  'Balise meta-description': 'Meta etiqueta de descrição',
  Malgache: 'Malgaxe',
  Tagalog: 'Tagalog',
  'Ce champs doit être sup à 0 et inférieur à 1':
    'Este campo deve ser superior a 0 e inferior a 1',
  'Le rédacteur': 'O escritores',
  'a été mis à jour avec succès': 'foi actualizado com sucesso',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un symbole et ne pas être identique au nom.':
    'Deve ter pelo menos 8 caracteres com uma letra maiúscula, um número, um símbolo e não deve ser idêntico ao nome.',
  'textes en plus': 'mais textos',
  'Format souhaité': 'Formato desejado',
  Chamorro: 'Chamorro',
  Malayalam: 'Malayalam',
  'Détail ligne de commande': 'Detalhe da linha de encomenda',
  'Veuillez préciser la date': 'Por favor especifique a data',
  Pali: 'Pali',
  Clients: 'Clientes',
  'Total facturé': 'Total facturado',
  'contenu validé par le client': 'conteúdo validado pelo cliente',
  'Occitan (post 1500)': 'Occitano (pos 1500)',
  Japonais: 'Japonês',
  Avaric: 'Avaric',
  'Voulez-vous vraiment activer': 'Quer realmente activar',
  Sanskrit: 'Sânscrito',
  'Toutes les notifications': 'Todas as notificações',
  Marathi: 'Marathi',
  Persan: 'Persa',
  'La commande pour le client': 'A encomenda para o cliente',
  'Intitulé de la commande': 'Título do pedido',
  Samoan: 'Samoan',
  Autres: 'Outros',
  Sport: 'Desporto',
  "il y'a des commandes liés au site web ":
    'há encomendas relacionadas com o sítio web',
  'Ajout de mots-clé avec la fréquence restante':
    'Acrescentar palavras-chave com a frequência restante',
  'a été supprimé avec succès': 'foi removido com sucesso',
  Postulé: 'Publicado em',
  Literie: 'Roupa de cama',
  'Édité par le client': 'Editado pelo cliente',
  Cornouailles: 'Cornualha',
  Tigrinya: 'Tigrinya',
  Textes: 'Textos',
  'Soumettre un nouveau texte': 'Submeter um novo texto',
  Téléphone: 'Telefone',
  Deadline: 'Data limite',
  "Une erreur s'est produite. Veuillez vérifier votre saisie ou essayer plus tard.":
    'Ocorreu um erro. Por favor, verifique a sua entrada ou tente novamente mais tarde.',
  Marshallais: 'Marshall',
  "La demande d'ajout de crédit est envoyée":
    'O pedido para adicionar crédito é enviado',
  'Le rédacteur ': 'O escritores',
  'Demander des crédits': 'Pedido de crédito',
  "Cette commande n'existe pas": 'Este comando não existe',
  Inupiaq: 'Inupiaq',
  Tahitien: 'Tahitiano',
  Admins: 'Admins',
  'Mettez des mots-clés avec fréquence':
    'Definir palavras-chave com frequência',
  Notifications: 'Notificações',
  'en retard': 'final',
  "l'admin": 'a administração',
  'Mail rédacteurs': 'escritores de correio',
  Amharique: 'Amárico',
  Societe: 'Empresa',
  Tsonga: 'Tsonga',
  'Nombre de textes ajoutés': 'Número de textos adicionados',
  Société: 'Empresa',
  Yoruba: 'Iorubá',
  'Le texte a été livré avec succès': 'O texto foi entregue com sucesso',
  'Mettez votre texte': 'Ponha o seu texto',
  "Oups....! Une erreur s'est produite": 'Oops....! Ocorreu um erro',
  'Prénom/Nom': 'Primeiro nome/último nome',
  'Max doit être sup à Min': 'O máximo deve ser maior que o mínimo',
  'Importer un autre document': 'Importar outro documento',
  'Votre demande a expirée .. veuillez refaire une autre':
    'O seu pedido expirou ... por favor faça outro',
  'Veuillez corriger le format votre gap sémantique afin de pouvoir sauvegarder le texte en brouillon':
    'Por favor, corrija o formato da sua lacuna semântica a fim de salvar o texto como um rascunho',
  Exemple: 'Exemplo',
  Slovaque: 'Eslovaco',
  Finance: 'Finanças',
  Réactiver: 'Reativar',
  'Nb textes': 'Nº de textos',
  'Intitulé commande': 'Título da encomenda',
  Supprimer: 'Eliminar',
  Brouillon: 'Projecto',
  'Mots clés restants': 'Restantes palavras-chave',
  Date: 'Data',
  Rôle: 'Papel',
  'Ce champs doit être sup à 0': 'Este campo deve ser superior a 0',
  'Maison/Déco': 'Início/Decoração',
  'Vous avez': 'Tem',
  'Cette ligne de commande ne peut pas être supprimée car une partie de ses textes a été livré':
    'Esta linha de comando não pode ser apagada porque parte do seu texto foi entregue',
  'vérifiez votre email et cliquez sur le lien pour continuer !':
    'verifique o seu e-mail e clique no link para continuar!',
  Sarde: 'Sardenha',
  'Note rédacteur': 'Nota do escritores',
  'Uighur ; Uyghur': 'Uighur; Uyghur',
  Name: 'Nome',
  'Commandes intelligentes': 'Comandos smart',
  'Commandes avec révision': 'Comandos smart + review',
  'Type de contenu': 'Tipo de conteúdo',
  'Fiche produit': 'Ficha de produto',
  GMB: 'GMB',
  'STORE LOCATOR': 'STORE LOCATOR',
  'Rédaction premium': 'Redação premium',
  'Rédaction avec révision': 'Redação smart + review',
  'Rédaction intelligente': 'Redação smart',
  PREMIUM: 'PREMIUM',
  Type: 'Tipo',
  Editer: 'Editar',
  'Tous les types': 'Todos os tipos',
  Smart: 'Smart',
  'Smart + review': 'Smart + review',
  'Moteur de recherche Netlinking': 'Wyszukiwarka Netlinking',
  'Paramètre de recherche': 'Parametr wyszukiwania',
  Standard: 'Standardowy',
  Premium: 'Premium',
  Thématique: 'Tematyczny',
  'Filtre avancé': 'Zaawansowany filtr',
  'Nombre de': 'Liczba',
  'Netlinking Trafic': 'Ruch Netlinking',
  'Mots clé positionés': 'Pozycjonowane słowa kluczowe',
  'Netlinking Tarif': 'Cena Netlinkingu',
  Chercher: 'Szukaj',
  Domaine: 'Domena',
  'Topic Majestic': 'Temat Majestic',
  'Kw positionés': 'Pozycjonowane Kw',
  Trafic: 'Ruch',
  Tarif: 'Cena',
  'Contacté le': 'Skontaktowany',
  Contact: 'Kontakt',
  'Rechercher par nom de domaine': 'Wyszukaj według nazwy domeny',
  'Fiche de Spot Netlinking': 'Karta Spot Netlinking',
  'Domaine Netlinking': 'Domena Netlinking',
  'Netlinking Thématique': 'Temat Netlinkingu',
  'Netlinking Topic Majestic': 'Temat Netlinkingu Majestic',
  Réinitialiser: 'Reiniciar',
  italian: 'italiano',
  english: 'inglês',
  german: 'alemão',
  spanish: 'espanhol',
  portuguese: 'português',
  french: 'francês',
};
