import { SuperConsultantHomePage } from 'pages/SuperConsultant/SuperConsultantHomepage';
import SuperConsultantProfile from 'pages/SuperConsultant/SuperConsultantProfile';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from './Layout';
import SuperConsultantOrdersOverdue from 'pages/SuperConsultant/SuperConsultantOrdersOverDue';
import SuperConsultantOrdersInProgress from 'pages/SuperConsultant/SuperConsultantOrdersInProgress';
import SuperConsultantFicheClient from 'pages/SuperConsultant/SuperConsultantFicheClient';
import superConsultantCampaignDetail from 'pages/SuperConsultant/superConsultantCampaignDetail';
import SuperConsultantCampaignDetail from 'pages/SuperConsultant/superConsultantCampaignDetail';
import SuperConsultantCreateCampaign from 'pages/SuperConsultant/SuperConsultantCreateCampaign';
import SuperConsultantOrdersByWebsite from 'pages/SuperConsultant/SuperConsultantOrdersByWebsite';
import SuperConsultantUnassignedOrders from 'pages/SuperConsultant/SuperConsultantUnassignedOrders';

export default class SuperConsultantMainMenu extends React.Component {
  render() {
    return (
      <Layout>
        <Switch>
          <Route
            exact
            path="/SuperConsultant"
            component={SuperConsultantHomePage}
          />
          <Route
            path="/SuperConsultant/MainMenu"
            component={SuperConsultantHomePage}
          />
          <Route
            path="/SuperConsultant/MonProfil"
            component={SuperConsultantProfile}
          />
          <Route
            path="/SuperConsultant/CampagnesEnCour"
            component={SuperConsultantOrdersInProgress}
          />
          <Route
            path="/SuperConsultant/CampagnesNonAttribuees"
            component={SuperConsultantUnassignedOrders}
          />
          <Route
            path="/SuperConsultant/CampagnesEnRetard"
            component={SuperConsultantOrdersOverdue}
          />
          <Route
            path="/SuperConsultant/FicheClient/:id"
            component={SuperConsultantFicheClient}
          ></Route>
          <Route
            path="/SuperConsultant/FicheClient/:id"
            component={SuperConsultantFicheClient}
          ></Route>
          <Route
            path="/SuperConsultant/DetailsCampagne"
            component={SuperConsultantCampaignDetail}
          />
          <Route
            path="/superConsultant/AjouterCampaign"
            component={SuperConsultantCreateCampaign}
          />
          <Route
            path="/SuperConsultant/Website/:id/Campagnes"
            component={SuperConsultantOrdersByWebsite}
          ></Route>
        </Switch>
      </Layout>
    );
  }
}
