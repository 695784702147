import { ListItemText, Menu, MenuItem } from '@mui/material';
import { withStyles } from '@mui/styles';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: '12px',
    margin: '0',
    padding: '0',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    width: '275px',
    '&:hover': {
      color: '3c3c3c',
      backgroundColor: 'rgba(255, 228, 38, 0.4);',
    },
    '&:focus': {
      color: '3c3c3c',
      backgroundColor: 'rgba(255, 228, 38, 0.4);',
    },
  },
}))(MenuItem);

export const CustomDropdownReadOnly = (props) => {
  return (
    <StyledMenu
      id="customized-menu"
      anchorEl={props.anchorEl}
      keepMounted
      open={Boolean(props.anchorEl)}
      onClose={props.handleClose}
    >
      {props.list.map((option, key) => (
        <StyledMenuItem key={key}>
          <ListItemText
            style={{
              textAlign: 'center',
              fontFamily: 'Poppins',
              fontWeight: '400',
              fontSize: '16px',
            }}
            primary={props.getOptionLabel(option)}
          />
        </StyledMenuItem>
      ))}
    </StyledMenu>
  );
};

export const CustomDropdownWithSelect = (props) => {
  return (
    <StyledMenu
      id="customized-menu"
      anchorEl={props.anchorEl}
      keepMounted
      open={Boolean(props.anchorEl)}
      onClose={props.handleClose}
    >
      {props.list.map((option, index) => (
        <StyledMenuItem
          key={index}
          selected={props.value === option}
          onClick={(option) => props.setValue(index)}
        >
          <ListItemText
            style={{
              textAlign: 'left',
              fontFamily: 'Poppins',
              fontWeight: '400',
              fontSize: '16px',
            }}
            primary={props.getOptionLabel(option)}
          />
          {props.value === option ? (
            <img
              style={{ float: 'right' }}
              src="Images/icon-check.png"
              alt="check"
            />
          ) : null}
        </StyledMenuItem>
      ))}
    </StyledMenu>
  );
};
