import { useTranslation } from 'react-i18next';

import { Modal } from 'reactstrap';

import { classes } from './modalClasses';

export const ConfirmationModal = (props) => {
  const { t } = useTranslation();
  return (
    <Modal style={classes.modalCustom} isOpen={props.open} backdrop={true}>
      <div style={classes.modalHeader} className="modal-header">
        <h5 style={classes.title} className="modal-title">
          {t('Vous êtes sûr')} ?
        </h5>
        <button
          type="button"
          onClick={props.handleClose}
          style={classes.btnClose}
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div style={classes.modalPad} className="modal-body">
        <div className="row row-mb-lg">
          <div className="col-12 col-md-12">
            <div style={classes.textConfirm}>{props.message}</div>
          </div>
        </div>
        <div style={classes.boxBtConfirm}>
          <button
            style={{ backgroundColor: '#ee3900' }}
            onClick={props.handleClose}
            className="confirm me-2"
          >
            {t('Annuler')}
          </button>
          <button
            style={{ backgroundColor: '#43b929' }}
            onClick={props.executeAction}
            className="confirm"
          >
            {t('Confirmer')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
