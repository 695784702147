import AdminCampaignDetail from 'pages/Admin/AdminCampaignDetail';
import AdminFicheClient from 'pages/Admin/AdminFicheClient';
import { AdminHomePage } from 'pages/Admin/AdminHomepage';
import AdminInProgressOrders from 'pages/Admin/AdminInProgressOrders';
import AdminNetlinkingDetail from 'pages/Admin/AdminNetlinkingDetail';
import AdminOverDueOrders from 'pages/Admin/AdminOverDueOrders';
import AdminSearchNetlinking from 'pages/Admin/AdminSearchNetlinking';
import AdminUnassignedOrders from 'pages/Admin/AdminUnassignedOrders';
import AdminUsersManagement from 'pages/Admin/AdminUsersManagement';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from './Layout';
import AdminProfile from 'pages/Admin/AdminProfile';
import AdminNetlinkingCreate from 'pages/Admin/AdminCreateNetlinking';

export default class AdminMainMenu extends React.Component {
  render() {
    return (
      <Layout>
        <Switch>
          <Route exact path="/Admin" component={AdminHomePage} />
          <Route path="/Admin/SpotDetail" component={AdminNetlinkingDetail} />
          <Route path="/Admin/MonProfil" component={AdminProfile} />
          <Route path="/Admin/ChercherSpot" component={AdminSearchNetlinking} />
          <Route path="/Admin/CreerSpot" component={AdminNetlinkingCreate} />
          <Route
            path="/Admin/GestionUtilisateur"
            component={AdminUsersManagement}
          />
          <Route path="/Admin/MainMenu" component={AdminHomePage} />
          <Route path="/Admin/FicheClient/:id" component={AdminFicheClient} />
          <Route
            path="/Admin/CampagnesEnCour"
            component={AdminInProgressOrders}
          />
          <Route
            path="/Admin/CampagnesEnRetard"
            component={AdminOverDueOrders}
          />
          <Route
            path="/Admin/CampagnesNonAttribuees"
            component={AdminUnassignedOrders}
          ></Route>
          <Route
            path="/Admin/DetailsCampagne"
            component={AdminCampaignDetail}
          />
          <Route path="/Admin/FicheClient" component={AdminFicheClient}></Route>
        </Switch>
      </Layout>
    );
  }
}
