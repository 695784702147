import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosFacturation from 'config/axiosFacturation';

export const getBills = createAsyncThunk(
  'admin/getAllBills',
  async (_, { rejectWithValue }) => {
    try {
      console.log('fd4s5s144fd');
      const response = await axiosFacturation.get(`/admin/getAllBills`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
