import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Layout from './Layout';
import { SuperAdminHomePage } from 'pages/SuperAdmin/SuperAdminHomepage';
import SuperAdminSearchNetlinking from 'pages/SuperAdmin/SuperAdminSearchNetlinking';
import SuperAdminNetlinkingDetail from 'pages/SuperAdmin/SuperAdminNetlinkingDetail';
import SuperAdminUsersManagement from 'pages/SuperAdmin/SuperAdminUsersManagement';
import SuperAdminCreateWebsite from 'pages/SuperAdmin/SuperAdminCreateWebsite';
import SuperAdminFicheClient from 'pages/SuperAdmin/SuperAdminFicheClient';
import SuperAdminCreateCampaign from '../../pages/SuperAdmin/SuperAdminCreateCampaign';
import SuperAdminInProgressOrders from 'pages/SuperAdmin/SuperAdminInProgressOrders';
import SuperAdminCampaignDetail from '../../pages/SuperAdmin/SuperAdminCampaignDetail';
import SuperAdminUnassignedOrders from 'pages/SuperAdmin/SuperAdminUnassignedOrders';
import SuperAdminOverDueOrders from 'pages/SuperAdmin/SuperAdminOverDueOrders';
import SuperAdminProfile from 'pages/SuperAdmin/SuperAdminProfile';
import SuperAdminNetlinkingCreate from 'pages/SuperAdmin/SuperAdminCreateNetlinking';

export default class AdminMainMenu extends React.Component {
  render() {
    return (
      <Layout>
        <Switch>
          <Route exact path="/SuperAdmin" component={SuperAdminHomePage} />
          <Route
            path="/SuperAdmin/SpotDetail"
            component={SuperAdminNetlinkingDetail}
          />
          <Route path="/SuperAdmin/MonProfil" component={SuperAdminProfile} />
          <Route
            path="/SuperAdmin/ChercherSpot"
            component={SuperAdminSearchNetlinking}
          />
          <Route
            path="/SuperAdmin/CreerSpot"
            component={SuperAdminNetlinkingCreate}
          />

          <Route
            path="/SuperAdmin/GestionUtilisateur"
            component={SuperAdminUsersManagement}
          />
          <Route path="/SuperAdmin/MainMenu" component={SuperAdminHomePage} />
          <Route
            path="/SuperAdmin/AjouterClient"
            component={SuperAdminCreateWebsite}
          />
          <Route
            path="/SuperAdmin/AjouterCampaign"
            component={SuperAdminCreateCampaign}
          />
          <Route
            path="/SuperAdmin/FicheClient/:id"
            component={SuperAdminFicheClient}
          />
          <Route
            path="/SuperAdmin/CampagnesEnCour"
            component={SuperAdminInProgressOrders}
          />
          <Route
            path="/SuperAdmin/CampagnesEnRetard"
            component={SuperAdminOverDueOrders}
          />
          <Route
            path="/SuperAdmin/CampagnesNonAttribuees"
            component={SuperAdminUnassignedOrders}
          ></Route>
          <Route
            path="/SuperAdmin/DetailsCampagne"
            component={SuperAdminCampaignDetail}
          />
        </Switch>
      </Layout>
    );
  }
}
