export function removeWWW(url) {
  let index = url.indexOf('www.');
  return index > -1 ? url.slice(index + 'www.'.length) : url;
}

export function removeWhitespace(string) {
  return string.replace(/^\s+|\s+$/g, '');
}

export function lower(string) {
  return string.toLowerCase();
}

export function replaceTextInBold(text, array) {
  let content = text;
  array.map((x) =>
    content.replace(
      new RegExp(`(?<![A-Za-zÀ-ÖØ-öø-ÿ])(${x})(?![A-Za-zÀ-ÖØ-öø-ÿ=])`, 'g'),
      '<strong>' + x + '</strong>'
    )
  );
  while (
    content.includes('<strong><strong>') &&
    content.includes('</strong></strong>')
  ) {
    content = content.replaceAll('<strong><strong>', '<strong>');
    content = content.replaceAll('</strong></strong>', '</strong>');
  }
  return content;
}

export function capitalizeFirstLetter(inputString) {
  // Check if the input is a valid string
  if (typeof inputString !== 'string' || inputString.length === 0) {
    return inputString; // Return the input as is if it's not a valid string
  }

  // Capitalize the first letter and concatenate it with the rest of the string
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}
