import { createSlice } from '@reduxjs/toolkit';
import { fetchClientOrderList } from './features';

export const clientOrderList = createSlice({
  name: 'clientOrderList',
  initialState: {
    orders: null,
    isLoading: true,
    isError: true,
  },
  reducers: {
    handleSorting: (state, { payload }) => {
      let array = [];

      array = [...state.orders];

      if (payload.sortField) {
        const sorted = [...array].sort((a, b) => {
          if (a[payload.sortField] === null) return 1;
          if (b[payload.sortField] === null) return -1;
          if (
            payload.sortField === 'deliveredTextsAmount' ||
            payload.sortField === 'validatedTextsAmount'
          ) {
            const pourcentageA = (a[payload.sortField] * 100) / a['textAmount'];
            const pourcentageB = (b[payload.sortField] * 100) / b['textAmount'];

            return payload.sortOrder === 'asc'
              ? pourcentageA - pourcentageB
              : pourcentageB - pourcentageA;
          }

          return (
            a[payload.sortField]
              .toString()
              .localeCompare(b[payload.sortField].toString(), 'en', {
                numeric: true,
              }) * (payload.sortOrder === 'asc' ? 1 : -1)
          );
        });

        state.orders = [...sorted];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientOrderList.fulfilled, (state, { payload }) => {
        state.orders = payload;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchClientOrderList.pending, (state, { payload }) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchClientOrderList.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default clientOrderList.reducer;
export const { handleSorting } = clientOrderList.actions;

export const clientOrderListSelector = (state) => {
  return state.clientOrderList;
};
