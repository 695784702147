import TokenService from 'config/token.service';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchUser } from 'store/UserSession/features';
import useLanguage from 'utils/hooks/useLanguage';

import axiosPixLink from 'config/axiosPixLink';
import './style/NavMenu.css';
const Languages = [
  { text: 'en', img: 'Images/img_flag/GB_flag.png' },
  { text: 'fr', img: 'Images/img_flag/FR_flag.png' },
  { text: 'de', img: 'Images/img_flag/DE_flag.png' },
  { text: 'es', img: 'Images/img_flag/ES_flag.png' },
  { text: 'it', img: 'Images/img_flag/IT_flag.png' },
  { text: 'da', img: 'Images/img_flag/DA_flag.png' },
  { text: 'fi', img: 'Images/img_flag/FI_flag.png' },
  { text: 'nb', img: 'Images/img_flag/NB_flag.png' },
  { text: 'nl', img: 'Images/img_flag/NL_flag.png' },
  { text: 'pl', img: 'Images/img_flag/PL_flag.png' },
  { text: 'pt', img: 'Images/img_flag/PT_flag.png' },
  { text: 'ro', img: 'Images/img_flag/RO_flag.png' },
  { text: 'sv', img: 'Images/img_flag/SV_flag.png' },
  { text: 'el', img: 'Images/img_flag/GK_flag.png' },
  { text: 'sk', img: 'Images/img_flag/SK_flag.png' },
  { text: 'cs', img: 'Images/img_flag/CS_flag.png' },
];

function NavMenu() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { language, changeLanguage } = useLanguage();

  const [expanded, setExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState(window.location.pathname);
  // const [currentLanguage, setCurrentLanguage] = useState(
  //   localStorage.getItem("language")
  // )

  useEffect(() => {
    const handleLocationChange = () => {
      setCurrentPage(window.location.pathname);
    };
    window.addEventListener('locationchange', handleLocationChange);

    return () => {
      window.removeEventListener('locationchange', handleLocationChange);
    };
  }, []);

  const handleChangeLanguage = (language) => {
    changeLanguage(language);
    axiosPixLink
      .put(`/user/${language}/updateLanguage`)
      .then(() => {
        console.log('language changed');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <header className="border-bottom fixed-top header-fix bg-light-wh bg-img-new-year-admin">
        <div
          className="container-fluid d-grid gap-3 align-items-center"
          style={{ gridTemplateColumns: '1fr 2fr' }}
        >
          <div className="dropdown">
            <button
              className="d-flex align-items-center col-lg-4 mb-lg-0 link-dark text-decoration-none border-0"
              id="dropdownLink"
              aria-expanded="false"
            ></button>
          </div>
          <div className="d-flex align-items-center">
            <form className="box-menu-list w-100 text-end">
              <div className="btn-menu-icon">
                <div
                  className={`${
                    currentPage === '/Client/MonProfil'
                      ? 'box-icon-menu bt-icon-user active'
                      : 'box-icon-menu bt-icon-user'
                  }`}
                  id="dropdownMenu2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                />
                <ul
                  className="dropdown-menu menu-list menu-list-sm"
                  aria-labelledby="dropdownMenu2"
                >
                  <li className="first-child">
                    <Link to="/Client/MonProfil">
                      <button className="dropdown-item" type="button">
                        {t('Mon profil')}
                      </button>
                    </Link>
                  </li>
                  <li className="last-child">
                    <button
                      onClick={() => {
                        TokenService.removeUser();
                        dispatch(fetchUser(null));
                      }}
                      className="dropdown-item"
                      type="button"
                    >
                      {t('Déconnexion')}
                    </button>
                  </li>
                </ul>
              </div>
              <div className="btn-menu-icon">
                <button
                  className="btn dropdown-toggle d-flex flex-row align-items-baseline"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    <div className="flag-img-container-country">
                      <img
                        src={
                          Languages.find(
                            (languageAttempt) =>
                              languageAttempt.text === language
                          ).img
                        }
                        alt="langage icon"
                      />
                    </div>
                  </span>
                </button>
                <ul
                  className="dropdown-menu menu-list-nav menu-list-lng"
                  aria-labelledby="dropdownMenu2"
                >
                  {Languages.map((language, index) => (
                    <li
                      key={index}
                      onClick={() => handleChangeLanguage(language.text)}
                    >
                      <button
                        className="dropdown-item d-flex justify-content-center"
                        type="button"
                      >
                        <div className="flag-img-container">
                          <img src={language.img} alt="language icon" />
                        </div>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </form>
          </div>
        </div>
      </header>
      <div className="box-menu-left">
        <div className={`box-radius-3 ${expanded ? 'expand' : ''}`} />
        <div className="box-radius bg-img-new-year-1">
          <Link tag={Link} to="/Client/MainMenu">
            <img
              className="logo-m"
              src="Images/pixlinkLogo.png"
              alt="pixlinkLogo.png"
            />
          </Link>
        </div>
        <div className={`box-radius-2 ${expanded ? 'active' : ''}`} />
        <div
          className="wrapper-box-menu-left"
          onMouseEnter={() => setExpanded(true)}
          onMouseLeave={() => setExpanded(false)}
        >
          <Link tag={Link} to="/Client/MainMenu">
            <div
              className={`${
                currentPage === '/Client/MainMenu'
                  ? 'box-menu-block-admin bt-icon-dashboard active'
                  : 'box-menu-block-admin bt-icon-dashboard'
              }`}
            ></div>
          </Link>
          <Link tag={Link} to="/Client/CampagnesEnCour">
            <div
              className={`${
                currentPage === '/Client/CampagnesEnCour'
                  ? 'box-menu-block-admin bt-icon-encour active'
                  : 'box-menu-block-admin bt-icon-encour'
              }`}
            ></div>
          </Link>
          <Link tag={Link} to="/Client/CampagnesEnRetard">
            <div
              className={`${
                currentPage === '/Client/CampagnesEnRetard'
                  ? 'box-menu-block-admin bt-icon-enretard active'
                  : 'box-menu-block-admin bt-icon-enretard'
              }`}
            ></div>
          </Link>
          <Link tag={Link} to="/Client/CampagnesEnLigne">
            <div
              className={`${
                currentPage === '/Client/CampagnesEnLigne'
                  ? 'box-menu-block-admin bt-icon-validee active'
                  : 'box-menu-block-admin bt-icon-validee'
              }`}
            ></div>
          </Link>
          <Link tag={Link} to="/Client/LiensSupprimé">
            <div
              className={`${
                currentPage === '/Client/LiensSupprimé'
                  ? 'box-menu-block-admin bt-icon-smart-orders active'
                  : 'box-menu-block-admin bt-icon-smart-orders'
              }`}
            ></div>
          </Link>

          <div
            className={`box-sub-menu ${expanded ? 'active' : ''}`}
            id="sidebar"
          >
            <div className="box-subtext-menu-admin">
              <div className="box-text-menu-admin">{t('Dashboard')}</div>
            </div>

            <div className="box-subtext-menu-admin">
              <div className="box-text-menu-admin">{t('En cours')}</div>
            </div>
            <div className="box-subtext-menu-admin">
              <div className="box-text-menu-admin">{t('En retard')}</div>
            </div>
            <div className="box-subtext-menu-admin">
              <div className="box-text-menu-admin">{t('En ligne')}</div>
            </div>
            <div className="box-subtext-menu-admin">
              <div className="box-text-menu-admin">{t('Liens supprimés')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavMenu;
