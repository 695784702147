export function getLanguageNameById(id) {
  const idToLanguage = {
    1: 'english',
    2: 'german',
    3: 'spanish',
    4: 'italian',
    5: 'portuguese',
    6: 'french',
  };
  return idToLanguage[id];
}

export function getLanguageIdByName(name) {
  const idToLanguage = {
    1: 'english',
    2: 'german',
    3: 'spanish',
    4: 'italian',
    5: 'portuguese',
    6: 'french',
  };
  const languageToId = {};
  for (const id in idToLanguage) {
    const language = idToLanguage[id];
    languageToId[language] = parseInt(id);
  }
  return languageToId[name];
}

export function getLanguageRefById(id) {
  const idToLanguage = {
    1: 'En',
    2: 'De',
    3: 'Es',
    4: 'It',
    5: 'Pt',
    6: 'Fr',
  };
  return idToLanguage[id];
}

export function getLanguageIdByRef(name) {
  const idToLanguage = {
    1: 'En',
    2: 'De',
    3: 'Es',
    4: 'It',
    5: 'Pt',
    6: 'Fr',
  };
  const languageToId = {};
  for (const id in idToLanguage) {
    const language = idToLanguage[id];
    languageToId[language] = parseInt(id);
  }
  return languageToId[name];
}
