import React, { useState, useEffect } from 'react';
import axiosPixLink from 'config/axiosPixLink';
import { Link, useHistory } from 'react-router-dom';
import LanguageCheckBoxGroup from 'components/Netlinking/CheckBox/LanguageCheckBox';
import TypeCheckBoxGroup from 'components/Netlinking/CheckBox/TypeCheckBox';
import { ThemeSearchField } from 'components/Netlinking/AutoComplete/themeAutoComplete';
import { Loader } from 'components/shared/Loader';
import { NetlinkingTable } from 'components/Netlinking/Table/Table';
import SliderComponent from 'components/Netlinking/Slider/NetlinkingSlider';
import CheckBox from 'components/shared/CheckBox';
import { ExcelExportButton } from 'components/shared/ExcelExport/ExcelExportButton';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { ChevronDownIcon } from 'icons/ChevronIcon';
import { PAGE_NB_OPTIONS } from 'utils/Constants';
export default function SuperAdminSearchNetlinking() {
  const pageNbOptions = PAGE_NB_OPTIONS;
  const { t } = useTranslation();
  const history = useHistory();
  const [netlinkingType, setNetlinkingType] = useState('');
  const [ordersPerPage, setOrdersPerPage] = useState(10);
  const [netlinkingLanguage, setNetlinkingLanguage] = useState('');
  const [netlinkingList, setNetlinkingList] = useState([]);
  const [netlinkingFilteredList, setNetlinkingFilteredList] = useState([]);
  const [nameSearch, setNameSearch] = useState('');
  const [netlinkingTheme, setNetlinkingTheme] = useState([]);
  const [filteringLoading, setFilteringLoading] = useState(false);
  const [netlinkingContactFilter, setNetlinkingContactFilter] = useState('');
  const [showTable, setShowTable] = useState(false);
  const [activeAdvancedFilter, setActiveAdvancedFilter] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [dataExcelToExport, setDataExcelToExport] = useState([]);
  function handleDataExcelToExport(data) {
    console.log('this is', data);
    setDataExcelToExport(data);
  }

  function handleTypeCheckboxChange(newStates) {
    if (!newStates.checkbox1 && !newStates.checkbox2 && !newStates.checkbox3) {
      setNetlinkingType('');
    }
    if (newStates.checkbox1) {
      setNetlinkingType('standard');
      /** TF Filter */
      setMinTFValue(18);
      setMaxTFValue(Infinity);
      setTFValues([18, Infinity]);
      /** Trafic Filter */
      setMinTraficValue(1000);
      setMaxTraficValue(Infinity);
      setTraficValues([1000, Infinity]);
      /** Keyword positioned */
      setMinKwValue(100);
      setMaxKwValue(Infinity);
      setKwvalues([100, Infinity]);
      setTarifHRValues([0, 680]);
      setTarifRCValues([0, 750]);
      setMaxTarifHRValue(680);
      setMaxTarifRCValue(750);
      setMinTarifHRValue(0);
      setMinTarifRCValue(0);
    } else if (newStates.checkbox2) {
      setNetlinkingType('premium');
      /** TF Filter */
      setMinTFValue(30);
      setMaxTFValue(Infinity);
      setTFValues([30, Infinity]);
      /** Trafic Filter */
      setMinTraficValue(25000);
      setMaxTraficValue(Infinity);
      setTraficValues([25000, Infinity]);
      /** Keyword positioned */
      setMinKwValue(200);
      setMaxKwValue(Infinity);
      setKwvalues([200, Infinity]);
      setTarifHRValues([0, 680]);
      setTarifRCValues([0, 750]);
      setMaxTarifHRValue(680);
      setMaxTarifRCValue(750);
      setMinTarifHRValue(0);
      setMinTarifRCValue(0);
    } else if (newStates.checkbox3) {
      setNetlinkingType('redType');
      /** TF Filter */
      setMinTFValue(0);
      setMaxTFValue(18);
      setTFValues([0, 18]);
      /** Trafic Filter */
      setMinTraficValue(0);
      setMaxTraficValue(1000);
      setTraficValues([0, 1000]);
      setTarifHRValues([0, 680]);
      setTarifRCValues([0, 750]);
      setMaxTarifHRValue(680);
      setMaxTarifRCValue(750);
      setMinTarifHRValue(0);
      setMinTarifRCValue(0);
    }
  }

  function handleThemeChange(newTheme) {
    if (
      newTheme !== null &&
      newTheme !== undefined &&
      !netlinkingTheme.includes(newTheme)
    ) {
      setNetlinkingTheme([...netlinkingTheme, newTheme]);
    }
  }

  function handleActiveAdvancedFilter() {
    setActiveAdvancedFilter(!activeAdvancedFilter);
  }

  function removeTheme(theme) {
    const updatedThemes = netlinkingTheme.filter((t) => t !== theme);
    setNetlinkingTheme(updatedThemes);
  }
  function handleLanguageCheckboxChange(newStates) {
    if (
      !newStates.checkbox1 &&
      !newStates.checkbox2 &&
      !newStates.checkbox3 &&
      !newStates.checkbox4 &&
      !newStates.checkbox5 &&
      !newStates.checkbox6
    ) {
      setNetlinkingLanguage('');
    }
    if (newStates.checkbox1) {
      setNetlinkingLanguage(6);
    } else if (newStates.checkbox2) {
      setNetlinkingLanguage(1);
    } else if (newStates.checkbox3) {
      setNetlinkingLanguage(3);
    } else if (newStates.checkbox4) {
      setNetlinkingLanguage(2);
    } else if (newStates.checkbox5) {
      setNetlinkingLanguage(4);
    } else if (newStates.checkbox6) {
      setNetlinkingLanguage(5);
    }
  }
  async function removeLanguageFilter() {
    setNetlinkingLanguage('');
  }

  async function removeNetlinkingContactFilter() {
    setNetlinkingContactFilter('');
  }
  function runSearchFunction() {
    setFilteringLoading(true);
    searchFunction();
    setShowTable(true);
    setPopupOpen(false);
    setFilteringLoading(false);
  }
  function searchFunction() {
    let tmpNetlinkingLanguage = netlinkingList;
    if (netlinkingLanguage !== '') {
      tmpNetlinkingLanguage = netlinkingList.filter(
        (item) => item.languageId === netlinkingLanguage
      );
    }
    let tmpNetlinkingType = tmpNetlinkingLanguage;
    let tmpNetlinkingTheme = tmpNetlinkingType;
    if (netlinkingTheme.length > 0) {
      tmpNetlinkingTheme = tmpNetlinkingTheme.filter((row) => {
        return netlinkingTheme.some(
          (value) => row.themesList !== null && row.themesList.includes(value)
        );
      });
    }

    let tmpNetlinkingFilter = tmpNetlinkingTheme;

    tmpNetlinkingFilter = tmpNetlinkingFilter.filter(
      (item) =>
        ((item.tF >= TFValues[0] && item.tF <= TFValues[1]) ||
          (TFValues[0] === 0 && TFValues[1] === 0)) &&
        ((item.trafic >= TraficValues[0] && item.trafic <= TraficValues[1]) ||
          (TraficValues[0] === 0 && TraficValues[1] === 0)) &&
        ((item.tarifHr >= TarifHRValues[0] &&
          item.tarifHr <= TarifHRValues[1]) ||
          (TarifHRValues[0] === 0 && TarifHRValues[1] === 0)) &&
        ((item.tarifRc >= TarifRCValues[0] &&
          item.tarifRc <= TarifRCValues[1]) ||
          (TarifRCValues[0] === 0 && TarifRCValues[1] === 0)) &&
        ((item.kwPositioned >= Kwvalues[0] &&
          item.kwPositioned <= Kwvalues[1]) ||
          (Kwvalues[0] === 0 && Kwvalues[1] === 0))
    );

    let tmpNetlinkingContactFilter = tmpNetlinkingFilter;

    if (netlinkingContactFilter != '') {
      tmpNetlinkingContactFilter = tmpNetlinkingContactFilter.filter(
        (item) =>
          item.contact != null &&
          item.contact != undefined &&
          item.contact.includes(netlinkingContactFilter)
      );
    }
    setNetlinkingFilteredList(tmpNetlinkingContactFilter);
  }

  function resetFunction() {
    setNetlinkingContactFilter('');
    setNetlinkingLanguage('');
    setNetlinkingType('');
    setNetlinkingTheme([]);
    /**TF */
    setMinTFValue(0);
    setMaxTFValue(0);
    setTFValues([0, 0]);
    /** Trafic Filter */
    setMinTraficValue(0);
    setMaxTraficValue(0);
    setTraficValues([0, 0]);
    /** Keyword positioned */
    setMinKwValue(0);
    setMaxKwValue(0);
    setKwvalues([0, 0]);
    /** Netlinking Pricing RC */
    setMinTarifRCValue(0);
    setMaxTarifRCValue(0);
    setTarifRCValues([0, 0]);
    /** Netlinking Pricing HR */
    setMinTarifHRValue(0);
    setMaxTarifHRValue(0);
    setTarifHRValues([0, 0]);
    setNetlinkingFilteredList(netlinkingList);
  }

  const [Kwvalues, setKwvalues] = useState([0, 0]);
  const [minKwValue, setMinKwValue] = useState(Kwvalues[0]);
  const [maxKwValue, setMaxKwValue] = useState(Kwvalues[1]);

  const handleSliderChange = (event, newValue) => {
    setKwvalues(newValue);
    setMinKwValue(newValue[0]);
    setMaxKwValue(newValue[1]);
  };

  const handleMinInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinKwValue(newMinValue);
    setKwvalues([newMinValue, Kwvalues[1]]);
  };

  const handleMaxInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxKwValue(newMaxValue);
    setKwvalues([Kwvalues[0], newMaxValue]);
  };

  const [TFValues, setTFValues] = useState([0, 0]);
  const [minTFValue, setMinTFValue] = useState(TFValues[0]);
  const [maxTFValue, setMaxTFValue] = useState(TFValues[1]);

  const handleSliderTFChange = (event, newValue) => {
    setTFValues(newValue);
    setMinTFValue(newValue[0]);
    setMaxTFValue(newValue[1]);
  };

  const handleMinTFInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTFValue(newMinValue);
    setTFValues([newMinValue, TFValues[1]]);
  };

  const handleMaxTFInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTFValue(newMaxValue);
    setTFValues([TFValues[0], newMaxValue]);
  };

  const [TraficValues, setTraficValues] = useState([0, 0]);
  const [minTraficValue, setMinTraficValue] = useState(TraficValues[0]);
  const [maxTraficValue, setMaxTraficValue] = useState(TraficValues[1]);

  const handleSliderTraficChange = (event, newValue) => {
    setTraficValues(newValue);
    setMinTraficValue(newValue[0]);
    setMaxTraficValue(newValue[1]);
  };

  const handleMinTraficInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTraficValue(newMinValue);
    setTraficValues([newMinValue, TraficValues[1]]);
  };

  const handleMaxTraficInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTraficValue(newMaxValue);
    setTraficValues([TraficValues[0], newMaxValue]);
  };

  const [TarifHRValues, setTarifHRValues] = useState([0, 0]);
  const [minTarifHRValue, setMinTarifHRValue] = useState(TarifHRValues[0]);
  const [maxTarifHRValue, setMaxTarifHRValue] = useState(TarifHRValues[1]);

  const handleSliderTarifHRChange = (event, newValue) => {
    setTarifHRValues(newValue);
    setMinTarifHRValue(newValue[0]);
    setMaxTarifHRValue(newValue[1]);
  };

  const handleMinTarifHRInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTarifHRValue(newMinValue);
    setTarifHRValues([newMinValue, TarifHRValues[1]]);
  };

  const handleMaxTarifHRInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTarifHRValue(newMaxValue);
    setTarifHRValues([TarifHRValues[0], newMaxValue]);
  };

  const [TarifRCValues, setTarifRCValues] = useState([0, 0]);
  const [minTarifRCValue, setMinTarifRCValue] = useState(TarifRCValues[0]);
  const [maxTarifRCValue, setMaxTarifRCValue] = useState(TarifRCValues[1]);

  const handleSliderTarifRCChange = (event, newValue) => {
    setTarifRCValues(newValue);
    setMinTarifRCValue(newValue[0]);
    setMaxTarifRCValue(newValue[1]);
  };

  const handleMinTarifRCInputChange = (event) => {
    const newMinValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMinTarifRCValue(newMinValue);
    setTarifRCValues([newMinValue, TarifRCValues[1]]);
  };

  const handleMaxTarifRCInputChange = (event) => {
    const newMaxValue =
      event.target.value === '' ? '' : Number(event.target.value);
    setMaxTarifRCValue(newMaxValue);
    setTarifRCValues([TarifRCValues[0], newMaxValue]);
  };
  function handleLanguageId(value) {
    let result;

    switch (value) {
      case 6:
        result = 'Français';
        break;
      case 1:
        result = 'English';
        break;
      case 2:
        result = 'Deutsch';
        break;
      case 3:
        result = 'Español';
        break;
      case 4:
        result = 'Italiano';
        break;
      case 5:
        result = 'Português';
        break;
      default:
        result = 'Français';
        break;
    }

    return result;
  }

  const handleValueNetlinkingContactFilter = (value) => {
    setNetlinkingContactFilter(value);
  };
  useEffect(() => {
    searchFunction();
  }, [
    netlinkingLanguage,
    netlinkingType,
    netlinkingTheme,
    TFValues,
    TraficValues,
    Kwvalues,
    TarifHRValues,
    TarifRCValues,
    netlinkingContactFilter,
  ]);
  useEffect(() => {
    if (
      window.location.href.split('/')[
        window.location.href.split('/').length - 1
      ] == 'table'
    ) {
      setFilteringLoading(true);
    }
    setFilteringLoading(true);

    window.dispatchEvent(new Event('locationchange'));
    axiosPixLink.get('netlinking/all-netlinkings').then((res) => {
      setNetlinkingList(res.data);
      setNetlinkingFilteredList(res.data);
    });
    /* setTimeout(() => {*/
    if (
      window.location.href.split('/')[
        window.location.href.split('/').length - 1
      ] == 'table'
    ) {
      setShowTable(true);
    }
    setTimeout(() => {
      setFilteringLoading(false);
    }, '1000');
    /*  }, '100');*/
  }, []);
  return (
    <div>
      <div className="content-center">
        <div className="box-header-top">
          <span
            onClick={() => {
              setActiveAdvancedFilter(true);
              setShowTable(false);
            }}
            className="bt-circle me-3"
          />
          {showTable &&
            (filteringLoading ? (
              <h1>{'XXX' + ' ' + 'Spot trouvé'}</h1>
            ) : (
              <h1>
                {netlinkingFilteredList.length === 0
                  ? 'XXX' + ' ' + 'Spot trouvé'
                  : netlinkingFilteredList.length + ' ' + 'Spot trouvé'}
              </h1>
            ))}
          {!showTable && <h1>{t('Moteur de recherche Netlinking')}</h1>}
        </div>
        {showTable && filteringLoading && <Loader />}
        {!showTable && (
          <div className="row">
            <div className="box-img-bg bg-2" />
            <div className="box-img-bg bg-1" />
            <div className="col-12 col-md-12 box-screen">
              <div className="box-img-bg bg-3" />
              <div className="box-wrapper">
                <div className="header-title">
                  {t('Paramètre de recherche')}
                </div>
                <div className="row row-mb">
                  <div className="label-title">{t('Type')} :</div>
                  <TypeCheckBoxGroup
                    onCheckboxChange={handleTypeCheckboxChange}
                    initialStateString={netlinkingType}
                  />
                </div>

                <div className="row row-mb">
                  <div className="col-12 col-md-6">
                    <div className="label-title">{t('Thématique')} :</div>
                    <ThemeSearchField onThemeChange={handleThemeChange} />
                  </div>
                </div>
                <div className="theme-list-container-filter">
                  {netlinkingTheme.map((theme, index) => (
                    <span key={index} className="theme-container">
                      <span>{theme}</span>
                      <button onClick={() => removeTheme(theme)}>x</button>
                    </span>
                  ))}
                </div>
                <div className="row row-mb">
                  <div className="label-title">{t('Langue')} :</div>
                  <LanguageCheckBoxGroup
                    onCheckboxChange={handleLanguageCheckboxChange}
                    initialStateString={netlinkingLanguage}
                  />
                </div>
                <div class="row row-mb">
                  <div class="label-title">
                    <span class="label-title-filtre">
                      {t('Filtre avancé')} :
                    </span>

                    <CheckBox
                      name="Checkbox 1"
                      tick={activeAdvancedFilter}
                      onCheck={handleActiveAdvancedFilter}
                    />
                  </div>
                </div>

                {activeAdvancedFilter && (
                  <div>
                    <div className="row row-mb">
                      {/* First Slider */}
                      <SliderComponent
                        SliderName={t('Nombre de') + ' TF'}
                        values={TFValues}
                        handleSliderChange={handleSliderTFChange}
                        minValue={minTFValue}
                        maxValue={maxTFValue}
                        handleMinInputChange={handleMinTFInputChange}
                        handleMaxInputChange={handleMaxTFInputChange}
                        SliderLabel={'TF'}
                      />

                      {/* Second Slider */}

                      <SliderComponent
                        SliderName={t('Netlinking Trafic')}
                        values={TraficValues}
                        handleSliderChange={handleSliderTraficChange}
                        minValue={minTraficValue}
                        maxValue={maxTraficValue}
                        handleMinInputChange={handleMinTraficInputChange}
                        handleMaxInputChange={handleMaxTraficInputChange}
                        SliderLabel={'Trafic'}
                      />

                      {/* Third Slider */}
                      <SliderComponent
                        SliderName={t('Mots clé positionés')}
                        values={Kwvalues}
                        handleSliderChange={handleSliderChange}
                        minValue={minKwValue}
                        maxValue={maxKwValue}
                        handleMinInputChange={handleMinInputChange}
                        handleMaxInputChange={handleMaxInputChange}
                        SliderLabel={'keywords positioned'}
                      />
                    </div>
                    <div className="row row-mb">
                      <SliderComponent
                        SliderName={t('Netlinking Tarif') + ' RC'}
                        values={TarifRCValues}
                        handleSliderChange={handleSliderTarifRCChange}
                        minValue={minTarifRCValue}
                        maxValue={maxTarifRCValue}
                        handleMinInputChange={handleMinTarifRCInputChange}
                        handleMaxInputChange={handleMaxTarifRCInputChange}
                        SliderLabel={'Tarif RC'}
                      />{' '}
                      <SliderComponent
                        SliderName={t('Netlinking Tarif') + ' HR'}
                        values={TarifHRValues}
                        handleSliderChange={handleSliderTarifHRChange}
                        minValue={minTarifHRValue}
                        maxValue={maxTarifHRValue}
                        handleMinInputChange={handleMinTarifHRInputChange}
                        handleMaxInputChange={handleMaxTarifHRInputChange}
                        SliderLabel={'Tarif HR'}
                      />
                      <div className="box-button-bt">
                        <div>
                          <button
                            className="bt-submit-y"
                            onClick={resetFunction}
                          >
                            {t('Réinitialiser')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="box-button-bt">
                <div>
                  <button className="bt-submit-y" onClick={runSearchFunction}>
                    {t('Chercher')}
                  </button>
                </div>
              </div>
              {true && (
                <div className="box-button-bt-add">
                  <div>
                    <button
                      className="bt-submit-y"
                      onClick={() => history.push(`/SuperAdmin/CreerSpot`)}
                    >
                      {t('Ajouter')}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {showTable && (
          <div>
            <div className="input-container-excel">
              <div className="input-group input-cs w-80">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('Rechercher par nom de domaine')}
                  value={nameSearch}
                  onChange={(e) => setNameSearch(e.target.value)}
                />
              </div>
              <div className="d-flex align-items-center item-per-table">
                <span className="me-2">{t('Afficher les résultats')}</span>
                <div className="dropdown" style={{ width: '70px' }}>
                  <button
                    className="btn dropdown-toggle dropdown-custom w-100"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {ordersPerPage}

                    <span>
                      <ChevronDownIcon />
                    </span>
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    {pageNbOptions
                      .filter((nb) => nb !== ordersPerPage)
                      .map((item, index) => (
                        <li key={index}>
                          <button
                            className="dropdown-item"
                            onClick={() => setOrdersPerPage(item)}
                          >
                            {item}
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div
                className={
                  netlinkingLanguage !== '' ||
                  netlinkingType !== '' ||
                  netlinkingTheme.length > 0 ||
                  TFValues[0] !== 0 ||
                  TFValues[1] !== 0 ||
                  TraficValues[0] !== 0 ||
                  TraficValues[1] !== 0 ||
                  Kwvalues[0] !== 0 ||
                  Kwvalues[1] !== 0 ||
                  TarifRCValues[0] !== 0 ||
                  TarifRCValues[1] !== 0 ||
                  TarifHRValues[0] !== 0 ||
                  TarifHRValues[1] !== 0
                    ? 'bt-filter-circle-selected vertical-col-filter'
                    : 'bt-filter-circle'
                }
                onClick={() => {
                  setActiveAdvancedFilter(false);
                  setPopupOpen(!popupOpen);
                }}
              ></div>

              {showTable && netlinkingList && netlinkingList.length > 0 && (
                <ExcelExportButton
                  netlinkingData={netlinkingList}
                  netlinkingFilteredListData={netlinkingFilteredList}
                  netlinkingExportList={dataExcelToExport}
                ></ExcelExportButton>
              )}
            </div>
            {popupOpen && (
              <Modal isOpen={popupOpen} backdrop={true}>
                <div>
                  <div className="col-12 col-md-12 box-screen">
                    <div className="box-img-bg bg-3" />
                    <div className="box-wrapper">
                      <button
                        type="button"
                        onClick={() => {
                          setPopupOpen(false);
                        }}
                        className="btn-close btnClose"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                      <div className="header-title">
                        {t('Paramètre de recherche')}
                      </div>
                      <div className="row row-mb">
                        <div className="label-title">{t('Type')} :</div>
                        <TypeCheckBoxGroup
                          onCheckboxChange={handleTypeCheckboxChange}
                          initialStateString={netlinkingType}
                        />
                      </div>

                      <div className="row row-mb">
                        <div className="col-12 col-md-6">
                          <div className="label-title">{t('Thématique')} :</div>
                          <ThemeSearchField onThemeChange={handleThemeChange} />
                        </div>
                      </div>
                      <div className="theme-list-container-filter">
                        {netlinkingTheme.map((theme, index) => (
                          <span key={index} className="theme-container">
                            <span>{theme}</span>
                            <button onClick={() => removeTheme(theme)}>
                              x
                            </button>
                          </span>
                        ))}
                      </div>
                      <div className="row row-mb">
                        <div className="label-title">{t('Langue')} :</div>
                        <LanguageCheckBoxGroup
                          onCheckboxChange={handleLanguageCheckboxChange}
                          initialStateString={netlinkingLanguage}
                        />
                      </div>
                      <div class="row row-mb">
                        <div class="label-title">
                          <span class="label-title-filtre">
                            {t('Filtre avancé')} :
                          </span>

                          <CheckBox
                            name="Checkbox 1"
                            tick={activeAdvancedFilter}
                            onCheck={handleActiveAdvancedFilter}
                          />
                        </div>
                      </div>

                      {activeAdvancedFilter && (
                        <div>
                          <div className="row row-mb">
                            {/* First Slider */}
                            <SliderComponent
                              SliderName={t('Nombre de') + ' TF'}
                              values={TFValues}
                              handleSliderChange={handleSliderTFChange}
                              minValue={minTFValue}
                              maxValue={maxTFValue}
                              handleMinInputChange={handleMinTFInputChange}
                              handleMaxInputChange={handleMaxTFInputChange}
                              SliderLabel={'TF'}
                            />

                            {/* Second Slider */}

                            <SliderComponent
                              SliderName={t('Netlinking Trafic')}
                              values={TraficValues}
                              handleSliderChange={handleSliderTraficChange}
                              minValue={minTraficValue}
                              maxValue={maxTraficValue}
                              handleMinInputChange={handleMinTraficInputChange}
                              handleMaxInputChange={handleMaxTraficInputChange}
                              SliderLabel={'Trafic'}
                            />

                            {/* Third Slider */}
                            <SliderComponent
                              SliderName={t('Mots clé positionés')}
                              values={Kwvalues}
                              handleSliderChange={handleSliderChange}
                              minValue={minKwValue}
                              maxValue={maxKwValue}
                              handleMinInputChange={handleMinInputChange}
                              handleMaxInputChange={handleMaxInputChange}
                              SliderLabel={'keywords positioned'}
                            />
                          </div>
                          <div className="row row-mb">
                            <SliderComponent
                              SliderName={t('Netlinking Tarif') + ' RC'}
                              values={TarifRCValues}
                              handleSliderChange={handleSliderTarifRCChange}
                              minValue={minTarifRCValue}
                              maxValue={maxTarifRCValue}
                              handleMinInputChange={handleMinTarifRCInputChange}
                              handleMaxInputChange={handleMaxTarifRCInputChange}
                              SliderLabel={'Tarif RC'}
                            />{' '}
                            <SliderComponent
                              SliderName={t('Netlinking Tarif') + ' HR'}
                              values={TarifHRValues}
                              handleSliderChange={handleSliderTarifHRChange}
                              minValue={minTarifHRValue}
                              maxValue={maxTarifHRValue}
                              handleMinInputChange={handleMinTarifHRInputChange}
                              handleMaxInputChange={handleMaxTarifHRInputChange}
                              SliderLabel={'Tarif HR'}
                            />
                          </div>
                        </div>
                      )}
                      <div className="box-button-bt">
                        <div style={{ margin: '20px' }}>
                          <button
                            className="bt-submit-y"
                            onClick={resetFunction}
                          >
                            {t('Réinitialiser')}
                          </button>
                        </div>
                        <div>
                          <button
                            className="bt-submit-y"
                            onClick={runSearchFunction}
                          >
                            {t('Chercher')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            )}
            {(netlinkingLanguage !== '' ||
              netlinkingType !== '' ||
              netlinkingTheme.length > 0 ||
              TFValues[0] !== 0 ||
              TFValues[1] !== 0 ||
              TraficValues[0] !== 0 ||
              TraficValues[1] !== 0 ||
              Kwvalues[0] !== 0 ||
              Kwvalues[1] !== 0 ||
              TarifRCValues[0] !== 0 ||
              TarifRCValues[1] !== 0 ||
              TarifHRValues[0] !== 0 ||
              TarifHRValues[1] !== 0 ||
              netlinkingContactFilter != '') && (
              <div class="bright-yellow-div">
                {netlinkingType !== '' && (
                  <>
                    <b>Type:</b>{' '}
                    <span className="theme-container click-pointer">
                      <span
                        onClick={() => {
                          setActiveAdvancedFilter(false);
                          setPopupOpen(!popupOpen);
                        }}
                      >
                        {netlinkingType}
                      </span>
                      <button
                        onClick={() => {
                          setNetlinkingType('');
                          setTFValues([0, 0]);
                          setMinTFValue(0);
                          setMaxTFValue(0);
                          setTraficValues([0, 0]);
                          setMinTraficValue(0);
                          setMaxTraficValue(0);
                          setKwvalues([0, 0]);
                          setMinKwValue(0);
                          setMaxKwValue(0);
                        }}
                      >
                        x
                      </button>
                    </span>
                  </>
                )}
                {netlinkingLanguage !== '' && (
                  <>
                    <b>Langue:</b>{' '}
                    <span className="theme-container click-pointer">
                      <span
                        onClick={() => {
                          setActiveAdvancedFilter(false);
                          setPopupOpen(!popupOpen);
                        }}
                      >
                        {handleLanguageId(netlinkingLanguage)}
                      </span>
                      <button
                        onClick={() => {
                          removeLanguageFilter();
                        }}
                      >
                        x
                      </button>
                    </span>
                  </>
                )}
                {netlinkingTheme.length > 0 && (
                  <>
                    <b>Theme:</b>{' '}
                    {netlinkingTheme.map((theme, index) => (
                      <span
                        key={index}
                        className="theme-container click-pointer"
                        onClick={() => {
                          setActiveAdvancedFilter(false);
                          setPopupOpen(!popupOpen);
                        }}
                      >
                        <span>{theme}</span>
                        <button
                          onClick={() => {
                            removeTheme(theme);
                          }}
                        >
                          x
                        </button>
                      </span>
                    ))}
                  </>
                )}
                {(TFValues[0] !== 0 || TFValues[1] !== 0) && (
                  <>
                    <b>TF:</b>{' '}
                    <span className="theme-container click-pointer">
                      <span
                        onClick={() => {
                          setActiveAdvancedFilter(false);
                          setPopupOpen(!popupOpen);
                        }}
                      >
                        {TFValues[0]}-{TFValues[1]}
                      </span>
                      <button
                        onClick={() => {
                          setTFValues([0, 0]);
                          setMinTFValue(0);
                          setMaxTFValue(0);
                        }}
                      >
                        x
                      </button>
                    </span>
                  </>
                )}
                {(TraficValues[0] !== 0 || TraficValues[1] !== 0) && (
                  <>
                    <b>Trafic:</b>{' '}
                    <span className="theme-container click-pointer">
                      <span
                        onClick={() => {
                          setActiveAdvancedFilter(false);
                          setPopupOpen(!popupOpen);
                        }}
                      >
                        {TraficValues[0]}-{TraficValues[1]}
                      </span>
                      <button
                        onClick={() => {
                          setTraficValues([0, 0]);
                          setMinTraficValue(0);
                          setMaxTraficValue(0);
                        }}
                      >
                        x
                      </button>
                    </span>
                  </>
                )}
                {(Kwvalues[0] !== 0 || Kwvalues[1] !== 0) && (
                  <>
                    <b>Keyword Positioned:</b>{' '}
                    <span className="theme-container click-pointer">
                      <span
                        onClick={() => {
                          setActiveAdvancedFilter(false);
                          setPopupOpen(!popupOpen);
                        }}
                      >
                        {Kwvalues[0]}-{Kwvalues[1]}
                      </span>
                      <button
                        onClick={() => {
                          setKwvalues([0, 0]);
                          setMinKwValue(0);
                          setMaxKwValue(0);
                        }}
                      >
                        x
                      </button>
                    </span>
                  </>
                )}
                {(TarifRCValues[0] !== 0 || TarifRCValues[1] !== 0) && (
                  <>
                    <b>Tarif RC:</b>{' '}
                    <span className="theme-container click-pointer">
                      <span
                        onClick={() => {
                          setActiveAdvancedFilter(false);
                          setPopupOpen(!popupOpen);
                        }}
                      >
                        {TarifRCValues[0]}-{TarifRCValues[1]}
                      </span>
                      <button
                        onClick={() => {
                          setTarifRCValues([0, 0]);
                          setMinTarifRCValue(0);
                          setMaxTarifRCValue(0);
                        }}
                      >
                        x
                      </button>
                    </span>
                  </>
                )}
                {(TarifHRValues[0] !== 0 || TarifHRValues[1] !== 0) && (
                  <>
                    <b>TF:</b>{' '}
                    <span className="theme-container click-pointer">
                      <span
                        onClick={() => {
                          setActiveAdvancedFilter(false);
                          setPopupOpen(!popupOpen);
                        }}
                      >
                        {TarifHRValues[0]}-{TarifHRValues[1]}
                      </span>
                      <button
                        onClick={() => {
                          setTarifHRValues([0, 0]);
                          setMinTarifHRValue(0);
                          setMaxTarifHRValue(0);
                        }}
                      >
                        x
                      </button>
                    </span>
                  </>
                )}
                {netlinkingContactFilter !== '' && (
                  <>
                    <b>Contact:</b>{' '}
                    <span className="theme-container click-pointer">
                      <span
                        onClick={() => {
                          setActiveAdvancedFilter(false);
                          setPopupOpen(!popupOpen);
                        }}
                      >
                        {netlinkingContactFilter}
                      </span>
                      <button
                        onClick={() => {
                          removeNetlinkingContactFilter();
                        }}
                      >
                        x
                      </button>
                    </span>
                  </>
                )}
              </div>
            )}

            <NetlinkingTable
              shownNetlinkingFilteredList={netlinkingFilteredList}
              nameSearch={nameSearch}
              onNetlinkingContactFilterChange={
                handleValueNetlinkingContactFilter
              }
              contactFilter={netlinkingContactFilter}
              sendNetlinkingExport={handleDataExcelToExport}
              ordersPerPage={ordersPerPage}
            />
          </div>
        )}
      </div>
    </div>
  );
}
