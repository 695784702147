import { createSlice } from '@reduxjs/toolkit';
import { getBills } from './actions';

const initialState = {
  bills: [],
  status: 'idle',
  error: null,
};

const billsDisplay = createSlice({
  name: 'bill_display',
  initialState,
  reducers: {
    fetchBills: (state) => {
      state.status = 'loading';
    },
    fetchBillsSuccess: (state, action) => {
      state.status = 'succeeded';
      state.bills = action.payload;
    },
    fetchBillsFailure: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },

    handleSorting: (state, { payload }) => {
      console.log('playload', payload);
      if (payload !== undefined) {
        let array = [...state.bills];
        if (payload.sortField) {
          const sorted = [...array].sort((a, b) => {
            if (a[payload.sortField] === null) return 1;
            if (b[payload.sortField] === null) return -1;
            return (
              a[payload.sortField]
                .toString()
                .localeCompare(b[payload.sortField].toString(), 'en', {
                  numeric: true,
                }) * (payload.sortOrder === 'asc' ? 1 : -1)
            );
          });
          state.bills = [...sorted];
          // state.generatedBills = [...sorted];
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBills.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getBills.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.bills = action.payload;
      })
      .addCase(getBills.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default billsDisplay.reducer;
export const {
  handleSorting,
  fetchBills,
  fetchBillsSuccess,
  fetchBillsFailure,
} = billsDisplay.actions;
export const BillDisplaySelector = (state) => state.billsDisplay;
