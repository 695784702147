import { CustomAutocomplete } from 'components/shared/DropDown/CustomAutocomplete';
import { useTranslation } from 'react-i18next';

export const AssignConsultant = (props) => {
  const { t } = useTranslation();

  return (
    <div className="row row-mb">
      <div className="col-12 col-md-4">
        <div className="label-title">{t(props.entityName)}</div>
        <CustomAutocomplete
          disabled={!props.consultantsList}
          list={props.consultantsList}
          value={props.consultant}
          setValue={(consultant) => {
            props.updateConsultantList('email', consultant?.email);
            props.updateConsultantList('firstName', consultant?.firstName);
            props.updateConsultantList('lastName', consultant?.lastName);
          }}
          getOptionSelected={(option) => option.id === props.consultant.id}
          getOptionDisabled={(option) => false}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option; // Handle string values directly
            }

            return option?.email == undefined ? '' : option?.email;
          }}
        />
      </div>
      {(props.isDeleteable && props.id) || props.new === false ? (
        <div
          className="col-12 col-md-1"
          onClick={() => props.deleteConsultant(props.id)}
        >
          <div className="bt-minus-circle" style={{ marginTop: 25 }} />
        </div>
      ) : (
        <div className="col-12 col-md-1" onClick={props.addConsultant}>
          <div className="bt-plus-circle" style={{ marginTop: 25 }} />
        </div>
      )}
    </div>
  );
};
