import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { DefaultLanguage } from 'utils/Constants';
import { englishTranslation } from 'utils/languages/englishTranslation';
import { frenchTranslation } from 'utils/languages/frenchTranslation';
import { germanTranslation } from 'utils/languages/germanTranslation';
import { italianTranslation } from 'utils/languages/italianTranslation';
import { spanishTranslation } from 'utils/languages/spanishTranslation';

import { polishTranslation } from 'utils/languages/polishTranslation';
import { chekRepublicTranslation } from 'utils/languages/chekRepublicTranslation';
import { denmarkTranslation } from 'utils/languages/denmarkTranslation';
import { finnishTranslation } from 'utils/languages/finnishTranslation';
import { hollandTranslation } from 'utils/languages/hollandTranslation';
import { norwigianTranslation } from 'utils/languages/norwigianTranslation';
import { portugeseTranslation } from 'utils/languages/portugeseTranslation';
import { romanianTranslation } from 'utils/languages/romanianTranslation';
import { sweedishTranslation } from 'utils/languages/sweedishTranslation';
import { greekTranslation } from 'utils/languages/greekTranslation';
import { slovaqueTranslation } from 'utils/languages/slovaqueTranslation';

const i18nInstance = i18n.use(initReactI18next);

i18nInstance.init({
  resources: {
    fr: {
      translations: frenchTranslation,
    },
    en: {
      translations: englishTranslation,
    },
    de: {
      translations: germanTranslation,
    },
    it: {
      translations: italianTranslation,
    },
    es: {
      translations: spanishTranslation,
    },
    cs: {
      translations: chekRepublicTranslation,
    },
    da: {
      translations: denmarkTranslation,
    },
    fi: {
      translations: finnishTranslation,
    },
    nl: {
      translations: hollandTranslation,
    },
    nb: {
      translations: norwigianTranslation,
    },
    pl: {
      translations: polishTranslation,
    },
    pt: {
      translations: portugeseTranslation,
    },
    ro: {
      translations: romanianTranslation,
    },
    sv: {
      translations: sweedishTranslation,
    },
    el: {
      translations: greekTranslation,
    },
    sk: {
      translations: slovaqueTranslation,
    },
  },
  fallbackLng: DefaultLanguage,

  debug: true, // have a common namespace used around the full app

  defaultNS: 'translations',

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

i18nInstance.languages = [
  'en',
  'fr',
  'de',
  'it',
  'cs',
  'da',
  'fi',
  'nl',
  'nb',
  'pt',
  'ro',
  'sv',
  'el',
  'sk',
];

export default i18nInstance;
