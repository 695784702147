import axiosPixLink from 'config/axiosPixLink';
import { useRef, useState } from 'react';
import Modal from 'react-modal'; // for the popup/modal
import { splitThemes } from 'utils/netlinking/StringTools';
import { getLanguageIdByRef } from 'utils/netlinking/languages';
import * as XLSX from 'xlsx'; // for Excel parsing
import { Loader } from '../Loader';
import ConfirmationModal from '../modal/ConfirmationModal';
import ErrorSuccesModal from '../modal/ErrorSuccesModal';
import { uploadNetlinkingExcelHeader } from 'helpers/ExcelUploaderHeaderHelper';
import { useTranslation } from 'react-i18next';
import { PAGE_NB_OPTIONS } from 'utils/Constants';
import { Paginator } from '../Paginator';

// Style for the modal content
const customModalStyle = {
  overlay: {
    zIndex: 9999, // Highest z-index value
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
  },
  content: {
    width: '80rem', // Width of the modal
    margin: '0 auto', // Center the modal horizontally
    padding: '20px', // Padding for content inside the modal
    height: '60rem',
  },
};

function ExcelUploader() {
  const { t } = useTranslation();
  const [excelData, setExcelData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const [creationLoading, setCreationLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [netlinkinhPerPage, setNetlinkinhPerPage] = useState(10);
  const formRef = useRef(null);
  const pageNbOptions = PAGE_NB_OPTIONS;
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    if (file) {
      // Parse the Excel file
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // Process the workbook and store the data
        const formattedData = processWorkbookData(workbook);
        setExcelData(formattedData);

        // Open the modal
        setIsModalOpen(true);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const closeModal = () => {
    // Close the modal and reset the Excel data
    if (formRef.current) {
      formRef.current.value = null;
    }
    setIsModalOpen(false);
    setExcelData(null);
  };

  // Placeholder function to process workbook data
  const processWorkbookData = (workbook) => {
    const sheetName = workbook.SheetNames[0]; // Assuming data is in the first sheet
    const sheet = workbook.Sheets[sheetName];

    // Convert the sheet data to a 2D array (array of arrays)
    const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });

    // Iterate through the rows and cells to detect and handle empty cells
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].length; j++) {
        // Check if the cell is empty (undefined)
        if (typeof data[i][j] === 'undefined') {
          data[i][j] = null; // Replace with null or any other placeholder value
        }
      }
    }

    console.log(data);
    const keys = data[0];

    // Create JSON objects from the remaining arrays
    const jsonData = data.slice(1).map((itemArray) => {
      const itemObject = {};
      keys.forEach((key, index) => {
        itemObject[key] = itemArray[index];
      });
      itemObject['languageId'] = getLanguageIdByRef(itemObject['language']);
      itemObject['themes'] = splitThemes(itemObject['themesList']);
      itemObject['topics'] = splitThemes(itemObject['topicsList']);

      return itemObject;
    });

    setJsonData(jsonData);

    return data;
  };

  const createMultipleNetlinking = async (jsonData) => {
    try {
      setCreationLoading(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Smooth scrolling animation
      });
      const response = await axiosPixLink
        .post(`/netlinking/create/multiple`, jsonData)
        .then((res) => {
          closeModal();
          setIsSuccess(true);
          setErrorSuccessMsg(
            'The Netlinking excel sheet have been successfully uploaded'
          );
          setErrorSuccess(true);
          setCreationLoading(false);
          return res.data;
        });
    } catch (error) {
      if (error.response) {
        closeModal();
        setIsSuccess(false);
        setErrorSuccessMsg('An Error Has Happened In the Server');
        setErrorSuccess(true);
        console.error('Error:', error.response.data);
      } else {
        closeModal();
        setIsSuccess(false);
        setErrorSuccessMsg('An Error Has Happened In the Server');
        setErrorSuccess(true);
        console.error('Error:', error.response.data);
      }
      throw error; // Rethrow the error to be handled by the caller
    }
  };
  const startIdx = (currentPage - 1) * netlinkinhPerPage;
  const endIdx = startIdx + netlinkinhPerPage;
  const displayedRows = excelData ? excelData.slice(startIdx, endIdx) : [];

  return (
    <div>
      <div className="flex-container bt-submit-y me-2">
        {' '}
        <img
          src="/Images/icon-excel.png"
          onClick={() => {}}
          className="App-logo-excel"
          alt="logo"
        />
        <input
          type="file"
          accept=".xlsx"
          onChange={handleFileUpload}
          className="bt-submit-y me-2"
          ref={formRef}
        />
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Excel Data"
        style={customModalStyle} // Apply custom styles to the modal
      >
        {creationLoading && <Loader />}
        {excelData && (
          <div className="table-responsive">
            <div className="box-table-netlinking">
              <table className="table-lists">
                <thead>
                  <tr>
                    {excelData[0].map((header, index) => (
                      <th key={index}>
                        {t(uploadNetlinkingExcelHeader[header])}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {excelData.slice(1).length > 10
                    ? displayedRows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {row.map((cell, cellIndex) => (
                            <td key={cellIndex}>
                              {' '}
                              {cell?.length > 50
                                ? cell.slice(0, 10) + '...'
                                : cell}
                            </td>
                          ))}
                        </tr>
                      ))
                    : excelData.slice(1).map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {row.map((cell, cellIndex) => (
                            <td key={cellIndex}>
                              {cell?.length > 50
                                ? cell.slice(10) + '...'
                                : cell}
                            </td>
                          ))}
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <div style={{ marginTop: '25px' }}>
          <div className="d-flex align-items-center">
            <span className="me-2">{t('Afficher les résultats')}</span>
            <div className="dropdown" style={{ width: '67px' }}>
              <button
                className="btn dropdown-toggle dropdown-custom w-100"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {netlinkinhPerPage}

                <span>
                  <i className="bi bi-chevron-down" />
                </span>
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                {pageNbOptions
                  .filter((nb) => nb !== netlinkinhPerPage)
                  .map((item, index) => (
                    <li key={index}>
                      <button
                        className="dropdown-item"
                        onClick={() => setNetlinkinhPerPage(item)}
                      >
                        {item}
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
        <Paginator
          listLength={excelData ? excelData.length : 0}
          currentPage={currentPage}
          pageNumbers={
            excelData
              ? [
                  ...Array(
                    Math.ceil(excelData?.slice(1)?.length / netlinkinhPerPage)
                  ).keys(),
                ].map((i) => i + 1)
              : [0]
          }
          nbElementsPerPage={netlinkinhPerPage}
          goToPage={(page) => setCurrentPage(page)}
        />
        <div style={{ marginTop: '25px' }}>
          <button
            onClick={() => {
              createMultipleNetlinking(jsonData);
            }}
            className="bt-submit-y me-2 close-modal-btn-upload"
          >
            {t('Sauvegarder')}
          </button>
          <button
            onClick={closeModal}
            className="bt-submit-y me-2 close-modal-btn-upload"
          >
            {t('Annuler')}
          </button>
        </div>
      </Modal>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => setErrorSuccess(false)}
        success={isSuccess}
      />

      <ConfirmationModal
        open={confirmation}
        message={confirmationMsg}
        handleClose={() => setConfirmation(false)}
        executeAction={() => {}}
      />
    </div>
  );
}
export default ExcelUploader;
