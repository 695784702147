import axios from "axios";
import TokenService from "./token.service";
import { fetchUser } from "store/UserSession/features";

import { store } from "store";

// const BASE_URL = process.env.REACT_APP_DEV_API_URL_PREFIX
const BASE_URL = "http://localhost:2255";

const axiosFacturation = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
});

axiosFacturation.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosFacturation.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalReq = error?.config;
    if (error.response && error.response.status === 403) {
      // auto logout if 403 Forbidden response returned from api
      TokenService.removeUser();
      store.dispatch(fetchUser(null));

      originalReq._retry = true;
    }
    return Promise.reject(error);
  }
);

export default axiosFacturation;
