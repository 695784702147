export const hollandTranslation = {
  'Les lignes de commandes ont été mises à jour avec succès !':
    'De commandoregels zijn succesvol bijgewerkt!',
  'Le texte a été enregistré avec succès':
    'De tekst werd met succes geregistreerd',
  'Nombre de mots': 'Aantal woorden',
  Lingala: 'Lingala',
  'Prénom/Nom du client': 'Voornaam/achternaam van de klant',
  Bashkir: 'Bashkir',
  "La date doit être ultérieure à aujourd'hui.":
    'De datum moet later zijn dan vandaag.',
  'Contenu rédigé': 'Geschreven inhoud',
  'Nom/prénom': 'Achternaam/voornaam',
  'Mot de passe incorrect': 'Onjuist wachtwoord',
  Institutions: 'Instellingen',
  'Veuillez donner un descriptif au document téléchargé':
    'Geef een beschrijving van het gedownloade document',
  'Sichuan Yi ; Nuosu': 'Sichuan Yi; Nuosu',
  Suivant: 'Volgende',
  Kannada: 'Kannada',
  'Séparer les mots clés par un "enter"': 'Scheid trefwoorden met een "enter".',
  Allemand: 'Duits',
  Sango: 'Sango',
  'Min.': 'Min.',
  'un email contenant un lien de réinitialisation du mot de passe a été envoyé à votre email':
    'een e-mail met een link om het wachtwoord te resetten is naar uw e-mail gestuurd',
  'Chef edito': 'Hoofdredacteur',
  Croate: 'Kroatisch',
  'Nb de textes': 'Aantal teksten',
  Violet: 'Violet',
  'Ce champs doit être sup ou égale nombre de texte':
    'Dit veld moet groter zijn dan of gelijk aan het aantal tekst',
  'a été activé avec succès': 'is met succes geactiveerd',
  Népalais: 'Nepalese',
  'Nombre de FAQ': "Aantal FAQ's",
  Urdu: 'Urdu',
  'Analyses restantes': 'Overige analyses',
  Indonésien: 'Indonesisch',
  Breton: 'Bretoens',
  Livrés: 'Geleverd',
  Associations: 'Verenigingen',
  'Le client': 'De klant',
  'Révision demandée': 'Herziening gevraagd',
  "Une erreur s'est produite, veuillez réessayer":
    'Er is een fout opgetreden, probeer het opnieuw',
  'Mise à jour non autorisée car des textes ont été livrés':
    'Ongeoorloofde bijwerking als teksten zijn afgeleverd',
  "Pour ajouter un texte (optimisation d'un contenu existant)":
    'Tekst toevoegen (bestaande inhoud optimaliseren)',
  Gaélique: 'Gaelic',
  'La ligne de commande pour': 'De opdrachtregel voor',
  'Business/Management': 'Business/Management',
  Serbe: 'Servisch',
  "Merci d'indiquer ci-dessous vos demandes de modifications en prenant bien en compte nos conditions de repasses":
    'Geef hieronder uw wensen voor wijzigingen aan, rekening houdend met onze strijkvoorwaarden',
  'rédacteurs trouvés': 'gevonden schrijvers',
  'Kirghiz ; Kirghiz': 'Kirghiz; Kirghiz',
  Différence: 'Verschil',
  'en cours': 'in uitvoering',
  'Sami du Nord': 'Noordelijke Sami',
  Comparer: 'Vergelijk',
  Lituanien: 'Litouws',
  'PixLink - Mon tableau de bord': 'PixLink - Mijn dashboard',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial et ne pas être identique au nom':
    'Moet minstens 8 tekens lang zijn met een hoofdletter, een cijfer, een speciaal teken en niet identiek aan de naam',
  Webmaster: 'Webmaster',
  'Veuillez saisir des champs valides': 'Voer geldige velden in',
  Basque: 'Baskisch',
  'Exporter .csv': 'Exporteer .csv',
  'Confirmer le nouveau mot de passe': 'Nieuw wachtwoord bevestigen',
  "Pas d'options": 'Geen opties',
  'Banque/Assurance': 'Bank/verzekering',
  'Vos demandes': 'Uw verzoeken',
  'Coût d\u2019achat': 'Aankoopkosten',
  Tamil: 'Tamil',
  'Le texte a été supprimé avec succès': 'De tekst is succesvol verwijderd',
  'Mes Commandes': 'Mijn bestellingen',
  Zoulou: 'Zoeloe',
  Afficher: 'Bekijk',
  Texte: 'Tekst',
  Islandais: 'IJslandse',
  Russe: 'Russische',
  'Tous les textes': 'Alle teksten',
  Czech: 'Tsjechisch',
  'Comparaison hors ligne': 'Offline vergelijking',
  Téléphonie: 'Telefonie',
  'Valider le texte': 'De tekst valideren',
  'Votre text ne de doit pas dépasser 500 mots':
    'Uw tekst mag niet langer zijn dan 500 woorden',
  'Coquilles (double espace, « s »\u2026)': 'Schelpen (dubbele spatie, "s"...)',
  'le consultant': 'de adviseur',
  Coréen: 'Koreaanse',
  'Nb de Silo': "Aantal silo's",
  Validés: 'Gevalideerd',
  'non attribuées': 'niet-toegewezen',
  Néerlandais: 'Nederlands',
  'Voulez-vous vraiment supprimer': 'Wil je echt verwijderen',
  Rundi: 'Rundi',
  Regional: 'Regionaal',
  'Compte client': 'Klantenrekening',
  Global: 'Wereldwijd',
  'Tous les rôles': 'Alle rollen',
  Musique: 'Muziek',
  Perso: 'Persoonlijk',
  Kinyarwanda: 'Kinyarwanda',
  'Il semblerait que tu n\u2019aies plus de crédit d\u2019analyse':
    'Het lijkt erop dat je geen analyse krediet meer hebt',
  Bambara: 'Bambara',
  Sortir: 'Ga weg.',
  optionel: 'optionele',
  'Temps restant': 'Resterende tijd',
  Ukrainien: 'Oekraïense',
  'Chichewa ; Chewa ; Nyanja': 'Chichewa; Chewa; Nyanja',
  'Votre demande de révision a bien été envoyée.':
    'Uw verzoek om herziening is verzonden.',
  'PixLink - Créer rédacteur': 'PixLink - Maak een schrijvers',
  "n'existe pas": 'bestaat niet',
  Hindi: 'Hindi',
  'ne peut pas être supprimé car il est lié à des sites web':
    'kan niet worden verwijderd omdat het gekoppeld is aan websites',
  'Campagnes en cours': 'Bestellingen in uitvoering',
  'Textes livrés': 'Geleverde teksten',
  Validation: 'Validatie',
  'Non attribuées': 'Niet toegewezen',
  'Voulez-vous vraiment désactiver': 'Wil je echt deactiveren',
  'Catalan ; Valencien': 'Catalaans; Valenciaans',
  'Nombre de textes restants': 'Aantal resterende teksten',
  'Ne doit pas contenir votre nom': 'Mag uw naam niet bevatten',
  Documentation: 'Documentatie',
  Kazakh: 'Kazachse',
  'Numéro invalide': 'Ongeldig nummer',
  'Veuillez saisir un texte ou importer un fichier.':
    'Voer een tekst in of importeer een bestand.',
  'Nb de FAQ': "Aantal FAQ's",
  Analyser: 'Analyse',
  Turc: 'Turkse',
  'Toute demande n\u2019ayant pas été précisée lors du brief':
    'Elk verzoek dat niet in het mandaat is vermeld',
  Wallon: 'Waals',
  Birmane: 'Birmese',
  Irlandais: 'Ierse',
  Oriya: 'Oriya',
  'Réinitialiservotre mot de passe': 'Wachtwoord opnieuw instellen',
  Cuisine: 'Keuken',
  'Haïtien ; créole haïtien': 'Haïtiaans; Haïtiaans Creools',
  'Vous ne pouvez pas supprimer le rédacteur':
    'U kunt de schrijvers niet verwijderen',
  Validé: 'Gevalideerd',
  "cet email n'existe pas": 'deze e-mail bestaat niet',
  Historique: 'Geschiedenis',
  Confirmer: 'Bevestig',
  'à cocher si le nombre de mots est différent par texte':
    'aan te vinken indien het aantal woorden per tekst verschilt',
  'Fiche du site web ': 'Website',
  Désactiver: 'Deactiveer',
  'Travaux/BTP': 'Werken/Bouw',
  'La fiche du website': 'De website',
  'Si le contenu ne respecte pas les règles du brief':
    'Indien de inhoud niet voldoet aan de regels van de opdracht',
  Norvégien: 'Noors',
  '0 rédacteurs': '0 schrijvers',
  indisponible: 'niet beschikbaar',
  Venda: 'Venda',
  Maori: 'Maori',
  'Textes revisés': 'Herziene teksten',
  'Status texte': 'Tekst status',
  'textes livrés en attente de validation':
    'geleverde teksten in afwachting van validatie',
  'PixLink - Mon Profile': 'PixLink - Mijn Profiel',
  'le client': 'de klant',
  Romanche: 'Romanche',
  'URL du site': 'Site URL',
  'nouvelles notifications': 'nieuwe meldingen',
  'Divehi ; Dhivehi ; Maldivien': 'Divehi; Dhivehi; Maldivisch',
  'Êtes vous sur de vouloir sauvegarder le texte':
    'Weet je zeker dat je de tekst wilt opslaan',
  'Veuillez ajouter un texte initiale afin de pouvoir sauvegarder le texte en brouillon':
    'Voeg een eerste tekst toe om de tekst als concept op te slaan',
  Xhosa: 'Xhosa',
  'Le contrat pour le client': 'Het contract voor de klant',
  rédacteurs: 'schrijvers',
  'Ce champ est obligatoire': 'Dit veld is verplicht',
  'Tarif/Mot': 'Tarief/Woord',
  Tadjik: 'Tadzjiekse',
  'Réf devis': 'Ref citaat',
  'Rechercher un rédacteur': 'Zoek een schrijver',
  Albanais: 'Albanese',
  Galicien: 'Galicië',
  Disponibilité: 'Beschikbaarheid',
  'Les deux mots de passe ne sonts pas identiques':
    'De twee wachtwoorden zijn niet identiek',
  Tibétain: 'Tibetaans',
  'PixLink - Gestion des utilisateurs': 'PixLink - Gebruikersbeheer',
  'Ossétien ; Ossétique': 'Ossetisch; Ossetisch',
  Tswana: 'Tswana',
  'Ajouter un client': 'Een klant toevoegen',
  'Séparez les mots-clés par un " Enter ".':
    'Scheid de trefwoorden met een "Enter".',
  'Zhuang ; Chuang': 'Zhuang; Chuang',
  Thématiques: "Thema's",
  'Nb mots/texte': 'Aantal woorden/tekst',
  Commerce: 'Handel',
  'Voir les commandes': 'Zie de bestellingen',
  'Nombre de mots par texte': 'Aantal woorden per tekst',
  'Lien drive...': 'Link drive...',
  'Hollandais ; Flamand': 'Nederlands; Vlaams',
  'Mon profil': 'Mijn profiel',
  "Ce champs doit être sup à 0 car facturation n'est pas au réel":
    'Dit veld moet groter zijn dan 0 omdat facturering niet echt is',
  'Le texte a été validé avec succès': 'De tekst is succesvol gevalideerd',
  Jeux: 'Spelletjes',
  'Voulez-vous vraiment supprimer la ligne de commande pour':
    'Wil je echt de opdrachtregel voor',
  'Ndebele, Nord ; Ndebele Nord': 'Ndebele, Noord; Ndebele Noord',
  'Slave ecclésiastique ; vieux slavon ; slavon ecclésiastique ; vieux bulgare ; vieux slavon ecclésiastique':
    'Kerkelijk Slavisch; Oud Slavisch; Kerkelijk Slavisch; Oud Bulgaars; Oud Kerkelijk Slavisch',
  Swahili: 'Swahili',
  Voyage: 'Reizen',
  'Nombre de mot à ajouter': 'Aantal toe te voegen woorden',
  'Liste des rédacteurs': 'Lijst van schrijvers',
  'le rédacteur': 'de redacteur',
  Kanuri: 'Kanuri',
  rédacteur: 'redacteur',
  Tatar: 'Tataars',
  'Roumain ; Moldave ; Moldovan': 'Roemeens; Moldavisch',
  'Le texte initial de': 'De oorspronkelijke tekst van de',
  'Mail clients': 'Klantenpost',
  'Le contrat de': 'Het contract van',
  'au moins une lettre minuscule et une lettre majuscule,un caractère spécial et un chiffre':
    'ten minste één kleine letter en één hoofdletter, één speciaal teken en één cijfer',
  Transport: 'Transport',
  'Nombre total de mots (existants et ajoutés) :':
    'Totaal aantal woorden (bestaande en toegevoegde) :',
  'Rechercher par mail, prénom, nom, site...':
    'Zoeken op e-mail, voornaam, achternaam, site...',
  'Nombre de mot à ajouter (Min/Max) : ':
    'Aantal toe te voegen woorden (Min/Max) :',
  Compte: 'Account',
  'Tourisme/Loisirs': 'Toerisme/Vrijetijdsbesteding',
  'Nouveau texte': 'Nieuwe tekst',
  Max: 'Max',
  'Kalaallisut ; Groenlandais': 'Kalaallisut; Groenlands',
  'Oups...!il y a une/plusieurs erreur(s) dans la fenêtre du gap sémantique. Veuillez vérifier son contenu pour la/les corriger et soumettre à nouveau votre texte.':
    'Oeps...! Er zijn een of meer fouten in het venster met de semantische kloof. Controleer de inhoud om deze te corrigeren en stuur uw tekst opnieuw in.',
  'ne peut pas être supprimé pour le moment':
    'kan op dit moment niet worden verwijderd',
  'Le texte': 'De tekst',
  URL: 'URL',
  "Un utilisateur existe déjà avec l'email":
    'Er bestaat al een gebruiker met het e-mailadres',
  Cree: 'Cree',
  Prénom: 'Voornaam',
  'commandes prête': 'orders klaar',
  Réinitialiser: 'Reset',
  'Veuillez fournir une date valide': 'Geef een geldige datum',
  'Kuanyama ; Kwanyama': 'Kuanyama; Kwanyama',
  'Luxembourgeois ; Letzeburgesch': 'Luxemburgs; Letzeburgesch',
  Suédois: 'Zweedse',
  'Confirmer le mot de passe': 'Wachtwoord bevestigen',
  'Ce champs est obligatoire et ne peut avoir que des nombres':
    'Dit veld is verplicht en kan alleen getallen bevatten',
  Sciences: 'Wetenschap',
  Tchétchène: 'Tsjetsjeense',
  "est le seul client pour un site, il faut ajouter d'autres pour pouvoir le supprimer":
    'de enige client is voor een site, moeten er anderen worden toegevoegd om hem te verwijderen',
  'Le mot de passe de confirmation ne correspond pas':
    'Bevestigingswachtwoord komt niet overeen',
  Swati: 'Swati',
  Danois: 'Deens',
  Sauvegarder: 'Sla',
  'Veuillez changer les informations suivantes titre et/ou mot clé':
    'Wijzig de volgende informatie: titel en/of trefwoord',
  'importer un document': 'een document importeren',
  Maltais: 'Maltezer',
  'Nb de mots': 'Aantal woorden',
  'Les données fournies sont invalides': 'De verstrekte gegevens zijn ongeldig',
  'Livraison estimée': 'Geschatte levering',
  'Grec moderne (1453-)': 'Modern Grieks (1453-)',
  Avestan: 'Avestaans',
  Inuktitut: 'Inuktitut',
  'Nombre total de mots ajoutés': 'Totaal aantal toegevoegde woorden',
  'le contenu présente des inexactitudes': 'de inhoud bevat onnauwkeurigheden',
  Finnois: 'Finse',
  'Ajouter un admin': 'Een admin toevoegen',
  'Min/Max': 'Min/Max',
  'Modifier le consultant': 'De adviseur wijzigen',
  'Liste des clients': 'Lijst van klanten',
  'Nom client': 'Naam van de klant',
  Vert: 'Groen',
  'Mots clé manquants': 'Ontbrekende sleutelwoorden',
  Lao: 'Lao',
  Somali: 'Somalische',
  'Nb de silo par texte': "Aantal silo's per tekst",
  Italien: 'Italiaans',
  'La commande a été mis à jour avec succès !':
    'De bestelling is succesvol bijgewerkt!',
  Bosniaque: 'Bosnische',
  'Thématiques d\u2019expertise': "Thema's van deskundigheid",
  'Ce mot de passe est le mot de passe que vous avez oublié':
    'Dit is het wachtwoord dat u bent vergeten',
  'a été désactivé avec succès': 'is met succes gedeactiveerd',
  Kongo: 'Kongo',
  'Postuler à la commande': 'Vraag de bestelling aan',
  'mot-clé': 'trefwoord',
  Abkhazien: 'Abchazisch',
  Mongolien: 'Mongools',
  'PixLink - Fiche site web': 'PixLink - Website',
  'Mail envoyé': 'Verzonden post',
  Géorgien: 'Georgisch',
  'Si des phrases ne sont pas dans un français correct':
    'Als de zinnen niet in correct Frans zijn',
  'En cours': 'In uitvoering',
  voir: 'zie',
  "Date d'ajout": 'Datum toegevoegd',
  'Tarif au mot': 'Tarief per woord',
  Thai: 'Thaise',
  'Détails du texte': 'Details van de tekst',
  'Commandes en retard': 'Late bestellingen',
  disponible: 'beschikbaar',
  Droit: 'Rechts',
  Azerbaïdjanais: 'Azerbeidzjaans',
  'Ajouter un consultant': 'Een adviseur toevoegen',
  'Dans le cadre d\u2019une repasse sur des contenus, seuls les éléments suivants seront pris en charge':
    'Bij het opnieuw afspelen van inhoud worden alleen de volgende items ondersteund',
  'doit comprendre au moins 8 charactères': 'moet ten minste 8 tekens bevatten',
  'Titre de la commande': 'Titel van de bestelling',
  'Langues bihari': 'Bihari talen',
  'Livraison estimé au': 'Geschatte levering door',
  "Le nombre de texte de la commande ne peut pas être inférieur aux nombre de textes attribuées au(x) rédacteur(s). Veuillez d'abord modifier les informations de la ligne de commande":
    'Het aantal teksten in de bestelling kan niet minder zijn dan het aantal teksten dat aan de schrijver(s) is toegewezen. Verander eerst de informatie in de opdrachtregel',
  'Date de livraison': 'Leveringsdatum',
  'Voulez-vous vraiment supprimer le contrat de': 'Wil je echt de',
  'Facturation au réel': 'Feitelijke facturering',
  'Gap sémantique': 'Semantische kloof',
  Estonien: 'Estse',
  Wolof: 'Wolof',
  'Coût d\u2019achat max/mot': 'Maximum aankoopkosten/woord',
  'Détail de la commande': 'Bestelgegevens',
  'Export en cours de téléchargement\u2026': 'Export wordt geupload...',
  'Ce champ est obligatoire et doit être inférieur ou égal au Nombre total des textesde la commande':
    'Dit veld is verplicht en moet kleiner of gelijk zijn aan het Totaal aantal teksten in de bestelling.',
  Arabe: 'Arabisch',
  Bislama: 'Bislama',
  'Mail client': 'Klantenpost',
  '(Titre, mots clé, url, balise, méta-description)':
    '(Titel, sleutelwoorden, url, tag, meta-beschrijving)',
  'PixLink - Nouvelle commande': 'PixLink - Nieuwe bestelling',
  'Révision en attente': 'Beoordeling in behandeling',
  'Le consultant': 'De adviseur',
  Entreprise: 'Bedrijf',
  Aragonais: 'Aragonese',
  Kashmiri: 'Kashmiri',
  'a été activé': 'is geactiveerd',
  'Interlingua (Association internationale des langues auxiliaires)':
    'Interlingua (Internationale Vereniging van hulptalen)',
  Actualités: 'Nieuws',
  'Votre décision a été prise en compte avec succès':
    'Er is met succes rekening gehouden met uw beslissing',
  Afrikaans: 'Afrikaans',
  'contenu en attente de vérification client':
    'inhoud in afwachting van verificatie door de klant',
  'Fiche du rédacteur': "schrijvers's bestand",
  Outillage: 'Gereedschap',
  'PixLink - Détails de la commande': 'PixLink - Bestelgegevens',
  'Ajouter ou compléter ci-dessous votre contenu :':
    'Voeg hieronder uw inhoud toe of vul deze aan:',
  Ewe: 'Ooi',
  'contenu en attente de repasse rédacteur':
    'content pending replay schrijvers',
  'La commande a été supprimé avec succès':
    'De bestelling is succesvol verwijderd',
  'Expressions ou phrases françaises mais ne vous convenant pas':
    'Franse uitdrukkingen of zinnen die niet bij je passen',
  Corse: 'Corsica',
  Modifier: 'Bewerk',
  'Nb de GMB': "Aantal GMB's",
  Oromo: 'Oromo',
  Polish: 'Pools',
  'Insérez votre gap sémantique avec l\u2019occurence':
    'Voeg je semantische gat in met het voorkomen',
  'Nb de textes à optimiser': 'Aantal te optimaliseren teksten',
  Mode: 'Modus',
  Akan: 'Akan',
  'Révisions effectués': 'Gedane herzieningen',
  'Modifier l\u2019admin': 'De admin bewerken',
  'Télécharger le document': 'Het document downloaden',
  Hausa: 'Hausa',
  'Notes rédacteurs': 'Opmerkingen van de schrijvers',
  Quechua: 'Quechua',
  Féroïen: 'Faeröer',
  'Modifier le rédacteur': 'De schrijvers wijzigen',
  Mail: 'Mail',
  Rédacteurs: 'Schrijvers',
  'PixLink - Détails du texte': 'PixLink - Tekstdetails',
  Hongrois: 'Hongaars',
  'Bon pour livraison': 'Goed voor de levering',
  'Au réel': 'In de echte wereld',
  'Hiri Motu': 'Hiri Motu',
  Hébreu: 'Hebreeuws',
  'Nombre total de mots ajoutés :': 'Totaal aantal toegevoegde woorden :',
  Komi: 'Komi',
  'Doit être inférieur au nombre maximum':
    'Moet kleiner zijn dan het maximumaantal',
  'Nom du texte': 'Naam van de tekst',
  'Ajouter un rédacteur': 'Een redacteur toevoegen',
  Ou: 'Of',
  Utilisateurs: 'Gebruikers',
  Heures: 'Uren',
  'Santé/Bien-être': 'Gezondheid/Welzijn',
  'Interlingue ; Occidental': 'Interlinguaal; Westers',
  'Luba-Katanga': 'Luba-Katanga',
  Letton: 'Lets',
  Anglais: 'Engels',
  Envoyer: 'Stuur',
  'Tableau de bord': 'Dashboard',
  'Demande de révision': 'Verzoek om herziening',
  'Résultat sémantique': 'Semantisch resultaat',
  'Chercher un rédacteur': 'Een redacteur zoeken',
  'Ce champs est obligatoire': 'Dit veld is verplicht',
  Rédaction: 'schrijvers',
  'Modifier le client': 'De cliënt wijzigen',
  'Voulez-vous vraiment supprimer le rédacteur':
    'Wil je echt de schrijvers verwijderen',
  Titre: 'Titel',
  Langue: 'Taal',
  'Mots clé': 'Sleutelwoorden',
  'Les demandes suivantes ne seront pas prises en compte':
    'De volgende aanvragen worden niet in aanmerking genomen',
  'délai dépassé': 'gemiste deadline',
  Tchouvache: 'Tchouvache',
  Ganda: 'Ganda',
  Twi: 'Twi',
  'Contrat déjà existant, veuillez modifier les informations':
    'Bestaand contract, gelieve de informatie te wijzigen',
  Igbo: 'Igbo',
  Yiddish: 'Jiddisch',
  'Limburgan ; Limburger ; Limburgish': 'Limburgan; Limburger; Limburgs',
  'Commandes validées': 'Gevalideerde orders',
  'listeRedacteur-': 'listRedactor-',
  Étape: 'Stap',
  'Energie/Environnement': 'Energie/Milieu',
  Min: 'Min',
  Gujarati: 'Gujarati',
  'ne doit pas inclure votre nom/prénom': 'mag niet uw naam/voornaam bevatten',
  'PixLink - Fiche rédacteur': 'PixLink - Schrijversbestand',
  'révisions effectuées en attentes de validation':
    'herzieningen in afwachting van validatie',
  'Textes validés': 'Gevalideerde teksten',
  Art: 'Art',
  Dzongkha: 'Dzongkha',
  Polyvalent: 'Veelzijdig',
  Sundanese: 'Sundanees',
  'Nb total de textes': 'Totaal aantal teksten',
  'Nombre de mots gérés': 'Aantal beheerde woorden',
  "L'émail": 'Email',
  'Afficher les résultats': 'Toon resultaten',
  'Frison occidental': 'West-Fries',
  'Modification effectuée avec succès': 'Wijziging succesvol afgerond',
  'Balise titre': 'Titel tag',
  'PixLink - Mon profil': 'PixLink - Mijn profiel',
  'email invalide': 'ongeldige e-mail',
  'Veuillez ajouter une url afin de pouvoir sauvegarder le texte en brouillon':
    'Voeg een url toe zodat u de tekst als concept kunt opslaan.',
  Macédonien: 'Macedonisch',
  'Votre candidature a bien été envoyée': 'Uw aanvraag is verzonden',
  'E-mail': 'E-mail',
  Fulah: 'Fulah',
  'Ajouter ou compléter ci-dessous votre contenu':
    'Voeg hieronder uw inhoud toe of vul deze aan',
  Manx: 'Manx',
  'Ajout de mots-clés avec la fréquence restante':
    'Trefwoorden toevoegen met resterende frequentie',
  'Fiche du site web': 'Website',
  Contacter: 'Neem contact op met',
  Fidjien: 'Fiji',
  Déconnexion: 'Ontkoppeling',
  email: 'e-mail',
  'Nombre de textes': 'Aantal teksten',
  'Enregistrer et informer client': 'Registreren en informeren van klanten',
  'Date de validation': 'Valideringsdatum',
  'Tonga (Îles Tonga)': 'Tonga (Tonga-eilanden)',
  Javanais: 'Javaanse',
  Portugais: 'Portugees',
  Biélorusse: 'Wit-Russisch',
  'Pour ajouter un nouveau texte': 'Een nieuwe tekst toevoegen',
  'Enfant/Puéri': 'Kind/Puperium',
  'Mots clés': 'Sleutelwoorden',
  Emploi: 'Werkgelegenheid',
  'Voulez-vous vraiment supprimer la commande de': 'Wil je echt de',
  'Mot clé': 'Sleutelwoord',
  Consultants: 'Adviseurs',
  Volapük: 'Volapük',
  Services: 'Diensten',
  'mot-clé (fréquence)': 'trefwoord (frequentie)',
  'Ne plus afficher ce message': 'Dit bericht niet meer weergeven',
  Consultant: 'Consultant',
  "Une erreur s'est produite": 'Er is een fout opgetreden',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial':
    'Ten minste 8 tekens met een hoofdletter, een cijfer, een speciaal teken',
  'Pushto ; Pashto': 'Pushto; Pashto',
  Français: 'Frans',
  validées: 'gevalideerd',
  'Url du site': 'Url van de site',
  Précédent: 'Vorige',
  'car celui-ci a déjà rédigé des textes':
    'omdat deze laatste reeds teksten heeft opgesteld',
  'Kikuyu ; Gikuyu': 'Kikuyu; Gikuyu',
  Brouillons: 'Concepten',
  Ido: 'Ido',
  'Contenu disponible': 'Beschikbare inhoud',
  Enseignement: 'Onderwijs',
  'a été livré avec succès': 'is met succes afgeleverd',
  Chinois: 'Chinees',
  Vietnamien: 'Vietnamees',
  'Nouveau client': 'Nieuwe klant',
  'Votre profil a été mis à jour avec succès.':
    'Je profiel is succesvol bijgewerkt.',
  Consignes: 'Instructies',
  'Mot de passe': 'Wachtwoord',
  Annuler: 'Annuleren',
  Non: 'Geen',
  Nom: 'Naam',
  Guarani: 'Guarani',
  "il y'a des commandes liés à au site web":
    'er zijn orders met betrekking tot de website',
  Score: 'Score',
  'Mot de passe mis à jour avec succès': 'Wachtwoord succesvol bijgewerkt',
  Latin: 'Latijn',
  Technologie: 'Technologie',
  Internet: 'Internet',
  Nauru: 'Nauru',
  'a été créé avec succès': 'is met succes aangemaakt',
  Roumain: 'Roemeense',
  Assamais: 'Assamees',
  'Khmer central': 'Centraal Khmer',
  'Navajo ; Navaho': 'Navajo; Navaho',
  'Panjabi ; Punjabi': 'Punjabi; Punjabi',
  'Analyse/Limite': 'Analyse/Limiet',
  Grec: 'Grieks',
  Ojibwa: 'Ojibwa',
  'Mes Projets': 'Mijn projecten',
  'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n\u2026':
    'trefwoord1 (2)\ntrefwoord2 (1)\ntrefwoord3 (1)\ntrefwoord4 (2)\n...',
  'ajouter un texte': 'een tekst toevoegen',
  'Détail de la prestation': 'Details van de dienst',
  "Vous avez la possibilité d'exporter le rapport d'une, ou de plusieurs commandes. Pour exporter toutes les commandes d'un client, il vous suffit juste de cliquer sur l'icône de téléchargement en jaune":
    'U hebt de mogelijkheid om het rapport van één of meer bestellingen te exporteren. Om alle bestellingen van een klant te exporteren, hoeft u alleen maar op het gele download-icoon te klikken.',
  'Gestion des utilisateurs': 'Gebruikersbeheer',
  'livré et inférieur au nombre de texte non affecté':
    'geleverd en minder dan het aantal niet-toegewezen teksten',
  'Date deadline validation client': 'Klant validatie deadline',
  Aymara: 'Aymara',
  Validées: 'Gevalideerd',
  Notes: 'Opmerkingen',
  'Nombre de GMB': "Aantal GMB's",
  'résultats sur': 'resultaten op',
  'Prénom / Nom du client': 'Voornaam / Naam van de klant',
  'Tout télécharger': 'Alles downloaden',
  'Champs obligatoires': 'Verplichte velden',
  Éditer: 'Bewerk',
  'Coller le texte initial dans le champs ci-dessous':
    'Plak de oorspronkelijke tekst in het onderstaande veld',
  'ID rédacteur': 'schrijvers ID',
  Arménien: 'Armeens',
  'Grande distribution': 'Grootschalige distributie',
  Welsh: 'Welsh',
  Herero: 'Herero',
  'Enrichissement sémantique': 'Semantische verrijking',
  'Détail de la prestation': 'Details van de levering van inhoud',
  Noir: 'Zwart',
  'Ajouter un texte': 'Een tekst toevoegen',
  'Immo/Defisc': 'Eigendom/Belasting',
  'Brief détaillé': 'Gedetailleerd overzicht',
  'Séparez les mots-clés par un Enter .':
    'Scheid de trefwoorden met een Enter .',
  Prix: 'Prijzen',
  'mot de passe oublié': 'Wachtwoord vergeten',
  'Ndebele, South ; South Ndebele': 'Ndebele, Zuid; Zuid-Ndebele',
  'Demander une révision': 'Een beoordeling aanvragen',
  'Sotho, Southern': 'Sotho, Zuidelijk',
  'Le titre de la commande existe déjà':
    'De titel van de bestelling bestaat al',
  'Félicitations !': 'Gefeliciteerd!',
  Kurde: 'Koerdisch',
  Adresse: 'Adres',
  Espagnol: 'Spaans',
  Ndonga: 'Ndonga',
  'En retard': 'Laat',
  'a été ajouté avec succès': 'is met succes toegevoegd',
  Commandes: 'bestellingen',
  'a été mis en brouillon': 'is opgesteld',
  'Rédacteur attribué': 'Toegewezen redacteur',
  Rechercher: 'Zoek op',
  'Nouvelle commande': 'Nieuwe bestelling',
  'Commandes non attribuées': 'Niet toegewezen orders',
  'Le texte a été mis en brouillon': 'De tekst is opgesteld',
  'Contact edito': 'Contact edito',
  Langues: 'Talen',
  Orange: 'Oranje',
  'Nynorsk norvégien': 'Noors Nynorsk',
  Shona: 'Shona',
  Turkmène: 'Turkmenen',
  'contenu repassé, en attente validation client':
    'gestreken inhoud, in afwachting van validatie door de klant',
  'Nombre de mots rédigés': 'Aantal geschreven woorden',
  Malay: 'Maleis',
  'Nombre de Silo': "Aantal silo's",
  Oui: 'Ja',
  Automobile: 'Automotive',
  Résultats: 'Resultaten',
  Afar: 'Afar',
  'Tâche - id tâche': 'Taak - taak-id',
  'Ce champ est obligatoire doit être supérieur à 0':
    'Dit veld is verplicht en moet groter zijn dan 0.',
  'Vous êtes sûr': 'Weet je zeker dat',
  'Réinitialiser votre mot de passe': 'Wachtwoord opnieuw instellen',
  Sindhi: 'Sindhi',
  Bengali: 'Bengaals',
  'Bokmål, norvégien ; Bokmål norvégien': 'Bokmål, Noors; Noors Bokmål',
  'Nombre de Jours': 'Aantal dagen',
  'Min/Max mots': 'Min/Max woorden',
  Slovène: 'Sloveense',
  Site: 'Website',
  'Sinhala ; Cingalais': 'Sinhala; Singalees',
  Bulgare: 'Bulgaarse',
  'Liste des commandes': 'Lijst van bestellingen',
  "L'utilisateur": 'De gebruiker',
  Uzbek: 'Oezbeekse',
  'Titre du texte': 'Titel van de tekst',
  Postuler: 'Solliciteer bij',
  'Nb de textes par Silo': 'Aantal teksten per silo',
  'Nombre total de mots (existants et ajoutés)':
    'Totaal aantal woorden (bestaande en toegevoegde)',
  'Max.': 'Max.',
  Beauté: 'Schoonheid',
  Humanitaire: 'Humanitair',
  Telugu: 'Telugu',
  'Recherche par rédacteur ou texte': 'Zoeken op auteur of tekst',
  'Balise meta-description': 'Meta description tag',
  Malgache: 'Malagasy',
  Tagalog: 'Tagalog',
  'Ce champs doit être sup à 0 et inférieur à 1':
    'Dit veld moet groter zijn dan 0 en kleiner dan 1.',
  'Le rédacteur': 'De redacteur',
  'a été mis à jour avec succès': 'is met succes bijgewerkt',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un symbole et ne pas être identique au nom.':
    'Moet minstens 8 tekens lang zijn met een hoofdletter, een cijfer, een symbool en niet identiek aan de naam.',
  'textes en plus': 'meer teksten',
  'Format souhaité': 'Gewenst formaat',
  Chamorro: 'Chamorro',
  Malayalam: 'Malayalam',
  'Détail ligne de commande': 'Bestelregel detail',
  'Veuillez préciser la date': 'Geef de datum aan',
  Pali: 'Pali',
  Clients: 'Klanten',
  'Total facturé': 'Totaal gefactureerd',
  'contenu validé par le client': 'door de klant gevalideerde inhoud',
  'Occitan (post 1500)': 'Occitaans (na 1500)',
  Japonais: 'Japanse',
  Avaric: 'Avaric',
  'Voulez-vous vraiment activer': 'Wil je echt',
  Sanskrit: 'Sanskriet',
  'Toutes les notifications': 'Alle kennisgevingen',
  Marathi: 'Marathi',
  Persan: 'Perzisch',
  'La commande pour le client': 'De bestelling voor de klant',
  'Intitulé de la commande': 'Titel van de bestelling',
  Samoan: 'Samoan',
  Autres: 'Andere',
  Sport: 'Sport',
  "il y'a des commandes liés au site web ":
    'er zijn orders met betrekking tot de website',
  'Ajout de mots-clé avec la fréquence restante':
    'Trefwoorden toevoegen met resterende frequentie',
  'a été supprimé avec succès': 'is met succes verwijderd',
  Postulé: 'Geplaatst op',
  Literie: 'Beddengoed',
  'Édité par le client': 'Bewerkt door de klant',
  Cornouailles: 'Cornwall',
  Tigrinya: 'Tigrinya',
  Textes: 'Teksten',
  'Soumettre un nouveau texte': 'Een nieuwe tekst indienen',
  Téléphone: 'Telefoon',
  Deadline: 'Deadline',
  "Une erreur s'est produite. Veuillez vérifier votre saisie ou essayer plus tard.":
    'Er is een fout opgetreden. Controleer uw invoer of probeer het later opnieuw.',
  Marshallais: 'Marshall',
  "La demande d'ajout de crédit est envoyée":
    'Het verzoek om krediet toe te voegen wordt verzonden',
  'Le rédacteur ': 'De redacteur',
  'Demander des crédits': 'Krediet aanvragen',
  "Cette commande n'existe pas": 'Dit commando bestaat niet',
  Inupiaq: 'Inupiaq',
  Tahitien: 'Tahitiaanse',
  Admins: 'Admins',
  'Mettez des mots-clés avec fréquence': 'Trefwoorden met frequentie instellen',
  Notifications: 'Meldingen',
  'en retard': 'laat',
  "l'admin": 'de beheerder',
  'Mail rédacteurs': 'Mail schrijvers',
  Amharique: 'Amhaars',
  Societe: 'Bedrijf',
  Tsonga: 'Tsonga',
  'Nombre de textes ajoutés': 'Aantal toegevoegde teksten',
  Société: 'Bedrijf',
  Yoruba: 'Yoruba',
  'Le texte a été livré avec succès': 'De tekst werd met succes afgeleverd',
  'Mettez votre texte': 'Zet uw tekst',
  "Oups....! Une erreur s'est produite": 'Oeps....! Er is een fout opgetreden',
  'Prénom/Nom': 'Voornaam/achternaam',
  'Max doit être sup à Min': 'Max moet groter zijn dan Min',
  'Importer un autre document': 'Een ander document importeren',
  'Votre demande a expirée .. veuillez refaire une autre':
    'Uw aanvraag is verlopen ... gelieve een nieuwe te maken',
  'Veuillez corriger le format votre gap sémantique afin de pouvoir sauvegarder le texte en brouillon':
    'Gelieve het formaat van uw semantische kloof te corrigeren om de tekst als ontwerp op te slaan',
  Exemple: 'Voorbeeld',
  Slovaque: 'Slowaaks',
  Finance: 'Financiën',
  Réactiver: 'Reactiveren',
  'Nb textes': 'Aantal teksten',
  'Intitulé commande': 'Titel van de bestelling',
  Supprimer: 'Verwijder',
  Brouillon: 'Ontwerp',
  'Mots clés restants': 'Resterende trefwoorden',
  Date: 'Datum',
  Rôle: 'Rol',
  'Ce champs doit être sup à 0': 'Dit veld moet groter zijn dan 0',
  'Maison/Déco': 'Huis/decoratie',
  'Vous avez': 'Je hebt',
  'Cette ligne de commande ne peut pas être supprimée car une partie de ses textes a été livré':
    'Deze opdrachtregel kan niet worden verwijderd omdat een deel van de tekst ervan is afgeleverd',
  'vérifiez votre email et cliquez sur le lien pour continuer !':
    'controleer uw e-mail en klik op de link om verder te gaan!',
  Sarde: 'Sardinië',
  'Note rédacteur': 'Noot van de schrijvers',
  'Uighur ; Uyghur': 'Oeigoer; Oeigoer',
  Name: 'naam',
  'Commandes intelligentes': 'Smart opdrachten',
  'Commandes avec révision': 'Smart + review opdrachten',
  'Type de contenu': 'Type inhoud',
  'Fiche produit': 'Productblad',
  GMB: 'GMB',
  'STORE LOCATOR': 'STORE LOCATOR',
  'Rédaction premium': 'Premium redactie',
  'Rédaction avec révision': 'Smart + review redactie',
  'Rédaction intelligente': 'Smart redactie',
  PREMIUM: 'PREMIUM',
  Type: 'Type',
  Editer: 'Bewerken',
  'Tous les types': 'Alle typen',
  Smart: 'Smart',
  'Smart + review': 'Smart + review',
  'Moteur de recherche Netlinking': 'Μηχανή αναζήτησης Netlinking',
  'Paramètre de recherche': 'Παράμετρος αναζήτησης',
  Standard: 'Κανονικό',
  Premium: 'Πρεμιέ',
  Thématique: 'Θεματική',
  'Filtre avancé': 'Προηγμένο φίλτρο',
  'Nombre de': 'Αριθμός',
  'Netlinking Trafic': 'Netlinking Κίνηση',
  'Mots clé positionés': 'Κλειδιά θέσης',
  'Netlinking Tarif': 'Netlinking Τιμή',
  Chercher: 'Αναζήτηση',
  Domaine: 'Τομέας',
  'Topic Majestic': 'Majestic Θέμα',
  'Kw positionés': 'Κλειδιά θέσης',
  Trafic: 'Κίνηση',
  Tarif: 'Τιμή',
  'Contacté le': 'Επικοινωνία στις',
  Contact: 'Επικοινωνία',
  'Rechercher par nom de domaine': 'Αναζήτηση με όνομα τομέα',
  'Fiche de Spot Netlinking': 'Φύλλο Spot Netlinking',
  'Domaine Netlinking': 'Τομέας Netlinking',
  'Netlinking Thématique': 'Θεματική Netlinking',
  'Netlinking Topic Majestic': 'Θέμα Netlinking Majestic',
  Réinitialiser: 'Resetten',
  italian: 'Italiaans',
  english: 'Engels',
  german: 'Duits',
  spanish: 'Spaans',
  portuguese: 'Portugees',
  french: 'Frans',
};
