import { useState, useEffect } from 'react';
import CheckBox from 'components/shared/CheckBox';
import { useTranslation } from 'react-i18next';
export default function LanguageCheckBoxGroup({
  onCheckboxChange,
  initialState,
  initialStateString,
}) {
  const { t } = useTranslation();
  //set order type
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [isChecked4, setIsChecked4] = useState(false);
  const [isChecked5, setIsChecked5] = useState(false);
  const [isChecked6, setIsChecked6] = useState(false);

  function handleCheckbox1Change() {
    setIsChecked1(!isChecked1);
    setIsChecked2(false);
    setIsChecked3(false);
    setIsChecked4(false);
    setIsChecked5(false);
    setIsChecked6(false);
    onCheckboxChange({
      checkbox1: !isChecked1,
      checkbox2: false,
      checkbox3: false,
      checkbox4: false,
      checkbox5: false,
      checkbox6: false,
    });
  }

  function handleCheckbox2Change() {
    setIsChecked1(false);
    setIsChecked2(!isChecked2);
    setIsChecked3(false);
    setIsChecked4(false);
    setIsChecked5(false);
    setIsChecked6(false);
    onCheckboxChange({
      checkbox1: false,
      checkbox2: !isChecked2,
      checkbox3: false,
      checkbox4: false,
      checkbox5: false,
      checkbox6: false,
    });
  }

  function handleCheckbox3Change() {
    setIsChecked1(false);
    setIsChecked2(false);
    setIsChecked3(!isChecked3);
    setIsChecked5(false);
    setIsChecked6(false);
    onCheckboxChange({
      checkbox1: false,
      checkbox2: false,
      checkbox3: !isChecked3,
      checkbox4: false,
      checkbox5: false,
      checkbox6: false,
    });
  }

  function handleCheckbox4Change() {
    setIsChecked1(false);
    setIsChecked2(false);
    setIsChecked3(false);
    setIsChecked4(!isChecked4);
    setIsChecked5(false);
    setIsChecked6(false);
    onCheckboxChange({
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      checkbox4: !isChecked4,
      checkbox5: false,
      checkbox6: false,
    });
  }

  function handleCheckbox5Change() {
    setIsChecked1(false);
    setIsChecked2(false);
    setIsChecked3(false);
    setIsChecked4(false);
    setIsChecked5(!isChecked5);
    setIsChecked6(false);
    onCheckboxChange({
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      checkbox4: false,
      checkbox5: !isChecked5,
      checkbox6: false,
    });
  }

  function handleCheckbox6Change() {
    setIsChecked1(false);
    setIsChecked2(false);
    setIsChecked3(false);
    setIsChecked4(false);
    setIsChecked5(false);
    setIsChecked6(!isChecked6);
    onCheckboxChange({
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      checkbox4: false,
      checkbox5: false,
      checkbox6: !isChecked6,
    });
  }

  useEffect(() => {
    setIsChecked1(initialStateString === 6);
    setIsChecked2(initialStateString === 1);
    setIsChecked3(initialStateString === 3);
    setIsChecked4(initialStateString === 2);
    setIsChecked5(initialStateString === 4);
    setIsChecked6(initialStateString === 5);
  }, []);

  return (
    <div className="col-12 col-md-9 d-flex justify-content-between justify-content-md-between align-items-center flex-column flex-md-row">
      <div className="col-12 col-md-2 d-flex align-items-center">
        <div className="label-title me-3 label-choice"> {t('Français')}</div>
        <CheckBox
          name="Checkbox 1"
          tick={initialStateString === 6}
          onCheck={handleCheckbox1Change}
        />
      </div>
      <div className="col-12 col-md-2 d-flex align-items-center">
        <div className="label-title me-3 label-choice"> {t('Anglais')}</div>

        <CheckBox
          name="Checkbox 2"
          tick={initialStateString === 1}
          onCheck={handleCheckbox2Change}
        />
      </div>
      <div className="col-12 col-md-2 d-flex align-items-center">
        <div className=" label-title me-3 label-choice"> {t('Espagnol')}</div>

        <CheckBox
          name="Checkbox 3"
          tick={initialStateString === 3}
          onCheck={handleCheckbox3Change}
        />
      </div>
      <div className="col-12 col-md-2 d-flex align-items-center">
        <div className=" label-title me-3 label-choice">{t('Allemand')}</div>

        <CheckBox
          name="Checkbox 4"
          tick={initialStateString === 2}
          onCheck={handleCheckbox4Change}
        />
      </div>
      <div className="col-12 col-md-2 d-flex align-items-center">
        <div className=" label-title me-3 label-choice">{t('Italien')}</div>

        <CheckBox
          name="Checkbox 5"
          tick={initialStateString === 4}
          onCheck={handleCheckbox5Change}
        />
      </div>
      <div className="col-12 col-md-2 d-flex align-items-center">
        <div className=" label-title me-2 label-choice">{t('Portugais')}</div>

        <CheckBox
          name="Checkbox 6"
          tick={initialStateString === 5}
          onCheck={handleCheckbox6Change}
        />
      </div>
    </div>
  );
}
