import { Autocomplete, InputBase, createStyles } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { customStyle } from '../../Netlinking/AutoComplete/CustomAutocompleteStyle';

const useStyles = makeStyles(() => createStyles(customStyle));

export const CustomChipsAutocomplete = (props) => {
  const classes = useStyles();

  const defaultProps = {
    options: props.list,
    getOptionLabel: (option) => option.theme,
  };

  return (
    <div>
      <Autocomplete
        {...defaultProps}
        size="small"
        disableCloseOnSelect
        limitTags={5}
        disabled={props.disabled}
        multiple
        value={props.value}
        onChange={(event, newValues) => {
          props.callbackFromMultiSelect(newValues);
        }}
        getOptionSelected={(option, value) => option.theme === value.theme}
        className={`${
          props.disabled ? classes.textFieldDisabled : classes.textField
        }`}
        classes={{
          option: classes.option,
          clearIndicatorDirty: classes.clearIndicator,
          popupIndicator: classes.popupIndicator,
        }}
        renderInput={(params) => {
          const { InputLabelProps, InputProps, ...rest } = params;
          return (
            <InputBase
              classes={{ root: classes.root }}
              variant="outlined"
              {...params.InputProps}
              {...rest}
            />
          );
        }}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <span
              key={props.getOptionLabel(option)}
              className={`${classes.chip}`}
            >
              {option.theme}
            </span>
          ));
        }}
      />
    </div>
  );
};
