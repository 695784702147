import { useState, useEffect } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
export const ThemeSearchField = ({ onThemeChange }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(null);
  const [accountsList, setAccountsList] = useState([]);

  useEffect(() => {
    window.dispatchEvent(new Event('locationchange'));
    axios
      .get(
        'https://netlinkingservice-netlinkingapp.azuremicroservices.io/netlinking/all-thematics'
      )
      .then((res) => {
        setAccountsList(res.data.map((theme) => theme.theme));
      });
  }, []);

  return (
    <Autocomplete
      value={value}
      sx={{
        '& .MuiAutocomplete-inputRoot': {
          height: '40px',
        },
      }}
      disabled={accountsList.length > 0 ? false : true}
      onChange={(event, newValue) => {
        setValue(newValue);
        onThemeChange(newValue);
      }}
      options={accountsList}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          fullWidth
          sx={{ height: '40px' }}
        />
      )}
    />
  );
};
