export const denmarkTranslation = {
  'Les lignes de commandes ont été mises à jour avec succès !':
    'Kommandolinjerne er blevet opdateret med succes!',
  'Le texte a été enregistré avec succès':
    'Teksten blev registreret med succes',
  'Nombre de mots': 'Antal ord',
  Lingala: 'Lingala',
  'Prénom/Nom du client': 'Kundens fornavn/navne',
  Bashkir: 'Basjkirisk',
  "La date doit être ultérieure à aujourd'hui.":
    'Datoen skal være senere end i dag.',
  'Contenu rédigé': 'Indhold skrevet',
  'Nom/prénom': 'Efternavn/fornavn',
  'Mot de passe incorrect': 'Forkert adgangskode',
  Institutions: 'Institutioner',
  'Veuillez donner un descriptif au document téléchargé':
    'Giv en beskrivelse af det downloadede dokument',
  'Sichuan Yi ; Nuosu': 'Sichuan Yi; Nuosu',
  Suivant: 'Næste',
  Kannada: 'Kannada',
  'Séparer les mots clés par un "enter"': 'Adskil nøgleord med et "enter".',
  Allemand: 'Tysk',
  Sango: 'Sango',
  'Min.': 'Min.',
  'un email contenant un lien de réinitialisation du mot de passe a été envoyé à votre email':
    'der er blevet sendt en e-mail med et link til nulstilling af adgangskode til din e-mailadresse',
  'Chef edito': 'Chefredaktør',
  Croate: 'Kroatisk',
  'Nb de textes': 'Antal tekster',
  Violet: 'Violet',
  'Ce champs doit être sup ou égale nombre de texte':
    'Dette felt skal være større end eller lig med antallet af tekst',
  'a été activé avec succès': 'er blevet aktiveret med succes',
  Népalais: 'Nepalsk',
  'Nombre de FAQ': 'Antal ofte stillede spørgsmål',
  Urdu: 'Urdu',
  'Analyses restantes': 'Resterende analyser',
  Indonésien: 'Indonesisk',
  Breton: 'Bretonsk',
  Livrés: 'Leveret',
  Associations: 'Foreninger',
  'Le client': 'Kunden',
  'Révision demandée': 'Anmodning om gennemgang',
  "Une erreur s'est produite, veuillez réessayer":
    'Der er opstået en fejl, prøv venligst igen',
  'Mise à jour non autorisée car des textes ont été livrés':
    'Uautoriseret opdatering, da teksterne er blevet leveret',
  "Pour ajouter un texte (optimisation d'un contenu existant)":
    'Tilføjelse af tekst (optimering af eksisterende indhold)',
  Gaélique: 'Gælisk',
  'La ligne de commande pour': 'Kommandolinjen for',
  'Business/Management': 'Erhverv/ledelse',
  Serbe: 'Serbisk',
  "Merci d'indiquer ci-dessous vos demandes de modifications en prenant bien en compte nos conditions de repasses":
    'Angiv venligst nedenfor dine ønsker om ændringer under hensyntagen til vores strygebetingelser',
  'rédacteurs trouvés': 'fundet forfattere',
  'Kirghiz ; Kirghiz': 'Kirgisisk; Kirgisisk',
  Différence: 'Forskel',
  'en cours': 'i gang',
  'Sami du Nord': 'Nordlige samer',
  Comparer: 'Sammenlign',
  Lituanien: 'Litauisk',
  'PixLink - Mon tableau de bord': 'PixLink - Mit instrumentbræt',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial et ne pas être identique au nom':
    'Skal være på mindst 8 tegn med et stort bogstav, et tal eller et specialtegn og må ikke være identisk med navnet.',
  Webmaster: 'Webmaster',
  'Veuillez saisir des champs valides': 'Indtast venligst gyldige felter',
  Basque: 'Baskisk',
  'Exporter .csv': 'Eksporter .csv',
  'Confirmer le nouveau mot de passe': 'Bekræft ny adgangskode',
  "Pas d'options": 'Ingen muligheder',
  'Banque/Assurance': 'Bank/forsikring',
  'Vos demandes': 'Dine anmodninger',
  'Coût d\u2019achat': 'Omkostninger ved køb',
  Tamil: 'Tamil',
  'Le texte a été supprimé avec succès': 'Teksten blev slettet med succes',
  'Mes Commandes': 'Mine bestillinger',
  Zoulou: 'Zulu',
  Afficher: 'Se',
  Texte: 'Tekst',
  Islandais: 'Islandsk',
  Russe: 'Russisk',
  'Tous les textes': 'Alle tekster',
  Czech: 'Tjekkisk',
  'Comparaison hors ligne': 'Offline sammenligning',
  Téléphonie: 'Telefoni',
  'Valider le texte': 'Validering af teksten',
  'Votre text ne de doit pas dépasser 500 mots':
    'Din tekst bør ikke overstige 500 ord',
  'Coquilles (double espace, « s »\u2026)':
    'Skaller (dobbelt mellemrum, "s"...)',
  'le consultant': 'konsulenten',
  Coréen: 'Koreansk',
  'Nb de Silo': 'Antal siloer',
  Validés: 'Valideret',
  'non attribuées': 'ikke-tildelte',
  Néerlandais: 'Hollandsk',
  'Voulez-vous vraiment supprimer': 'Ønsker du virkelig at slette',
  Rundi: 'Rundi',
  Regional: 'Regional',
  'Compte client': 'Kundekonto',
  Global: 'Global',
  'Tous les rôles': 'Alle roller',
  Musique: 'Musik',
  Perso: 'Personligt',
  Kinyarwanda: 'Kinyarwanda',
  'Il semblerait que tu n\u2019aies plus de crédit d\u2019analyse':
    'Det ser ud til, at du ikke har mere analysekredit',
  Bambara: 'Bambara',
  Sortir: 'Ud med dig',
  optionel: 'valgfri',
  'Temps restant': 'Resterende tid',
  Ukrainien: 'Ukrainsk',
  'Chichewa ; Chewa ; Nyanja': 'Chichewa; Chewa; Nyanja',
  'Votre demande de révision a bien été envoyée.':
    'Din anmodning om gennemgang er blevet sendt.',
  'PixLink - Créer rédacteur': 'PixLink - Opret en editor',
  "n'existe pas": 'ikke eksisterer',
  Hindi: 'Hindi',
  'ne peut pas être supprimé car il est lié à des sites web':
    'kan ikke slettes, da den er knyttet til websteder',
  'Campagnes en cours': 'igangværende ordrer',
  'Textes livrés': 'Leverede tekster',
  Validation: 'Validering',
  'Non attribuées': 'Ikke tildelt',
  'Voulez-vous vraiment désactiver': 'Ønsker du virkelig at deaktivere',
  'Catalan ; Valencien': 'Katalansk; valenciansk',
  'Nombre de textes restants': 'Antal resterende tekster',
  'Ne doit pas contenir votre nom': 'Må ikke indeholde dit navn',
  Documentation: 'Dokumentation',
  Kazakh: 'Kasakhisk',
  'Numéro invalide': 'Ugyldigt nummer',
  'Veuillez saisir un texte ou importer un fichier.':
    'Indtast venligst en tekst eller importer en fil.',
  'Nb de FAQ': "Antal FAQ'er",
  Analyser: 'Analyser',
  Turc: 'Tyrkisk',
  'Toute demande n\u2019ayant pas été précisée lors du brief':
    'Enhver anmodning, der ikke er specificeret i opgaven',
  Wallon: 'Vallonien',
  Birmane: 'Burmesisk',
  Irlandais: 'Irsk',
  Oriya: 'Oriya',
  'Réinitialiservotre mot de passe': 'Nulstil din adgangskode',
  Cuisine: 'Køkken',
  'Haïtien ; créole haïtien': 'Haitiansk; Haitiansk kreolsk',
  'Vous ne pouvez pas supprimer le rédacteur': 'Du kan ikke slette editoren',
  Validé: 'Valideret',
  "cet email n'existe pas": 'denne e-mail findes ikke',
  Historique: 'Historie',
  Confirmer: 'Bekræft',
  'à cocher si le nombre de mots est différent par texte':
    'skal afkrydses, hvis antallet af ord er forskelligt pr. tekst',
  'Fiche du site web ': 'Webside ark',
  Désactiver: 'Deaktivere',
  'Travaux/BTP': 'Arbejder/byggeri',
  'La fiche du website': 'Websitetsbladet',
  'Si le contenu ne respecte pas les règles du brief':
    'Hvis indholdet ikke overholder reglerne i opgaven',
  Norvégien: 'Norsk',
  '0 rédacteurs': '0 forfattere',
  indisponible: 'ikke tilgængelig',
  Venda: 'Venda',
  Maori: 'Maori',
  'Textes revisés': 'Reviderede tekster',
  'Status texte': 'Tekststatus',
  'textes livrés en attente de validation':
    'tekster, der er leveret og afventer validering',
  'PixLink - Mon Profile': 'PixLink - Min profil',
  'le client': 'kunden',
  Romanche: 'Romanche',
  'URL du site': 'URL til webstedet',
  'nouvelles notifications': 'nye meddelelser',
  'Divehi ; Dhivehi ; Maldivien': 'Divehi; Dhivehi; maldivisk',
  'Êtes vous sur de vouloir sauvegarder le texte':
    'Er du sikker på, at du ønsker at gemme teksten',
  'Veuillez ajouter un texte initiale afin de pouvoir sauvegarder le texte en brouillon':
    'Tilføj venligst en indledende tekst for at gemme teksten som et udkast',
  Xhosa: 'Xhosa',
  'Le contrat pour le client': 'Kontrakten for kunden',
  rédacteurs: 'forfattere',
  'Ce champ est obligatoire': 'Dette felt er obligatorisk',
  'Tarif/Mot': 'Sats/ord',
  Tadjik: 'Tadsjikisk',
  'Réf devis': 'Ref citat',
  'Rechercher un rédacteur': 'Søg efter en forfatter',
  Albanais: 'Albansk',
  Galicien: 'Galicisk',
  Disponibilité: 'Tilgængelighed',
  'Les deux mots de passe ne sonts pas identiques':
    'De to adgangskoder er ikke identiske',
  Tibétain: 'Tibetansk',
  'PixLink - Gestion des utilisateurs': 'PixLink - Brugerstyring',
  'Ossétien ; Ossétique': 'Ossetisk; Ossetisk',
  Tswana: 'Tswana',
  'Ajouter un client': 'Tilføj en kunde',
  'Séparez les mots-clés par un " Enter ".':
    'Adskil nøgleordene med et "Enter".',
  'Zhuang ; Chuang': 'Zhuang; Chuang',
  Thématiques: 'Temaer',
  'Nb mots/texte': 'Antal ord/tekst',
  Commerce: 'Handel',
  'Voir les commandes': 'Se ordrerne',
  'Nombre de mots par texte': 'Antal ord pr. tekst',
  'Lien drive...': 'Link drev...',
  'Hollandais ; Flamand': 'hollandsk; flamsk',
  'Mon profil': 'Min profil',
  "Ce champs doit être sup à 0 car facturation n'est pas au réel":
    'Dette felt skal være større end 0, da fakturering ikke er reel',
  'Le texte a été validé avec succès': 'Teksten er blevet valideret med succes',
  Jeux: 'Spil',
  'Voulez-vous vraiment supprimer la ligne de commande pour':
    'Ønsker du virkelig at fjerne kommandolinjen for',
  'Ndebele, Nord ; Ndebele Nord': 'Ndebele, nord; Ndebele North',
  'Slave ecclésiastique ; vieux slavon ; slavon ecclésiastique ; vieux bulgare ; vieux slavon ecclésiastique':
    'Kirkelig slavisk; gammelt slavisk; kirkelig slavisk; gammelt bulgarsk; gammelt kirkelig slavisk; gammelt kirkelig slavisk',
  Swahili: 'Swahili',
  Voyage: 'Rejser',
  'Nombre de mot à ajouter': 'Antal ord, der skal tilføjes',
  'Liste des rédacteurs': 'Liste over forfattere',
  'le rédacteur': 'redaktøren',
  Kanuri: 'Kanuri',
  rédacteur: 'redaktør',
  Tatar: 'Tatarisk',
  'Roumain ; Moldave ; Moldovan': 'Rumænsk; moldovisk',
  'Le texte initial de': 'Den oprindelige tekst til den',
  'Mail clients': 'Kundepost',
  'Le contrat de': 'Kontrakten om',
  'au moins une lettre minuscule et une lettre majuscule,un caractère spécial et un chiffre':
    'mindst et lille og et stort bogstav, et specialtegn og et tal',
  Transport: 'Transport',
  'Nombre total de mots (existants et ajoutés) :':
    'Samlet antal ord (eksisterende og tilføjede) :',
  'Rechercher par mail, prénom, nom, site...':
    'Søg efter e-mail, fornavn, efternavn, websted...',
  'Nombre de mot à ajouter (Min/Max) : ':
    'Antal ord, der skal tilføjes (Min/Max) :',
  Compte: 'Konto',
  'Tourisme/Loisirs': 'Turisme/fritid',
  'Nouveau texte': 'Ny tekst',
  Max: 'Max',
  'Kalaallisut ; Groenlandais': 'Kalaallisut; grønlandsk',
  'Oups...!il y a une/plusieurs erreur(s) dans la fenêtre du gap sémantique. Veuillez vérifier son contenu pour la/les corriger et soumettre à nouveau votre texte.':
    'Ups...! Der er en eller flere fejl i vinduet for semantisk hul. Kontroller venligst indholdet for at rette dem og indsend din tekst igen.',
  'ne peut pas être supprimé pour le moment':
    'kan ikke slettes på nuværende tidspunkt',
  'Le texte': 'Teksten',
  URL: 'URL',
  "Un utilisateur existe déjà avec l'email":
    'Der findes allerede en bruger med denne e-mail',
  Cree: 'Cree',
  Prénom: 'Fornavn',
  'commandes prête': 'ordrer klar',
  Réinitialiser: 'Nulstil',
  'Veuillez fournir une date valide': 'Angiv venligst en gyldig dato',
  'Kuanyama ; Kwanyama': 'Kuanyama; Kwanyama',
  'Luxembourgeois ; Letzeburgesch': 'Luxembourgske; Letzeburgesch',
  Suédois: 'Svensk',
  'Confirmer le mot de passe': 'Bekræft adgangskode',
  'Ce champs est obligatoire et ne peut avoir que des nombres':
    'Dette felt er obligatorisk og kan kun indeholde tal',
  Sciences: 'Videnskab',
  Tchétchène: 'Tjetjenske',
  "est le seul client pour un site, il faut ajouter d'autres pour pouvoir le supprimer":
    'er den eneste klient for et sted, skal der tilføjes andre for at fjerne det',
  'Le mot de passe de confirmation ne correspond pas':
    'Bekræftelsesadgangskode passer ikke til',
  Swati: 'Swati',
  Danois: 'Dansk',
  Sauvegarder: 'Gem',
  'Veuillez changer les informations suivantes titre et/ou mot clé':
    'Ændre følgende oplysninger: titel og/eller nøgleord',
  'importer un document': 'importere et dokument',
  Maltais: 'Maltesisk',
  'Nb de mots': 'Antal ord',
  'Les données fournies sont invalides': 'De angivne data er ugyldige',
  'Livraison estimée': 'Anslået levering',
  'Grec moderne (1453-)': 'Nyere græsk (1453-)',
  Avestan: 'Avestan',
  Inuktitut: 'Inuktitut',
  'Nombre total de mots ajoutés': 'Samlet antal tilføjede ord',
  'le contenu présente des inexactitudes': 'indholdet indeholder ukorrektheder',
  Finnois: 'Finsk',
  'Ajouter un admin': 'Tilføj en administrator',
  'Min/Max': 'Min/Max',
  'Modifier le consultant': 'Ændre konsulenten',
  'Liste des clients': 'Liste over kunder',
  'Nom client': 'Kundens navn',
  Vert: 'Grøn',
  'Mots clé manquants': 'Manglende nøgleord',
  Lao: 'Lao',
  Somali: 'Somalisk',
  'Nb de silo par texte': 'Antal siloer pr. tekst',
  Italien: 'Italiensk',
  'La commande a été mis à jour avec succès !':
    'Ordren er blevet opdateret med succes!',
  Bosniaque: 'Bosnisk',
  'Thématiques d\u2019expertise': 'Temaer for ekspertise',
  'Ce mot de passe est le mot de passe que vous avez oublié':
    'Dette er den adgangskode, du har glemt',
  'a été désactivé avec succès': 'er blevet deaktiveret med succes',
  Kongo: 'Kongo',
  'Postuler à la commande': 'Ansøg om ordren',
  'mot-clé': 'nøgleord',
  Abkhazien: 'Abkhasisk',
  Mongolien: 'Mongolsk',
  'PixLink - Fiche site web': 'PixLink - Hjemmeside',
  'Mail envoyé': 'Afsendt post',
  Géorgien: 'Georgisk',
  'Si des phrases ne sont pas dans un français correct':
    'Hvis sætningerne ikke er skrevet på korrekt fransk',
  'En cours': 'I gang',
  voir: 'se',
  "Date d'ajout": 'Dato tilføjet',
  'Tarif au mot': 'Sats pr. ord',
  Thai: 'Thai',
  'Détails du texte': 'Detaljer om teksten',
  'Commandes en retard': 'Forsinkede ordrer',
  disponible: 'tilgængelig',
  Droit: 'Lov',
  Azerbaïdjanais: 'Aserbajdsjansk',
  'Ajouter un consultant': 'Tilføj en konsulent',
  'Dans le cadre d\u2019une repasse sur des contenus, seuls les éléments suivants seront pris en charge':
    'Når du afspiller indhold igen, understøttes kun følgende elementer',
  'doit comprendre au moins 8 charactères': 'skal indeholde mindst 8 tegn',
  'Titre de la commande': 'Titel på ordren',
  'Langues bihari': 'Bihari-sprog',
  'Livraison estimé au': 'Anslået levering senest',
  "Le nombre de texte de la commande ne peut pas être inférieur aux nombre de textes attribuées au(x) rédacteur(s). Veuillez d'abord modifier les informations de la ligne de commande":
    'Antallet af tekster i ordren må ikke være mindre end det antal tekster, der er tildelt forfatteren/forfatterne. Ændr først oplysningerne i kommandolinjen',
  'Date de livraison': 'Leveringsdato',
  'Voulez-vous vraiment supprimer le contrat de':
    'Ønsker du virkelig at afskaffe den',
  'Facturation au réel': 'Faktisk fakturering',
  'Gap sémantique': 'Semantisk kløft',
  Estonien: 'Estisk',
  Wolof: 'Wolof',
  'Coût d\u2019achat max/mot': 'Maksimal købspris/ord',
  'Détail de la commande': 'Bestillingsoplysninger',
  'Export en cours de téléchargement\u2026':
    'Eksporten er ved at blive uploadet...',
  'Ce champ est obligatoire et doit être inférieur ou égal au Nombre total des textesde la commande':
    'Dette felt er obligatorisk og skal være mindre end eller lig med det samlede antal tekster i ordren',
  Arabe: 'Arabisk',
  Bislama: 'Bislama',
  'Mail client': 'Kundepost',
  '(Titre, mots clé, url, balise, méta-description)':
    '(Titel, nøgleord, url, tag, meta-description)',
  'PixLink - Nouvelle commande': 'PixLink - Ny ordre',
  'Révision en attente': 'Revision afventer',
  'Le consultant': 'Konsulenten',
  Entreprise: 'Virksomhed',
  Aragonais: 'Aragonesisk',
  Kashmiri: 'Kashmiri',
  'a été activé': 'er blevet aktiveret',
  'Interlingua (Association internationale des langues auxiliaires)':
    'Interlingua (Den internationale sammenslutning af hjælpesprog)',
  Actualités: 'Nyheder',
  'Votre décision a été prise en compte avec succès':
    'Der er blevet taget hensyn til din beslutning med succes',
  Afrikaans: 'Afrikaans',
  'contenu en attente de vérification client':
    'indhold, der afventer kundeverifikation',
  'Fiche du rédacteur': 'Redaktørens fil',
  Outillage: 'Værktøj',
  'PixLink - Détails de la commande': 'PixLink - Bestillingsoplysninger',
  'Ajouter ou compléter ci-dessous votre contenu :':
    'Tilføj eller suppler dit indhold nedenfor:',
  Ewe: 'Ewe',
  'contenu en attente de repasse rédacteur':
    'indhold i afventning af afspilningsredaktør',
  'La commande a été supprimé avec succès': 'Ordren blev slettet med succes',
  'Expressions ou phrases françaises mais ne vous convenant pas':
    'Franske udtryk eller sætninger, der ikke passer dig',
  Corse: 'Korsika',
  Modifier: 'Rediger',
  'Nb de GMB': "Antal GMB'er",
  Oromo: 'Oromo',
  Polish: 'Polsk',
  'Insérez votre gap sémantique avec l\u2019occurence':
    'Indsæt dit semantiske hul med forekomsten',
  'Nb de textes à optimiser': 'Antal tekster, der skal optimeres',
  Mode: 'Tilstand',
  Akan: 'Akan',
  'Révisions effectués': 'Foretagne revisioner',
  'Modifier l\u2019admin': 'Rediger admin',
  'Télécharger le document': 'Download dokumentet',
  Hausa: 'Hausa',
  'Notes rédacteurs': 'Bemærkninger fra forfatterene',
  Quechua: 'Quechua',
  Féroïen: 'Færøsk',
  'Modifier le rédacteur': 'Ændre redaktøren',
  Mail: 'Mail',
  Rédacteurs: 'Forfattere',
  'PixLink - Détails du texte': 'PixLink - Detaljer om teksten',
  Hongrois: 'Ungarsk',
  'Bon pour livraison': 'God til levering',
  'Au réel': 'I den virkelige verden',
  'Hiri Motu': 'Hiri Motu',
  Hébreu: 'Hebraisk',
  'Nombre total de mots ajoutés :': 'Samlet antal tilføjede ord :',
  Komi: 'Komi',
  'Doit être inférieur au nombre maximum':
    'Skal være mindre end det maksimale antal',
  'Nom du texte': 'Navn på teksten',
  'Ajouter un rédacteur': 'Tilføj en redaktør',
  Ou: 'Eller',
  Utilisateurs: 'Brugere',
  Heures: 'Timer',
  'Santé/Bien-être': 'Sundhed/velvære',
  'Interlingue ; Occidental': 'Interlingual; vestlig',
  'Luba-Katanga': 'Luba-Katanga',
  Letton: 'Lettiske',
  Anglais: 'Engelsk',
  Envoyer: 'Send',
  'Tableau de bord': 'Instrumentbræt',
  'Demande de révision': 'Anmodning om fornyet behandling',
  'Résultat sémantique': 'Semantisk resultat',
  'Chercher un rédacteur': 'Søg efter en redaktør',
  'Ce champs est obligatoire': 'Dette felt er obligatorisk',
  Rédaction: 'Redaktionel',
  'Modifier le client': 'Ændre klienten',
  'Voulez-vous vraiment supprimer le rédacteur':
    'Ønsker du virkelig at slette editoren',
  Titre: 'Titel',
  Langue: 'Sprog',
  'Mots clé': 'Nøgleord',
  'Les demandes suivantes ne seront pas prises en compte':
    'Følgende ansøgninger vil ikke blive taget i betragtning',
  'délai dépassé': 'overskredet frist',
  Tchouvache: 'Tchouvache',
  Ganda: 'Ganda',
  Twi: 'Twi',
  'Contrat déjà existant, veuillez modifier les informations':
    'Eksisterende kontrakt, bedes du ændre oplysningerne',
  Igbo: 'Igbo',
  Yiddish: 'Jiddisk',
  'Limburgan ; Limburger ; Limburgish':
    'Limburgan; Limburger; Limburger; Limburgish',
  'Commandes validées': 'Validerede ordrer',
  'listeRedacteur-': 'listRedactor-',
  Étape: 'Trin',
  'Energie/Environnement': 'Energi/miljø',
  Min: 'Min',
  Gujarati: 'Gujarati',
  'ne doit pas inclure votre nom/prénom': 'må ikke indeholde dit navn/fornavn',
  'PixLink - Fiche rédacteur': 'PixLink - Fil til forfattere',
  'révisions effectuées en attentes de validation':
    'revisioner foretaget i afventning af validering',
  'Textes validés': 'Validerede tekster',
  Art: 'Kunst',
  Dzongkha: 'Dzongkha',
  Polyvalent: 'Alsidig',
  Sundanese: 'Sundansk',
  'Nb total de textes': 'Samlet antal tekster',
  'Nombre de mots gérés': 'Antal ord, der forvaltes',
  "L'émail": 'Emalje',
  'Afficher les résultats': 'Vis resultater',
  'Frison occidental': 'Vestfrisisk',
  'Modification effectuée avec succès': 'Ændringen er gennemført med succes',
  'Balise titre': 'Titel tag',
  'PixLink - Mon profil': 'PixLink - Min profil',
  'email invalide': 'ugyldig e-mail',
  'Veuillez ajouter une url afin de pouvoir sauvegarder le texte en brouillon':
    'Tilføj venligst en url, så du kan gemme teksten som udkast',
  Macédonien: 'Makedonsk',
  'Votre candidature a bien été envoyée': 'Din ansøgning er blevet sendt',
  'E-mail': 'E-mail',
  Fulah: 'Fulah',
  'Ajouter ou compléter ci-dessous votre contenu':
    'Tilføj eller suppler dit indhold nedenfor',
  Manx: 'Manx',
  'Ajout de mots-clés avec la fréquence restante':
    'Tilføjelse af nøgleord med resterende frekvens',
  'Fiche du site web': 'Webside ark',
  Contacter: 'Kontakt',
  Fidjien: 'Fijiansk',
  Déconnexion: 'Afbrydelse af forbindelsen',
  email: 'e-mail',
  'Nombre de textes': 'Antal tekster',
  'Enregistrer et informer client': 'Registrering og information af kunder',
  'Date de validation': 'Valideringsdato',
  'Tonga (Îles Tonga)': 'Tonga (Tonga-øerne)',
  Javanais: 'Javansk',
  Portugais: 'Portugisisk',
  Biélorusse: 'Hviderussisk',
  'Pour ajouter un nouveau texte': 'Sådan tilføjer du en ny tekst',
  'Enfant/Puéri': 'Barn/Puperium',
  'Mots clés': 'Nøgleord',
  Emploi: 'Beskæftigelse',
  'Voulez-vous vraiment supprimer la commande de':
    'Ønsker du virkelig at fjerne den',
  'Mot clé': 'Nøgleord',
  Consultants: 'Konsulenter',
  Volapük: 'Volapük',
  Services: 'Tjenester',
  'mot-clé (fréquence)': 'nøgleord (frekvens)',
  'Ne plus afficher ce message': 'Denne meddelelse må ikke vises igen',
  Consultant: 'Konsulent',
  "Une erreur s'est produite": 'Der er opstået en fejl',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial':
    'Mindst 8 tegn med et stort bogstav, et tal, et specialtegn',
  'Pushto ; Pashto': 'Pushto; Pashto',
  Français: 'Fransk',
  validées: 'valideret',
  'Url du site': 'Webstedets URL',
  Précédent: 'Forrige',
  'car celui-ci a déjà rédigé des textes':
    'fordi sidstnævnte allerede har udarbejdet tekster',
  'Kikuyu ; Gikuyu': 'Kikuyu; Gikuyu',
  Brouillons: 'Udkast',
  Ido: 'Ido',
  'Contenu disponible': 'Tilgængeligt indhold',
  Enseignement: 'Undervisning',
  'a été livré avec succès': 'er blevet leveret med succes',
  Chinois: 'Kinesisk',
  Vietnamien: 'Vietnamesisk',
  'Nouveau client': 'Ny kunde',
  'Votre profil a été mis à jour avec succès.':
    'Din profil er blevet opdateret med succes.',
  Consignes: 'Vejledning',
  'Mot de passe': 'Adgangskode',
  Annuler: 'Annuller',
  Non: 'Nej',
  Nom: 'Navn',
  Guarani: 'Guarani',
  "il y'a des commandes liés à au site web":
    'der er ordrer i forbindelse med webstedet',
  Score: 'Score',
  'Mot de passe mis à jour avec succès': 'Adgangskode er blevet opdateret',
  Latin: 'Latin',
  Technologie: 'Teknologi',
  Internet: 'Internet',
  Nauru: 'Nauru',
  'a été créé avec succès': 'er blevet oprettet med succes',
  Roumain: 'Rumænsk',
  Assamais: 'Assamesisk',
  'Khmer central': 'Central Khmer',
  'Navajo ; Navaho': 'Navajo; Navaho',
  'Panjabi ; Punjabi': 'Punjabi; Punjabi',
  'Analyse/Limite': 'Analyse/grænse',
  Grec: 'Græsk',
  Ojibwa: 'Ojibwa',
  'Mes Projets': 'Mine projekter',
  'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n\u2026':
    'nøgleord1 (2)\nnøgleord2 (1)\nnøgleord3 (1)\nnøgleord4 (2)\n...',
  'ajouter un texte': 'tilføje en tekst',
  'Détail de la prestation': 'Oplysninger om tjenesten',
  "Vous avez la possibilité d'exporter le rapport d'une, ou de plusieurs commandes. Pour exporter toutes les commandes d'un client, il vous suffit juste de cliquer sur l'icône de téléchargement en jaune":
    'Du har mulighed for at eksportere rapporten for en eller flere ordrer. Hvis du vil eksportere alle ordrer for en kunde, skal du blot klikke på det gule download-ikon',
  'Gestion des utilisateurs': 'Administration af brugere',
  'livré et inférieur au nombre de texte non affecté':
    'leveret og mindre end antallet af ikke-tildelte tekster',
  'Date deadline validation client': 'Frist for validering af kunden',
  Aymara: 'Aymara',
  Validées: 'Valideret',
  Notes: 'Noter',
  'Nombre de GMB': "Antal GMB'er",
  'résultats sur': 'resultater på',
  'Prénom / Nom du client': 'Fornavn / Navn på kunden',
  'Tout télécharger': 'Download alle',
  'Champs obligatoires': 'Obligatoriske felter',
  Éditer: 'Rediger',
  'Coller le texte initial dans le champs ci-dessous':
    'Indsæt den oprindelige tekst i feltet nedenfor',
  'ID rédacteur': 'Redaktør-ID',
  Arménien: 'Armensk',
  'Grande distribution': 'Distribution i stor skala',
  Welsh: 'Walisisk',
  Herero: 'Herero',
  'Enrichissement sémantique': 'Semantisk berigelse',
  'Détail de la prestation': 'Detaljer om levering af indhold',
  Noir: 'Sort',
  'Ajouter un texte': 'Tilføj en tekst',
  'Immo/Defisc': 'Ejendom/skat',
  'Brief détaillé': 'Detaljeret beskrivelse',
  'Séparez les mots-clés par un Enter .': 'Adskil nøgleordene med et Enter .',
  Prix: 'Priser',
  'mot de passe oublié': 'Glemt adgangskode',
  'Ndebele, South ; South Ndebele': 'Ndebele, syd; South Ndebele',
  'Demander une révision': 'Anmod om en anmeldelse',
  'Sotho, Southern': 'Sotho, sydlige',
  'Le titre de la commande existe déjà': 'Titlen på ordren findes allerede',
  'Félicitations !': 'Tillykke med det!',
  Kurde: 'Kurdisk',
  Adresse: 'Adresse',
  Espagnol: 'Spansk',
  Ndonga: 'Ndonga',
  'En retard': 'Sen',
  'a été ajouté avec succès': 'er blevet tilføjet med succes',
  Commandes: 'ordrer',
  'a été mis en brouillon': 'er blevet udarbejdet',
  'Rédacteur attribué': 'Tilknyttet redaktør',
  Rechercher: 'Søg på',
  'Nouvelle commande': 'Ny ordre',
  'Commandes non attribuées': 'Ikke-tildelte ordrer',
  'Le texte a été mis en brouillon': 'Teksten er blevet udarbejdet',
  'Contact edito': 'Kontakt edito',
  Langues: 'Sprog',
  Orange: 'Orange',
  'Nynorsk norvégien': 'Norsk nynorsk',
  Shona: 'Shona',
  Turkmène: 'Turkmensk',
  'contenu repassé, en attente validation client':
    'stryget indhold, der afventer kundens validering',
  'Nombre de mots rédigés': 'Antal skrevne ord',
  Malay: 'Malay',
  'Nombre de Silo': 'Antal siloer',
  Oui: 'Ja',
  Automobile: 'Automotive',
  Résultats: 'Resultater',
  Afar: 'Afar',
  'Tâche - id tâche': 'Opgave - opgave-id',
  'Ce champ est obligatoire doit être supérieur à 0':
    'Dette felt er obligatorisk og skal være større end 0',
  'Vous êtes sûr': 'Er du sikker på',
  'Réinitialiser votre mot de passe': 'Nulstil din adgangskode',
  Sindhi: 'Sindhi',
  Bengali: 'Bengali',
  'Bokmål, norvégien ; Bokmål norvégien': 'Bokmål, norsk; norsk Bokmål',
  'Nombre de Jours': 'Antal dage',
  'Min/Max mots': 'Min/Max ord',
  Slovène: 'Slovensk',
  Site: 'Websted',
  'Sinhala ; Cingalais': 'Sinhala; singalesisk',
  Bulgare: 'Bulgarsk',
  'Liste des commandes': 'Liste over ordrer',
  "L'utilisateur": 'Brugeren',
  Uzbek: 'Usbekisk',
  'Titre du texte': 'Titel på teksten',
  Postuler: 'Ansøg på',
  'Nb de textes par Silo': 'Antal tekster pr. silo',
  'Nombre total de mots (existants et ajoutés)':
    'Samlet antal ord (eksisterende og tilføjede)',
  'Max.': 'Max.',
  Beauté: 'Skønhed',
  Humanitaire: 'Humanitær bistand',
  Telugu: 'Telugu',
  'Recherche par rédacteur ou texte': 'Søg efter forfatter eller tekst',
  'Balise meta-description': 'Metabeskrivelsestag',
  Malgache: 'Madagaskisk',
  Tagalog: 'Tagalog',
  'Ce champs doit être sup à 0 et inférieur à 1':
    'Dette felt skal være større end 0 og mindre end 1',
  'Le rédacteur': 'Redaktøren',
  'a été mis à jour avec succès': 'er blevet opdateret med succes',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un symbole et ne pas être identique au nom.':
    'Skal være på mindst 8 tegn med et stort bogstav, et tal eller et symbol og må ikke være identisk med navnet.',
  'textes en plus': 'flere tekster',
  'Format souhaité': 'Ønsket format',
  Chamorro: 'Chamorro',
  Malayalam: 'Malayalam',
  'Détail ligne de commande': 'Ordrelinjedetaljer',
  'Veuillez préciser la date': 'Angiv venligst datoen',
  Pali: 'Pali',
  Clients: 'Kunder',
  'Total facturé': 'Faktureret i alt',
  'contenu validé par le client': 'indhold, der er valideret af kunden',
  'Occitan (post 1500)': 'Occitansk (efter 1500)',
  Japonais: 'Japansk',
  Avaric: 'Avaric',
  'Voulez-vous vraiment activer': 'Ønsker du virkelig at aktivere',
  Sanskrit: 'Sanskrit',
  'Toutes les notifications': 'Alle meddelelser',
  Marathi: 'Marathi',
  Persan: 'Persisk',
  'La commande pour le client': 'Ordren til kunden',
  'Intitulé de la commande': 'Titel på ordren',
  Samoan: 'Samoansk',
  Autres: 'Andre',
  Sport: 'Sport',
  "il y'a des commandes liés au site web ":
    'der er ordrer i forbindelse med webstedet',
  'Ajout de mots-clé avec la fréquence restante':
    'Tilføjelse af nøgleord med resterende frekvens',
  'a été supprimé avec succès': 'er blevet fjernet med succes',
  Postulé: 'Indsendt',
  Literie: 'Sengetøj',
  'Édité par le client': 'Redigeret af kunden',
  Cornouailles: 'Cornwall',
  Tigrinya: 'Tigrinya',
  Textes: 'Tekster',
  'Soumettre un nouveau texte': 'Indsend en ny tekst',
  Téléphone: 'Telefon',
  Deadline: 'Deadline',
  "Une erreur s'est produite. Veuillez vérifier votre saisie ou essayer plus tard.":
    'Der er opstået en fejl. Kontroller venligst din indtastning eller prøv igen senere.',
  Marshallais: 'Marshall',
  "La demande d'ajout de crédit est envoyée":
    'Anmodningen om at tilføje kredit sendes',
  'Le rédacteur ': 'Redaktøren',
  'Demander des crédits': 'Ansøgning om kredit',
  "Cette commande n'existe pas": 'Denne kommando findes ikke',
  Inupiaq: 'Inupiaq',
  Tahitien: 'Tahitiansk',
  Admins: 'Admins',
  'Mettez des mots-clés avec fréquence': 'Indstil nøgleord med hyppighed',
  Notifications: 'Meddelelser',
  'en retard': 'sent',
  "l'admin": 'administratoren',
  'Mail rédacteurs': 'Mail forfattere',
  Amharique: 'Amharisk',
  Societe: 'Virksomhed',
  Tsonga: 'Tsonga',
  'Nombre de textes ajoutés': 'Antal tilføjede tekster',
  Société: 'Virksomhed',
  Yoruba: 'Yoruba',
  'Le texte a été livré avec succès': 'Teksten blev leveret med succes',
  'Mettez votre texte': 'Indsæt din tekst',
  "Oups....! Une erreur s'est produite": 'Ups....! Der er opstået en fejl',
  'Prénom/Nom': 'Fornavn/navnet',
  'Max doit être sup à Min': 'Max skal være større end Min',
  'Importer un autre document': 'Importere et andet dokument',
  'Votre demande a expirée .. veuillez refaire une autre':
    'Din ansøgning er udløbet ... lav venligst en ny',
  'Veuillez corriger le format votre gap sémantique afin de pouvoir sauvegarder le texte en brouillon':
    'Ret venligst formatet af din semantiske kløft for at gemme teksten som et udkast',
  Exemple: 'Eksempel',
  Slovaque: 'Slovakisk',
  Finance: 'Finans',
  Réactiver: 'Genaktivere',
  'Nb textes': 'Antal tekster',
  'Intitulé commande': 'Titel på bestilling',
  Supprimer: 'Slet',
  Brouillon: 'Udkast',
  'Mots clés restants': 'Øvrige nøgleord',
  Date: 'Dato',
  Rôle: 'Rolle',
  'Ce champs doit être sup à 0': 'Dette felt skal være større end 0',
  'Maison/Déco': 'Hjem/Dekoration',
  'Vous avez': 'Du har',
  'Cette ligne de commande ne peut pas être supprimée car une partie de ses textes a été livré':
    'Denne kommandolinje kan ikke slettes, fordi en del af teksten er blevet leveret',
  'vérifiez votre email et cliquez sur le lien pour continuer !':
    'Tjek din e-mail og klik på linket for at fortsætte!',
  Sarde: 'Sardinien',
  'Note rédacteur': 'Redaktørens note',
  'Uighur ; Uyghur': 'Uighur; Uyghur',
  Name: 'Navn',
  'Commandes intelligentes': 'Smart kommandoer',
  'Commandes avec révision': 'Smart + review kommandoer ',
  'Type de contenu': 'Indholdstype',
  'Fiche produit': 'Produktside',
  GMB: 'GMB',
  'STORE LOCATOR': 'STORE LOCATOR',
  'Rédaction premium': 'Premium-redigering',
  'Rédaction avec révision': 'Smart + review redigering',
  'Rédaction intelligente': 'Smart redigering',
  PREMIUM: 'PREMIUM',
  Type: 'Type',
  Editer: 'Rediger',
  'Tous les types': 'Alle typer',
  Smart: 'Smart',
  'Smart + review': 'Smart + review',
  'Moteur de recherche Netlinking': 'Netlinking Søgemaskine',
  'Paramètre de recherche': 'Søgeparameter',
  Standard: 'Standard',
  Premium: 'Premium',
  Thématique: 'Tema',
  'Filtre avancé': 'Avanceret filter',
  'Nombre de': 'Antal',
  'Netlinking Trafic': 'Netlinking Trafik',
  'Mots clé positionés': 'Rangerede nøgleord',
  'Netlinking Tarif': 'Netlinking Pris',
  Chercher: 'Søg',
  Domaine: 'Domæne',
  'Topic Majestic': 'Majestic Emne',
  'Kw positionés': 'Rangerede Kw',
  Trafic: 'Trafik',
  Tarif: 'Pris',
  'Contacté le': 'Kontaktet den',
  Contact: 'Kontakt',
  'Rechercher par nom de domaine': 'Søg efter domænenavn',
  'Fiche de Spot Netlinking': 'Netlinking Spot Ark',
  'Domaine Netlinking': 'Netlinking Domæne',
  'Netlinking Thématique': 'Netlinking Tema',
  'Netlinking Topic Majestic': 'Majestic Netlinking Emne',
  Réinitialiser: 'Nulstil',
  italian: 'italiensk',
  english: 'engelsk',
  german: 'tysk',
  spanish: 'spansk',
  portuguese: 'portugisisk',
  french: 'fransk',
};
