import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Modal } from 'reactstrap';

import axiosPrivate from 'config/axiosPrivate';

import { addUser, updateUser } from 'store/Admin/UserManagement';

import { CustomAutocomplete } from 'components/shared/DropDown/CustomAutocomplete';
import ErrorSuccesModal from 'components/shared/modal/ErrorSuccesModal';
import { classes } from 'components/shared/modal/modalClasses';

import { PWD_REGEX, EMAIL_REGEX, PHONENUMBER_REGEX } from 'utils/Regex';
import { PIXLINK_ROLES, ROLES } from 'utils/Constants';
import axiosPixLink from 'config/axiosPixLink';

const API = {
  pixopClients: '/user/pixOpsClient',
  createClient: '/user/create',
  updateClient: '/user/update',
};

export const ClientModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mail, setMail] = useState('');
  const [account, setAccount] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [accountsList, setAccountsList] = useState([]);

  const [errorSuccess, setErrorSuccess] = useState(false);
  const [errorSuccessMsg, setErrorSuccessMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const [disabledForm, setDisabledForm] = useState(true);

  useEffect(() => {
    axiosPixLink
      .get(API.pixopClients)
      .then((res) => setAccountsList(res.data.map((x) => x.clientName)));

    if (props && props.user) {
      setFirstName(props.user.firstName);
      setLastName(props.user.lastName);
      setName(props.user.name);
      setMail(props.user.email);
      setPhoneNumber(props.user.phoneNumber);
      setAccount(props.user.account);
    }
  }, [props]);

  const checkPasswordValid = useCallback(() => {
    return !password || PWD_REGEX.test(password);
  }, [password]);

  const checkValidForm = useCallback(() => {
    if (!mail || !phoneNumber || !firstName || !lastName || !account) {
      setDisabledForm(true);
      return;
    }

    if (!mail.match(EMAIL_REGEX)) {
      setDisabledForm(true);
      return;
    }

    return props.user
      ? setDisabledForm(!checkPasswordValid())
      : setDisabledForm(false);
  }, [
    account,
    checkPasswordValid,
    mail,
    firstName,
    lastName,
    name,
    phoneNumber,
    props.user,
  ]);

  useEffect(() => {
    checkValidForm();
  }, [
    mail,
    phoneNumber,
    password,
    name,
    firstName,
    lastName,
    account,
    checkValidForm,
  ]);

  const updateClient = () => {
    let body = {
      password: !password ? null : password,
      email: mail,
      firstName,
      lastName,
      phoneNumber,
      account,
      role: PIXLINK_ROLES.CLIENT,
    };
    axiosPixLink
      .put(API.updateClient, body, { params: { mail: props.user.email } })
      .then((res) => {
        dispatch(updateUser({ ...res.data }));
        setIsSuccess(true);
        setErrorSuccessMsg(
          `${t('Le client')} ${props.user.email} ${t(
            'a été mis à jour avec succès'
          )} !`
        );
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsSuccess(false);
        switch (e.response.status) {
          case 409:
            setErrorSuccessMsg(
              `${t("Un utilisateur existe déjà avec l'email")} ${mail}`
            );
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setErrorSuccess(true);
      });
  };

  const createClient = () => {
    let body = {
      email: mail,
      firstName,
      lastName,
      phoneNumber,
      account,
      role: PIXLINK_ROLES.CLIENT,
    };

    axiosPixLink
      .post(API.createClient, body)
      .then((res) => {
        dispatch(addUser({ ...res.data, role: PIXLINK_ROLES.CLIENT }));
        setIsSuccess(true);
        setErrorSuccessMsg(
          `${t('Le client')} ${mail} ${t('a été créé avec succès')}`
        );
        setErrorSuccess(true);
      })
      .catch((e) => {
        setIsSuccess(false);
        switch (e.response.status) {
          case 409:
            setErrorSuccessMsg(
              `${t("Un utilisateur existe déjà avec l'email")} ${mail}`
            );
            break;
          default:
            setErrorSuccessMsg(t("Oups....! Une erreur s'est produite"));
            break;
        }
        setErrorSuccess(true);
      });
  };

  if (props.user)
    return (
      <div>
        <Modal style={classes.modalCustom} isOpen={props.open}>
          <div style={classes.modalHeader} className="modal-header">
            <h5 style={classes.title} className="modal-title">
              {t('Modifier le client')}
            </h5>
            <button
              type="button"
              onClick={props.handleClose}
              style={classes.btnClose}
              className="btn-close"
            />
          </div>
          <div style={classes.modalPad} className="modal-body">
            <div className="row row-mb-lg">
              <div className="col-12 col-md-6">
                <div className="label-title"> {t('Nom')}</div>
                <div>
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="form-control form-ct"
                  />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="label-title">{t('Prénom')}</div>
                <div>
                  <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="form-control form-ct"
                  />
                </div>
              </div>
            </div>
            <div className="row row-mb-lg">
              <div className="col-12 col-md-12">
                <div className="label-title">{t('E - mail')}</div>
                <div>
                  <input
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                    type="text"
                    className="form-control form-ct"
                  />
                </div>
              </div>
            </div>
            <div className="row row-mb-lg">
              <div className="col-12 col-md-6">
                <div className="label-title">{t('Téléphone')}</div>
                <div>
                  <input
                    value={phoneNumber}
                    onChange={(e) => {
                      if (
                        e.target.value === '' ||
                        PHONENUMBER_REGEX.test(e.target.value)
                      ) {
                        setPhoneNumber(e.target.value);
                      }
                    }}
                    type="text"
                    className="form-control form-ct"
                  />
                </div>
              </div>
            </div>
            <div className="row row-mb-lg">
              <div className="col-12 col-md-12">
                <div className="label-title">{t('Compte client')}</div>
                <CustomAutocomplete
                  disabled={accountsList.length <= 0}
                  list={accountsList}
                  getOptionSelected={(option) => option === account}
                  getOptionDisabled={(option) => false}
                  value={account}
                  setValue={(option) => setAccount(option)}
                  getOptionLabel={(option) => {
                    return option;
                  }}
                />
              </div>
            </div>
            <div className="row row-mb-lg">
              <div className="col-12 col-md-12">
                <div className="label-title">{t('Mot de passe')}*</div>
                <div className="input-group input-password">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span
                    onClick={() => setShowPassword(!showPassword)}
                    className="input-group-text"
                  >
                    {showPassword ? (
                      <img
                        src="/Images/icon-eye-black.png"
                        alt="black eye icon"
                      />
                    ) : (
                      <img
                        src="/Images/icon-eye-gray.png"
                        alt="gray eye icon"
                      />
                    )}
                  </span>
                </div>
              </div>
              <div className="text-more mt-2">
                *
                {t(
                  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un symbole et ne pas être identique au nom.'
                )}
              </div>
            </div>
            <div className="box-button-bt">
              <div>
                {disabledForm ? (
                  <button className="bt-submit-disabled-y float-end">
                    {t('Sauvegarder')}
                  </button>
                ) : (
                  <button
                    className="bt-submit-y float-end"
                    onClick={updateClient}
                  >
                    {t('Sauvegarder')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal>
        <ErrorSuccesModal
          open={errorSuccess}
          message={errorSuccessMsg}
          handleClose={() => {
            if (isSuccess === true) props.handleClose();
            setErrorSuccess(false);
          }}
          success={isSuccess}
          toggle={() => setErrorSuccess(!errorSuccess)}
        />
      </div>
    );

  return (
    <div>
      <Modal style={classes.modalCustom} isOpen={props.open}>
        <div style={classes.modalHeader} className="modal-header">
          <h5 style={classes.title} className="modal-title">
            {t('Ajouter un client')}
          </h5>
          <button
            type="button"
            onClick={props.handleClose}
            style={classes.btnClose}
            className="btn-close"
          />
        </div>
        <div style={classes.modalPad} className="modal-body">
          <div className="row row-mb-lg">
            <div className="col-12 col-md-6">
              <div className="label-title"> {t('Nom')}</div>
              <div>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="form-control form-ct"
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="label-title">{t('Prénom')}</div>
              <div>
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="form-control form-ct"
                />
              </div>
            </div>
          </div>
          <div className="row row-mb-lg">
            <div className="col-12 col-md-12">
              <div className="label-title">{t('E-mail')}</div>
              <div>
                <input
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                  type="text"
                  className="form-control form-ct"
                />
              </div>
            </div>
          </div>
          <div className="row row-mb-lg">
            <div className="col-12 col-md-6">
              <div className="label-title">{t('Téléphone')}</div>
              <div>
                <input
                  value={phoneNumber}
                  onChange={(e) => {
                    if (
                      e.target.value === '' ||
                      PHONENUMBER_REGEX.test(e.target.value)
                    ) {
                      setPhoneNumber(e.target.value);
                    }
                  }}
                  type="text"
                  className="form-control form-ct"
                />
              </div>
            </div>
          </div>
          <div className="row row-mb-lg">
            <div className="col-12 col-md-12">
              <div className="label-title">{t('Compte client')}</div>
              <CustomAutocomplete
                disabled={accountsList.length <= 0}
                list={accountsList}
                getOptionDisabled={(option) => false}
                getOptionSelected={(option) => option === account}
                value={account}
                setValue={(option) => setAccount(option)}
                getOptionLabel={(option) => {
                  return option;
                }}
              />
            </div>
          </div>

          <div className="box-button-bt">
            <div>
              {disabledForm ? (
                <button className="bt-submit-disabled-y float-end">
                  {t('Sauvegarder')}
                </button>
              ) : (
                <button
                  className="bt-submit-y float-end"
                  onClick={createClient}
                >
                  {t('Sauvegarder')}
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <ErrorSuccesModal
        open={errorSuccess}
        message={errorSuccessMsg}
        handleClose={() => {
          if (isSuccess === true) props.handleClose();
          setErrorSuccess(false);
        }}
        success={isSuccess}
        toggle={() => setErrorSuccess(!errorSuccess)}
      />
    </div>
  );
};

export default ClientModal;
