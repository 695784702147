import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Modal } from 'reactstrap';
import { setOrderId, updateForm } from 'store/Writer/Analyser';
import { classes } from './modalClasses';
import { useEffect, useState } from 'react';

export const ErrorSuccesModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);

  const message = props.message;
  const boldTextRegex = /n°(\d+)/;
  const match = message.match(boldTextRegex);
  const boldText = match ? match[0] : '';

  useEffect(() => {
    if (props.open) {
      setModalOpen(true);
    }
  }, [props.open]);

  const handleModalClose = () => {
    setModalOpen(false);
    props.handleClose();
  };

  return (
    <Modal
      style={classes.modalCustom}
      isOpen={props.open}
      toggle={handleModalClose} // Close the modal when toggle is called
      backdrop={true} // Enable clicking outside to close the modal
      keyboard={false}
    >
      <div style={classes.modalHeader} className="modal-header">
        <h5 style={classes.title} className="modal-title">
          {props.success === true ? `${t('Félicitations !')}` : 'Ooops !'}
        </h5>
        <button
          type="button"
          onClick={props.handleClose}
          style={classes.btnClose}
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div style={classes.modalPad} className="modal-body">
        <div className="row row-mb-lg">
          <div className="col-12 col-md-12">
            <div>
              <img
                style={classes.imgConfirm}
                src={
                  props.success
                    ? '/Images/modal/img-felicitation.png'
                    : '/Images/modal/img-confirm.png'
                }
                alt="confirm"
              />
            </div>
            <div style={classes.textConfirm}>
              {' '}
              {message.replace(
                boldTextRegex,
                <span style={{ fontWeight: 'bold' }}>{boldText}</span>
              )}
            </div>
            <div className="d-flex justify-content-center mt-4">
              {props.orderType === 'PREMIUM' &&
              props.isTextDelivered &&
              props.remainingTextAmount > 0 ? (
                <button
                  className="bt-submit-y"
                  onClick={() => {
                    setOrderId(props.orderId);
                    dispatch(updateForm(null));
                    props.resetFields();
                    props.handleClose();
                  }}
                >
                  {t('Soumettre un nouveau texte')}
                </button>
              ) : props.remainingTextAmount === 0 ? (
                <button
                  className="CTA_validation"
                  onClick={() => {
                    setOrderId(props.orderId);
                    props.handleClose();
                  }}
                >
                  {/* Votre libellé pour le bouton lorsque remainingTextAmount est égal à 0 */}
                  {t('Générer une facture')}
                </button>
              ) : (
                ''
              )}
              {props.showCreditButton ? (
                <div className="col-12 col-md-6">
                  <div className="d-flex">
                    <button
                      className="bt-submit-y "
                      onClick={props.executeAction}
                    >
                      {t('Demander des crédits')}
                    </button>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorSuccesModal;
