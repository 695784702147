import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { JsonToExcel } from 'react-json-to-excel';

export const ExcelExportButton = (props) => {
  const { t } = useTranslation();
  const [netlinkingExcelData, setNetlinkingExcelData] = useState(null);
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  function handleLanguageId(value) {
    let result;

    switch (value) {
      case 6:
        result = 'Fr';
        break;
      case 1:
        result = 'En';
        break;
      case 2:
        result = 'De';
        break;
      case 3:
        result = 'Es';
        break;
      case 4:
        result = 'It';
        break;
      case 5:
        result = 'Pt';
        break;
      default:
        result = 'Fr';
        break;
    }

    return result;
  }
  const handleClick = async () => {
    const dataToExport =
      props.netlinkingExportList.length > 0
        ? props.netlinkingExportList
        : props.netlinkingFilteredListData.length > 0
        ? props.netlinkingFilteredListData
        : props.netlinkingData;
    const tmpExcelData = dataToExport.map((netlinkingData) => ({
      domain: netlinkingData.domain,
      cleanDomain: netlinkingData.domain
        .replace('https://', '')
        .replace('http://', '')
        .replace('www.', ''),
      language: handleLanguageId(netlinkingData.languageId),
      themesList: netlinkingData.themesList,
      topicsList: netlinkingData.topicsList,
      kwPositioned: netlinkingData.kwPositioned,
      trafic: netlinkingData.trafic,
      tF: netlinkingData.tF,
      cF: netlinkingData.cF,
      tarifHr: netlinkingData.tarifHr,
      tarifRc: netlinkingData.tarifRc,
      conditions: netlinkingData.conditions,
      contactedTime: netlinkingData.contactedTime,
      contact: netlinkingData.contact,
    }));
    setNetlinkingExcelData(tmpExcelData);
    await sleep(500);
    document.querySelector('.download-button').click();
  };

  return (
    <div className="container-excel">
      <div className="img-container">
        <img
          src="/Images/icon-excel.png"
          onClick={handleClick}
          className="App-logo-excel"
          alt="logo"
        />
      </div>
      <div className="json-to-excel-container">
        <JsonToExcel
          data={netlinkingExcelData}
          fileName="netlinking-report"
          fields={[
            { label: 'domain', value: 'domain' },
            { label: 'cleanDomain', value: 'cleanDomain' },
            { label: 'language', value: 'language' },
            { label: 'themesList', value: 'themesList' },
            { label: 'topicsList', value: 'topicsList' },
            { label: 'kwPositioned', value: 'kwPositioned' },
            { label: 'trafic', value: 'trafic' },
            { label: 'tF', value: 'tF' },
            { label: 'cF', value: 'cF' },
            { label: 'tarifHr', value: 'tarifHr' },
            { label: 'tarifRc', value: 'tarifRc' },
            { label: 'conditions', value: 'conditions' },
            { label: 'contactedTime', value: 'contactedTime' },
            { label: 'contact', value: 'contact' },
          ]}
          buttonText="Export to Excel"
          btnColor="white"
          btnClassName="download-button"
        />
      </div>
    </div>
  );
};
